import React from 'react';
import styles from './index.module.scss';
import clsx from 'clsx';
import CrossIcon from '../../../../shared/assets/CrossIcon';

interface IBottomAlert {
    title: string;
    text: string;
    onClick: () => void;
}

export const BottomAlert: React.FC<IBottomAlert> = ({ title, text, onClick }) => {
    return (
        <div className={clsx(styles['alert'], styles['active'])}>
            <CrossIcon className={styles['alert__icon']} onClick={onClick} />
            <h2 className={styles['alert__title']}>{title}</h2>
            <span className={styles['alert__text']}>{text}</span>
        </div>
    );
};
