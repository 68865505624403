import React, { useRef } from 'react';
import { useState, useEffect } from 'react';
import { Display } from '../../entities/featAudioPlayer/ui';
import { Controls } from '../../features/featCardDetailAudioPlayer';
import { IFeat } from '../../entities/feat';
import { AudioProgressBar } from '../../shared/ui/AudioProgressBar';

interface IFeatCardDetailAudioPlayer {
    feat: IFeat;
    togglePlayPause: () => void;
    closeAudioPlayer: () => void;
    stopAudio: () => void;
    isPlaying: boolean;
}

const FeatCardDetailAudioPlayer: React.FC<IFeatCardDetailAudioPlayer> = ({
    feat,
    togglePlayPause,
    closeAudioPlayer,
    stopAudio,
    isPlaying,
}) => {
    const [isReady, setIsReady] = useState(false);
    const audioRef = useRef<HTMLAudioElement>(null);
    const [{ progressValue, progressMax }, setProgressBarState] = useState({
        progressValue: 0,
        progressMax: 0,
    });
    const onLoaded = (e: any) => {
        const seconds = Math.floor(e.target.duration);
        setProgressBarState((prevState) => {
            return { ...prevState, progressMax: seconds };
        });
    };

    const handleProgressChange = (e: any) => {
        const seconds = Math.floor(e.target.value);

        setProgressBarState((prevState) => {
            return { ...prevState, progressValue: seconds };
        });
        if (audioRef.current) {
            audioRef.current.currentTime = seconds;
        }
    };

    const onEnded = () => {
        stopAudio();
    };

    const onTimeUpdate = (e: any) => {
        const seconds = Math.floor(e.target.currentTime);
        setProgressBarState((prevState) => {
            return { ...prevState, progressValue: seconds };
        });
    };

    const onCanPlay = () => {
        setIsReady(true);
    };

    const onDurationChange = (e: any) => {
        const duration = Math.floor(e.target.duration);

        setProgressBarState((prevState) => {
            return { ...prevState, progressMax: duration };
        });
    };

    useEffect(() => {
        try {
            const togglePlay = setTimeout(() => {
                if (audioRef.current) {
                    if (isPlaying) {
                        audioRef.current.play();
                    } else {
                        audioRef.current.pause();
                    }
                }
            }, 500);

            return () => {
                clearTimeout(togglePlay);
            };
        } catch (err) {
            console.log(err);
        }
    }, [isPlaying]);
    return (
        <>
            <Display
                controls={
                    <Controls
                        isPlaying={isPlaying}
                        togglePlayPause={togglePlayPause}
                        closePlayer={closeAudioPlayer}
                    />
                }
                progressBar={
                    <AudioProgressBar
                        handleProgressChange={handleProgressChange}
                        value={progressValue}
                        max={progressMax}
                    />
                }
                currentAudio={feat}
                ref={audioRef}
                onLoaded={onLoaded}
                onEnded={onEnded}
                onTimeUpdate={onTimeUpdate}
                onDurationChange={onDurationChange}
                onCanPlay={onCanPlay}
                isReady={isReady}
            />
        </>
    );
};

export default FeatCardDetailAudioPlayer;
