import React from 'react';
import { ISvgIcon } from '../../../types/icons';

export const ArrowIcon: React.FC<ISvgIcon> = ({ className, onClick }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            className={className}
            onClick={onClick}
        >
            <path
                d="M14.167 11.3327L17.5003 7.99935L14.167 4.66602"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.8333 16.3333H6.66667C4.36548 16.3333 2.5 14.4679 2.5 12.1667C2.5 9.86548 4.36548 8 6.66667 8H17.5"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
