import Modal from '../../shared/ui/Modal';
import { useCreateModalState } from '../../shared/hooks/modal';
import styles from './index.module.scss';
import Button from '../../shared/ui/Button';
import { useEffect, useState } from 'react';
import { getDayMothYearDateFormat } from '../../shared/utils/date';
import { useNavigate } from 'react-router-dom';
import { useAppNavigation } from '../../shared/hooks/navigation';

interface IContestBanner {
    isAlwaysActive?: boolean;
}

const ContestBanner: React.FC<IContestBanner> = ({ isAlwaysActive = false }) => {
    const { redirectToVote } = useAppNavigation();

    const [bannerStatus, setBannerStatus] = useState(isAlwaysActive);
    const navigate = useNavigate();
    const vkOnClick = () => {
        window.open('https://vk.com/app5898182_-218711548#s=2321967', '_blank');
    };
    useEffect(() => {
        const viewBanner = setTimeout(() => {
            const popUpData = localStorage.getItem('podvig-popup-data');
            if (popUpData === null) {
                const currentDate = getDayMothYearDateFormat(new Date().toISOString());
                const popUpData = {
                    [currentDate]: true,
                };
                localStorage.setItem('podvig-popup-data', JSON.stringify(popUpData));
                setBannerStatus(true);
            }
            if (popUpData) {
                const popUpDataHashMap = JSON.parse(popUpData);
                const currentDate = getDayMothYearDateFormat(new Date().toISOString());
                if (popUpDataHashMap[currentDate] === undefined) {
                    const newData = { ...popUpDataHashMap, [currentDate]: true };
                    localStorage.setItem('podvig-popup-data', JSON.stringify(newData));
                    setBannerStatus(true);
                }
            }
        }, 15000);
        return () => {
            clearTimeout(viewBanner);
        };
    }, []);
    return (
        <>
            {/* <Modal
                isOpen={bannerStatus}
                modalClassName={styles['banner__modal']}
                setStatus={() => {
                    setBannerStatus(false);
                }}
            >
                <article className={styles['banner']}>
                    <h2 className={styles['banner__title']}>
                        ПРИМИ УЧАСТИЕ В ЗРИТЕЛЬСКОМ ГОЛОСОВАНИИ В ФОТОКВЕСТЕ «СЛЕД»
                    </h2>

                    <span className={styles['banner__description']}>
                        Сравнивай пары фотографий, голосуй за ту, которая понравилась больше всего и
                        помоги определить будущих финалистов конкурса!
                    </span>
                    <div className={styles['banner__actions-container']}>
                        <Button
                            text="Принять участие"
                            variant="white"
                            type="button"
                            onClick={() => {
                                setBannerStatus(false);
                                redirectToVote();
                            }}
                        />
                    </div>
                </article>
            </Modal> */}
        </>
    );
};

export default ContestBanner;
