import React from 'react';
import Button from '../Button';
import styels from './index.module.scss';
import Modal from '../Modal';

interface IAlertModal {
    title: string;
    confirm: () => void;
    isOpen: boolean;
    toggleAlertStatus: () => void;
}

const AlertModal: React.FC<IAlertModal> = ({ confirm, title, isOpen, toggleAlertStatus }) => {
    return (
        <Modal isOpen={isOpen} modalClassName={styels['alert']}>
            <div className={styels['alert__modal']}>
                <h2 className={styels['alert__modal-title']}>{title}</h2>
                <div className={styels['alert__modal-btns-container']}>
                    <Button
                        text="Да"
                        variant="orange"
                        type="button"
                        onClick={() => {
                            confirm();
                            toggleAlertStatus();
                        }}
                    />
                    <Button
                        text="Нет"
                        variant="orange"
                        type="button"
                        onClick={() => {
                            toggleAlertStatus();
                        }}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default AlertModal;
