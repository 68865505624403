import { IFeatRubric } from './types';
import { BASE__API__URL } from '../../../shared/consts';

export async function rubricsRequest(): Promise<IFeatRubric[]> {
    try {
        const response = await fetch(`${BASE__API__URL}rubrics`);
        if (response.ok) {
            const data = await response.json();
            return data;
        } else {
            return [];
        }
    } catch (err) {
        return [];
    }
}
