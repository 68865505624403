import { useForm, SubmitHandler } from 'react-hook-form';
import { validateMail } from '../../../../shared/utils/validators';
import { MAIL__INPUT, PASSWORD__INPUT } from './consts';
import { ISignInForm } from './types';
import { signInRequest } from './api';
import { getSignInDto } from './utils';
import { changeAuthStatus, fetchUserData } from '../../../../entities/session';
import { useAppDispatch } from '../../../../shared/store';
import { createAlert, createAlertOptions } from '../../../../entities/alerts';
import { useState } from 'react';

type setModalStatus = () => void;

export function useSignInFormState(setModalStatus: setModalStatus) {
    const {
        register,
        handleSubmit,
        watch,
        reset,
        resetField,
        formState: { errors, isSubmitting },
    } = useForm<ISignInForm>({
        mode: 'onTouched',
        defaultValues: {
            mail: '',
            password: '',
        },
    });
    const [isMailnotVerif, setMailVerifStatus] = useState(false);
    const watchMail = watch(MAIL__INPUT);
    const watchPassword = watch(PASSWORD__INPUT);
    const dispatch = useAppDispatch();
    const submit: SubmitHandler<ISignInForm> = async (data) => {
        const response = await signInRequest(getSignInDto(data));
        if (response) {
            if (response.status === 200) {
                dispatch(changeAuthStatus(true));
                dispatch(fetchUserData());
                dispatch(
                    createAlert(
                        createAlertOptions(
                            'Добро пожаловать на подвиг.РФ!',
                            'Теперь ты можешь участвовать в наших конкурсах и ставить лайки в разделе Галерея'
                        )
                    )
                );
                setModalStatus();
            } else if (response.status === 428) {
                setMailVerifStatus(true);
                resetField(PASSWORD__INPUT);
                dispatch(
                    createAlert(
                        createAlertOptions(
                            'Не удалось войти',
                            'Для завершения регистрации, пожалуйста, подтвердите электронную почту.',
                            'middle'
                        )
                    )
                );
            } else {
                resetField(PASSWORD__INPUT);
                dispatch(
                    createAlert(
                        createAlertOptions(
                            'Что-то пошло не-так',
                            'Пожалуйста, попробуйте еще раз или обратитесь в службу поддержки Подвиг.РФ для получения помощи.',
                            'middle'
                        )
                    )
                );
            }
        }
    };

    const mailRegister = register(MAIL__INPUT, {
        required: 'Поле обязательно',
        validate: {
            validateMail: (v) => validateMail(v) || 'Введите email, указанный при регистрации.',
        },
        maxLength: {
            value: 100,
            message: 'превышено допустимое количество символов',
        },
    });

    const mailErrorText = errors?.mail && errors?.mail?.message;

    const passwordRegister = register(PASSWORD__INPUT, {
        required: 'Поле обязательно',
        maxLength: {
            value: 100,
            message: 'превышено допустимое количество символов',
        },
    });

    const passwordErrorText = errors?.password && errors?.password?.message;

    return {
        handleSubmit: handleSubmit(submit),
        watchMail,
        watchPassword,
        mailRegister,
        passwordRegister,
        mailErrorText,
        passwordErrorText,
        isMailnotVerif,
        isSubmitting,
    };
}
