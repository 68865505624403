import styels from './index.module.scss';
import clsx from 'clsx';

export interface IInputProps {
    title?: string;
    register: any;
    errorText?: any;
    type: string;
    isEmpty: boolean;
    className?: string;
    inputClassName?: string;
    isDisabled?: boolean;
    titleClassName?: string;
    atr?: Record<string, string | number>;
    variant?: 'primary' | 'admin';
}

const Input: React.FC<IInputProps> = ({
    title,
    register,
    errorText,
    type,
    isEmpty,
    className = '',
    inputClassName,
    isDisabled = false,
    titleClassName,
    variant = 'primary',
    atr,
}) => {
    return (
        <div className={clsx(className, styels['input__container'])}>
            <label htmlFor={title} className={styels['input__label']}>
                <span className={clsx(styels['input__title'], titleClassName)}>{title}</span>
                <input
                    className={clsx(
                        styels['input'],
                        inputClassName,
                        errorText && styels['input--invalid'],
                        !errorText && !isEmpty && styels['input--valid'],
                        isDisabled && styels['input--disabled'],
                        { primary: styels['input--primary'], admin: styels['input--admin'] }[
                            variant
                        ]
                    )}
                    type={type}
                    id={title}
                    readOnly={isDisabled ? true : false}
                    {...register}
                    {...atr}
                />{' '}
                <span className={styels['input__error-text']} title={errorText}>{errorText}</span>
            </label>
        </div>
    );
};

export default Input;
