import { ISignUpDto } from './types';
import { BASE__MEDIA__URL } from '../../../../shared/consts';

export async function signUpRequest(signUpDto: ISignUpDto) {
    try {
        const respoonse = await fetch(`${BASE__MEDIA__URL}user/register`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(signUpDto),
        });

        return respoonse;
    } catch (err) {
        return undefined;
    }
}
