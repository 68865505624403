import { useRef } from 'react';
import { useState, useEffect } from 'react';
import { Display } from '../../entities/featAudioPlayer/ui';
import { Controls } from '../../features/audioPlayer';
import { useAppSelector } from '../../shared/store';
import { useAppDispatch } from '../../shared/store';
import {
    currentAudioFeatId,
    setPlayingStatus,
    playingStatus,
    setAudioId,
    changePlayerActivityStatus,
    getAudioPlayerStatus,
} from '../../entities/featAudioPlayer/model';
import { getFeats } from '../../entities/feat/model/feat';
import { AudioProgressBar } from '../../shared/ui/AudioProgressBar';

const FeatAudioPlayer = () => {
    const dispatch = useAppDispatch();

    const feats = useAppSelector(getFeats);
    const audioFeats = feats.filter((feat) => feat.audio_url);
    const audioFeatId = useAppSelector(currentAudioFeatId);
    const audioPlayerStatus = useAppSelector(getAudioPlayerStatus);
    const isPlaying = useAppSelector(playingStatus);
    const currentAudioIndex = getInedx();
    const currentAudio = audioFeats[currentAudioIndex];
    const audioRef = useRef<HTMLAudioElement>(null);
    const [{ progressValue, progressMax }, setProgressBarState] = useState({
        progressValue: 0,
        progressMax: 0,
    });
    const [isReady, setIsReady] = useState(false);

    function getInedx() {
        const audioIndex = audioFeats.findIndex((audioFeat) => audioFeat.id === audioFeatId);

        return audioIndex !== -1 ? audioIndex : 0;
    }
    const togglePlayPause = () => {
        dispatch(setPlayingStatus(!isPlaying));
    };

    const onLoaded = (e: any) => {
        const seconds = Math.floor(e.target.duration);
        setProgressBarState((prevState) => {
            return { ...prevState, progressMax: seconds };
        });
    };

    const onDurationChange = (e: any) => {
        const duration = Math.floor(e.target.duration);

        setProgressBarState((prevState) => {
            return { ...prevState, progressMax: duration };
        });
    };

    const handleProgressChange = (e: any) => {
        const seconds = Math.floor(e.target.value);

        setProgressBarState((prevState) => {
            return { ...prevState, progressValue: seconds };
        });
        if (audioRef.current) {
            audioRef.current.currentTime = seconds;
        }
    };

    const onTimeUpdate = (e: any) => {
        const seconds = Math.floor(e.target.currentTime);
        setProgressBarState((prevState) => {
            return { ...prevState, progressValue: seconds };
        });
    };

    const onCanPlay = () => {
        setIsReady(true);
    };

    const switchNextAudio = () => {
        audioFeats.length - 1 > currentAudioIndex
            ? dispatch(setAudioId(audioFeats[currentAudioIndex + 1].id))
            : dispatch(setAudioId(audioFeats[0].id));
    };

    const switchPrevAudio = () => {
        0 >= currentAudioIndex
            ? dispatch(setAudioId(audioFeats[audioFeats.length - 1].id))
            : dispatch(setAudioId(audioFeats[currentAudioIndex + -1].id));
    };

    const playAudio = (id: number | undefined) => {
        dispatch(setAudioId(id));
    };

    const closeAudioPlayer = () => {
        dispatch(setPlayingStatus(false));
        dispatch(changePlayerActivityStatus(false));
    };

    useEffect(() => {
        try {
            const togglePlay = setTimeout(() => {
                if (audioRef.current) {
                    if (isPlaying) {
                        audioRef.current.play();
                    } else {
                        audioRef.current.pause();
                    }
                }
            }, 500);

            return () => {
                clearTimeout(togglePlay);
            };
        } catch (err) {
            console.log(err);
        }
    }, [isPlaying, audioFeatId]);

    return (
        <>
            {!!audioFeats.length && audioPlayerStatus && (
                <Display
                    controls={
                        <Controls
                            isPlaying={isPlaying}
                            switchNextAudio={switchNextAudio}
                            togglePlayPause={togglePlayPause}
                            switchPrevAudio={switchPrevAudio}
                            closePlayer={closeAudioPlayer}
                            playList={audioFeats.map((audioFeat) => {
                                return { option: audioFeat.name, value: audioFeat.id };
                            })}
                            playAudio={playAudio}
                            currentAudio={{ option: currentAudio.name, value: currentAudio.id }}
                        />
                    }
                    progressBar={
                        <AudioProgressBar
                            handleProgressChange={handleProgressChange}
                            value={progressValue}
                            max={progressMax}
                        />
                    }
                    currentAudio={currentAudio}
                    ref={audioRef}
                    onLoaded={onLoaded}
                    onEnded={switchNextAudio}
                    onTimeUpdate={onTimeUpdate}
                    onDurationChange={onDurationChange}
                    onCanPlay={onCanPlay}
                    isReady={isReady}
                />
            )}
        </>
    );
};

export default FeatAudioPlayer;
