import { useState } from 'react';

export function useCreateModalState(initState = false) {
    const [isModalOpen, setModalStatus] = useState(initState);
    const toggleModalStatus = () => {
        setModalStatus(!isModalOpen);
    };

    const closeModal = () => {
        setModalStatus(false);
    };

    return {
        isModalOpen,
        toggleModalStatus,
        closeModal,
    };
}
