import { APP__ROUTS } from '../../shared/consts';
import { Suspense } from 'react';
import { lazyRetry } from '../../shared/utils/lazyRetry';
import { loadFeatById } from '../../entities/feat/model/loader';
import PagePreloader from '../../shared/ui/PagePreloader';

const FeatsPageLazy = lazyRetry(() => import('../../pages/FeatsPage/index'));
const FeatPopUpLazy = lazyRetry(() => import('../../pages/FeatsPage/ui/FeatPreloaderModal'));

export const FeatsRoutes = [
    {
        path: APP__ROUTS.feats,
        element: (
            <Suspense fallback={<PagePreloader />}>
                <FeatsPageLazy />
            </Suspense>
        ),
        children: [
            {
                path: APP__ROUTS.featsId,
                loader: loadFeatById,
                element: (
                    <Suspense>
                        <FeatPopUpLazy />
                    </Suspense>
                ),
            },
        ],
    },
];
