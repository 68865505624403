import React, { ReactNode } from 'react';
import styles from './index.module.scss';
import { useId } from 'react';
import clsx from 'clsx';

interface ICheckBox {
    isCorrect?: boolean;
    isIncorrect?: boolean;
    text: ReactNode;
    className?: string;
    isChecked?: boolean;
    onClick?: () => void;
    register?: any;
}

export const CheckBox: React.FC<ICheckBox> = ({
    text,
    isCorrect,
    isIncorrect,
    className,
    isChecked ,
    onClick,
    register = {},
}) => {
    const id = useId();
    return (
        <div
            className={clsx(
                className,
                styles.container,
                isCorrect && styles.container__correct,
                isIncorrect && styles.container__incorrect
            )}
        >
            <div
                className={clsx(
                    styles['form-group'],
                    (isCorrect || isIncorrect) && styles['answered']
                )}
                onClick={(e) => {
                    onClick && onClick();
                    e.stopPropagation();
                }}
            >
                <input
                    id={id}
                    type="checkbox"
                    checked={isChecked}
                    className={styles.checkbox}
                    {...register}
                />
                <label htmlFor={id}>{text}</label>
            </div>
        </div>
    );
};
