import AdminNavPanel from '../AdminNavPanel';
import { Outlet } from 'react-router-dom';
import { getUserData } from '../../../../entities/session';
import { useAppSelector } from '../../../../shared/store';
import { Navigate } from 'react-router-dom';
import { APP__ROUTS } from '../../../../shared/consts';

export const AdminLayout = () => {
    const userData = useAppSelector(getUserData);

    return (
        <>
            {userData?.is_admin ? (
                <section>
                    <AdminNavPanel />
                    <Outlet />
                </section>
            ) : (
                <Navigate to={APP__ROUTS.main} replace />
            )}
        </>
    );
};
