import { ProfileHeader } from '../../../../widgets/ProfileHeader';
import { Outlet } from 'react-router-dom';
import AuthGuard from '../../../../widgets/AuthGuard';
import { Alerts } from '../../../../entities/alerts';
import styles from './index.module.scss';
import  ContestBanner  from '../../../../widgets/ContestBanner';

export const ProfileLayout = () => {
    return (
        <AuthGuard>
            <ProfileHeader />
            <ContestBanner />
            <Alerts />
            <main className={styles['profile-layout']}>
                <Outlet />
            </main>
        </AuthGuard>
    );
};
