import styles from './index.module.scss';
import { ReactNode } from 'react';

interface IMobileNavListpROPS {
    children: ReactNode;
}

const MobileNavList: React.FC<IMobileNavListpROPS> = ({ children }) => {
    return <ul className={styles['mobile-nav__links-list']}>{children}</ul>;
};

export default MobileNavList;
