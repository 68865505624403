import { IResetPassDto } from './types';
import { BASE__API__URL } from '../../../../shared/consts';

export async function resetPassRequest(resetPassDto: IResetPassDto) {
    try {
        const response = await fetch(`${BASE__API__URL}user/forgot_password`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(resetPassDto),
        });
        if (response.ok) {
            return true;
        } else {
            return false;
        }
    } catch (err) {
        console.log(err);
    }
}
