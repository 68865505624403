import React, { useState } from 'react';
import styels from './index.module.scss';
import PauseIcon from '../../../../shared/assets/PauseIcon';
import ViewIcon from '../../assets/ViewIcon';
import ShareButton from '../../../../shared/ui/ShareButton';
import { BASE__MEDIA__URL } from '../../../../shared/consts';
import { getDayMothYearDateFormat } from '../../../../shared/utils/date';
import { fetchFeatById } from '../../model/api';
import { useAppDispatch } from '../../../../shared/store';
import ErrorImg from '../../../../shared/assets/ErrorImg';
import clsx from 'clsx';
import { IFeat } from '../../model/types';
import { EarphoneIcon } from '../../assets/earphone';
import { AuthorInfo } from '../../../../shared/ui/AuthorInfo/AuthorInfo';

interface IFeatPreview {
    isPlaying: boolean;
    playAudio: (event: React.MouseEvent, id: number) => void;
    stopAudio: (event: React.MouseEvent) => void;
    isChoosen: boolean;
    shareIconOnClick?: () => void;
    toggleModalStatus: () => void;
    shareVkOnClick?: () => void;
    feat: IFeat;
}

export const FeatPreview: React.FC<IFeatPreview> = ({
    isPlaying,
    stopAudio,
    playAudio,
    isChoosen,
    shareIconOnClick,
    toggleModalStatus,
    shareVkOnClick,
    feat,
}) => {
    const formatDate = getDayMothYearDateFormat(feat.publish_date);
    const [isImageCorrect, setCorrectStatus] = useState(true);

    const dispatch = useAppDispatch();

    const openFeatDetails = () => {
        toggleModalStatus();
        dispatch(fetchFeatById(feat.id));
    };

    const audioIcon =
        isPlaying && isChoosen ? (
            <PauseIcon
                onClick={(event) => {
                    stopAudio(event);
                }}
                className={styels['feat-card__play-icon']}
            />
        ) : (
            <EarphoneIcon
                onClick={(event) => {
                    playAudio(event, feat.id);
                }}
                className={styels['feat-card__play-icon']}
            />
        );
    return (
        <div className={styels['feat-card__container']} onClick={openFeatDetails}>
            <article className={styels['feat-card']}>
                <div className={styels['feat-card__img-container']}>
                    <div className={styels['feat-card__img-container-header']}>
                        <div className={styels['feat-card__rubrics']}>
                            {feat.rubrics.map((el) => (
                                <div key={el} className={styels['feat-card__rubrics-item']}>
                                    <div
                                        title={el}
                                        className={styels['feat-card__rubrics-item-value']}
                                    >
                                        {el}
                                    </div>
                                </div>
                            ))}
                        </div>
                        {feat.audio_url && audioIcon}
                    </div>
                    {isImageCorrect ? (
                        <img
                            className={styels['feat-card__img']}
                            src={`${BASE__MEDIA__URL}${feat.image_url}`}
                            alt="иллюстрация к работе"
                            onError={() => setCorrectStatus(false)}
                        />
                    ) : (
                        <ErrorImg className={clsx(styels['feat-card__img'])} />
                    )}
                </div>
                <div className={styels['feat-card__preview-detail']}>
                    <h2 className={styels['feat-card__title']}>{feat.name}</h2>

                    <AuthorInfo
                        author={feat.hero}
                        publishDateDMY={`${feat.hero_city ? feat.hero_city + ', ' : ''}${formatDate}`}
                        src={feat.image_url}
                    />
                </div>

                <div className={styels['feat-card__footer']}>
                    <div className={styels['feat-card__view-stat']}>
                        <ViewIcon className={styels['feat-card__view-icon']} variant={1} />
                        <span>{feat.views}</span>
                    </div>
                    <ShareButton
                        shareIconOnClick={shareIconOnClick}
                        shareListClasses=""
                        shareVkOnClick={shareVkOnClick}
                        type="arrow-btn"
                    />
                </div>
            </article>
        </div>
    );
};

export default FeatPreview;
