import React, { ReactNode } from 'react';
import { useEffect } from 'react';
import { useAppDispatch } from '../../shared/store';
import { fetchUserData, fetchSignInvK } from '../../entities/session';
import queryString from 'query-string';
import { useSearchParams } from 'react-router-dom';

interface IAuthWrapper {
    children: ReactNode;
}

const AuthWrapper: React.FC<IAuthWrapper> = ({ children }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    useEffect(() => {
        const handleVkLogin = async (code: string) => {
            if (searchParams.has('code')) {
                searchParams.delete('code');
                setSearchParams(searchParams);
            }
            await dispatch(fetchSignInvK(code));
            await dispatch(fetchUserData());
        };

        const queryObj: any = queryString.parse(window.location.search);
        queryObj.code ? handleVkLogin(queryObj.code) : dispatch(fetchUserData());
    }, []);
    return <>{children}</>;
};

export default AuthWrapper;
