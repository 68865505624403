import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../shared/store';
import { PayloadAction } from '@reduxjs/toolkit';

interface AudioPlayerState {
    isActive: boolean;
    currentAudioFeatId: number | null | undefined;
    isPlaying: boolean;
}

const initialState: AudioPlayerState = {
    isActive: false,
    currentAudioFeatId: null,
    isPlaying: false,
};

export const audiopPlayerSlice = createSlice({
    name: 'audio-player',
    initialState,
    reducers: {
        changePlayerActivityStatus: (state, action: PayloadAction<boolean>) => {
            state.isActive = action.payload;
        },

        setPlayingStatus: (state, action: PayloadAction<boolean>) => {
            state.isPlaying = action.payload;
        },
        setAudioId: (state, action: PayloadAction<number | null | undefined>) => {
            state.currentAudioFeatId = action.payload;
        },
    },
});

export const getAudioPlayerStatus = (state: RootState) => state['audio-player'].isActive;
export const currentAudioFeatId = (state: RootState) => state['audio-player'].currentAudioFeatId;
export const playingStatus = (state: RootState) => state['audio-player'].isPlaying;

export const { changePlayerActivityStatus, setPlayingStatus, setAudioId } =
    audiopPlayerSlice.actions;
