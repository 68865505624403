import styles from './index.module.scss';
import { ReactNode } from 'react';

interface IHedaerLayoutProps {
    children: ReactNode;
}

const HedaerLayout: React.FC<IHedaerLayoutProps> = ({ children }) => {
    return <header className={styles['header']}>{children}</header>;
};

export default HedaerLayout;
