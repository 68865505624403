import React from 'react';
import { ReactNode } from 'react';
import styles from './index.module.scss';
import clsx from 'clsx';

interface IFooterLayoutProps {
    children: ReactNode;
    className?: string;
}

const FooterLayout: React.FC<IFooterLayoutProps> = ({ children, className = '' }) => {
    return (
        <footer className={clsx(styles['footer'], className)}>
            <div className={styles['footer__container']}>{children} </div>
        </footer>
    );
};

export default FooterLayout;
