export const cities = [
    {
        value: 'Москва',
    },
    {
        value: 'Абрамцево',
    },
    {
        value: 'Алабино',
    },
    {
        value: 'Апрелевка',
    },
    {
        value: 'Архангельское',
    },
    {
        value: 'Ашитково',
    },
    {
        value: 'Байконур',
    },
    {
        value: 'Бакшеево',
    },
    {
        value: 'Балашиха',
    },
    {
        value: 'Барыбино',
    },
    {
        value: 'Белоомут',
    },
    {
        value: 'Белые Столбы',
    },
    {
        value: 'Бородино',
    },
    {
        value: 'Бронницы',
    },
    {
        value: 'Быково',
    },
    {
        value: 'Валуево',
    },
    {
        value: 'Вербилки',
    },
    {
        value: 'Верея',
    },
    {
        value: 'Видное',
    },
    {
        value: 'Внуково',
    },
    {
        value: 'Вождь Пролетариата',
    },
    {
        value: 'Волоколамск',
    },
    {
        value: 'Вороново',
    },
    {
        value: 'Воскресенск',
    },
    {
        value: 'Восточный',
    },
    {
        value: 'Востряково',
    },
    {
        value: 'Высоковск',
    },
    {
        value: 'Голицино',
    },
    {
        value: 'Деденево',
    },
    {
        value: 'Дедовск',
    },
    {
        value: 'Джержинский',
    },
    {
        value: 'Дмитров',
    },
    {
        value: 'Долгопрудный',
    },
    {
        value: 'Домодедово',
    },
    {
        value: 'Дорохово',
    },
    {
        value: 'Дрезна',
    },
    {
        value: 'Дубки',
    },
    {
        value: 'Дубна',
    },
    {
        value: 'Егорьевск',
    },
    {
        value: 'Железнодорожный',
    },
    {
        value: 'Жилево',
    },
    {
        value: 'Жуковский',
    },
    {
        value: 'Загорск',
    },
    {
        value: 'Загорянский',
    },
    {
        value: 'Запрудная',
    },
    {
        value: 'Зарайск',
    },
    {
        value: 'Звенигород',
    },
    {
        value: 'Зеленоград',
    },
    {
        value: 'Ивантеевка',
    },
    {
        value: 'Икша',
    },
    {
        value: 'Ильинский',
    },
    {
        value: 'Истра',
    },
    {
        value: 'Калининград',
    },
    {
        value: 'Кашира',
    },
    {
        value: 'Керва',
    },
    {
        value: 'Климовск',
    },
    {
        value: 'Клин',
    },
    {
        value: 'Клязьма',
    },
    {
        value: 'Кожино',
    },
    {
        value: 'Кокошкино',
    },
    {
        value: 'Коломна',
    },
    {
        value: 'Колюбакино',
    },
    {
        value: 'Королев',
    },
    {
        value: 'Косино',
    },
    {
        value: 'Котельники',
    },
    {
        value: 'Красково',
    },
    {
        value: 'Красноармейск',
    },
    {
        value: 'Красногорск',
    },
    {
        value: 'Краснозаводск',
    },
    {
        value: 'Краснознаменск',
    },
    {
        value: 'Красный Ткач',
    },
    {
        value: 'Крюково',
    },
    {
        value: 'Кубинка',
    },
    {
        value: 'Купавна',
    },
    {
        value: 'Куровское',
    },
    {
        value: 'Лесной Городок',
    },
    {
        value: 'Ликино-Дулево',
    },
    {
        value: 'Лобня',
    },
    {
        value: 'Лопатинский',
    },
    {
        value: 'Лосино-Петровский',
    },
    {
        value: 'Лотошино',
    },
    {
        value: 'Лукино',
    },
    {
        value: 'Луховицы',
    },
    {
        value: 'Лыткарино',
    },
    {
        value: 'Львовский',
    },
    {
        value: 'Люберцы',
    },
    {
        value: 'Малаховка',
    },
    {
        value: 'Михайловское',
    },
    {
        value: 'Михнево',
    },
    {
        value: 'Можайск',
    },
    {
        value: 'Монино',
    },
    {
        value: 'Муханово',
    },
    {
        value: 'Мытищи',
    },
    {
        value: 'Нарофоминск',
    },
    {
        value: 'Нахабино',
    },
    {
        value: 'Некрасовка',
    },
    {
        value: 'Немчиновка',
    },
    {
        value: 'Новобратцевский',
    },
    {
        value: 'Новоподрезково',
    },
    {
        value: 'Ногинск',
    },
    {
        value: 'Обухово',
    },
    {
        value: 'Одинцово',
    },
    {
        value: 'Ожерелье',
    },
    {
        value: 'Озеры',
    },
    {
        value: 'Октябрьский',
    },
    {
        value: 'Опалиха',
    },
    {
        value: 'Орехово-Зуево',
    },
    {
        value: 'Павловский Посад',
    },
    {
        value: 'Первомайский',
    },
    {
        value: 'Пески',
    },
    {
        value: 'Пироговский',
    },
    {
        value: 'Подольск',
    },
    {
        value: 'Полушкино',
    },
    {
        value: 'Правдинский',
    },
    {
        value: 'Привокзальный',
    },
    {
        value: 'Пролетарский',
    },
    {
        value: 'Протвино',
    },
    {
        value: 'Пушкино',
    },
    {
        value: 'Пущино',
    },
    {
        value: 'Радовицкий',
    },
    {
        value: 'Раменское',
    },
    {
        value: 'Реутов',
    },
    {
        value: 'Решетниково',
    },
    {
        value: 'Родники',
    },
    {
        value: 'Рошаль',
    },
    {
        value: 'Рублево',
    },
    {
        value: 'Руза',
    },
    {
        value: 'Салтыковка',
    },
    {
        value: 'Северный',
    },
    {
        value: 'Сергиев Посад',
    },
    {
        value: 'Серебряные Пруды',
    },
    {
        value: 'Серпухов',
    },
    {
        value: 'Солнечногорск',
    },
    {
        value: 'Солнцево',
    },
    {
        value: 'Софрино',
    },
    {
        value: 'Старая Купавна',
    },
    {
        value: 'Старбеево',
    },
    {
        value: 'Ступино',
    },
    {
        value: 'Сходня',
    },
    {
        value: 'Талдом',
    },
    {
        value: 'Текстильщик',
    },
    {
        value: 'Темпы',
    },
    {
        value: 'Тишково',
    },
    {
        value: 'Томилино',
    },
    {
        value: 'Троицк',
    },
    {
        value: 'Туголесский Бор',
    },
    {
        value: 'Тучково',
    },
    {
        value: 'Уваровка',
    },
    {
        value: 'Удельная',
    },
    {
        value: 'Успенское',
    },
    {
        value: 'Фирсановка',
    },
    {
        value: 'Фосфоритный',
    },
    {
        value: 'Фрязино',
    },
    {
        value: 'Фряново',
    },
    {
        value: 'Химки',
    },
    {
        value: 'Хорлово',
    },
    {
        value: 'Хотьково',
    },
    {
        value: 'Черкизово',
    },
    {
        value: 'Черноголовка',
    },
    {
        value: 'Черусти',
    },
    {
        value: 'Чехов',
    },
    {
        value: 'Шарапово',
    },
    {
        value: 'Шатура',
    },
    {
        value: 'Шатурторф',
    },
    {
        value: 'Шаховская',
    },
    {
        value: 'Шереметьевский',
    },
    {
        value: 'Щелково',
    },
    {
        value: 'Щербинка',
    },
    {
        value: 'Электрогорск',
    },
    {
        value: 'Электросталь',
    },
    {
        value: 'Электроугли',
    },
    {
        value: 'Яхрома',
    },
    {
        value: 'Санкт-Петербург',
    },
    {
        value: 'Александровская',
    },
    {
        value: 'Бокситогорск',
    },
    {
        value: 'Большая Ижора',
    },
    {
        value: 'Будогощь',
    },
    {
        value: 'Вознесенье',
    },
    {
        value: 'Волосово',
    },
    {
        value: 'Волхов',
    },
    {
        value: 'Всеволожск',
    },
    {
        value: 'Выборг',
    },
    {
        value: 'Вырица',
    },
    {
        value: 'Высоцк',
    },
    {
        value: 'Гатчина',
    },
    {
        value: 'Дружная Горка',
    },
    {
        value: 'Дубровка',
    },
    {
        value: 'Ефимовский',
    },
    {
        value: 'Зеленогорск',
    },
    {
        value: 'Ивангород',
    },
    {
        value: 'Каменногорск',
    },
    {
        value: 'Кикерино',
    },
    {
        value: 'Кингисепп',
    },
    {
        value: 'Кириши',
    },
    {
        value: 'Кировск',
    },
    {
        value: 'Кобринское',
    },
    {
        value: 'Колпино',
    },
    {
        value: 'Коммунар',
    },
    {
        value: 'Кронштадт',
    },
    {
        value: 'Лисий Нос',
    },
    {
        value: 'Лодейное Поле',
    },
    {
        value: 'Ломоносов',
    },
    {
        value: 'Луга',
    },
    {
        value: 'Павловск',
    },
    {
        value: 'Парголово',
    },
    {
        value: 'Петродворец',
    },
    {
        value: 'Пикалёво',
    },
    {
        value: 'Подпорожье',
    },
    {
        value: 'Приозерск',
    },
    {
        value: 'Пушкин',
    },
    {
        value: 'Сестрорецк',
    },
    {
        value: 'Сланцы',
    },
    {
        value: 'Сосновый Бор',
    },
    {
        value: 'Тихвин',
    },
    {
        value: 'Тосно',
    },
    {
        value: 'Шлиссельбург',
    },
    {
        value: 'Адыгейск',
    },
    {
        value: 'Майкоп',
    },
    {
        value: 'Акташ',
    },
    {
        value: 'Акутиха',
    },
    {
        value: 'Алейск',
    },
    {
        value: 'Алтайский',
    },
    {
        value: 'Баево',
    },
    {
        value: 'Барнаул',
    },
    {
        value: 'Белово',
    },
    {
        value: 'Белокуриха',
    },
    {
        value: 'Белоярск',
    },
    {
        value: 'Бийск',
    },
    {
        value: 'Благовещенск',
    },
    {
        value: 'Боровлянка',
    },
    {
        value: 'Бурла',
    },
    {
        value: 'Бурсоль',
    },
    {
        value: 'Волчиха',
    },
    {
        value: 'Горно-Алтайск',
    },
    {
        value: 'Горняк',
    },
    {
        value: 'Ельцовка',
    },
    {
        value: 'Залесово',
    },
    {
        value: 'Заринск',
    },
    {
        value: 'Заток',
    },
    {
        value: 'Змеиногорск',
    },
    {
        value: 'Камень-на-Оби',
    },
    {
        value: 'Ключи',
    },
    {
        value: 'Кош-Агач',
    },
    {
        value: 'Красногорское',
    },
    {
        value: 'Краснощеково',
    },
    {
        value: 'Кулунда',
    },
    {
        value: 'Кытманово',
    },
    {
        value: 'Мамонтово',
    },
    {
        value: 'Новичиха',
    },
    {
        value: 'Новоалтайск',
    },
    {
        value: 'Онгудай',
    },
    {
        value: 'Павловск',
    },
    {
        value: 'Петропавловское',
    },
    {
        value: 'Поспелиха',
    },
    {
        value: 'Ребриха',
    },
    {
        value: 'Родино',
    },
    {
        value: 'Рубцовск',
    },
    {
        value: 'Славгород',
    },
    {
        value: 'Смоленское',
    },
    {
        value: 'Солонешное',
    },
    {
        value: 'Солтон',
    },
    {
        value: 'Староаллейское',
    },
    {
        value: 'Табуны',
    },
    {
        value: 'Тальменка',
    },
    {
        value: 'Топчиха',
    },
    {
        value: 'Троицкое',
    },
    {
        value: 'Турочак',
    },
    {
        value: 'Тюменцево',
    },
    {
        value: 'Угловское',
    },
    {
        value: 'Усть-Калманка',
    },
    {
        value: 'Усть-Кан',
    },
    {
        value: 'Усть-Кокса',
    },
    {
        value: 'Усть-Улаган',
    },
    {
        value: 'Усть-Чарышская Пристань',
    },
    {
        value: 'Хабары',
    },
    {
        value: 'Целинное',
    },
    {
        value: 'Чарышское',
    },
    {
        value: 'Шебалино',
    },
    {
        value: 'Шелаболиха',
    },
    {
        value: 'Шипуново',
    },
    {
        value: 'Айгунь',
    },
    {
        value: 'Архара',
    },
    {
        value: 'Белогорск',
    },
    {
        value: 'Благовещенск (Амурская обл.)',
    },
    {
        value: 'Бурея',
    },
    {
        value: 'Возжаевка',
    },
    {
        value: 'Екатеринославка',
    },
    {
        value: 'Ерофей Павлович',
    },
    {
        value: 'Завитинск',
    },
    {
        value: 'Зея',
    },
    {
        value: 'Златоустовск',
    },
    {
        value: 'Ивановка',
    },
    {
        value: 'Коболдо',
    },
    {
        value: 'Магдагачи',
    },
    {
        value: 'Новобурейский',
    },
    {
        value: 'Поярково',
    },
    {
        value: 'Райчихинск',
    },
    {
        value: 'Ромны',
    },
    {
        value: 'Свободный',
    },
    {
        value: 'Серышево',
    },
    {
        value: 'Сковородино',
    },
    {
        value: 'Стойба',
    },
    {
        value: 'Тамбовка',
    },
    {
        value: 'Тында',
    },
    {
        value: 'Шимановск',
    },
    {
        value: 'Экимчан',
    },
    {
        value: 'Ядрино',
    },
    {
        value: 'Амдерма',
    },
    {
        value: 'Архангельск',
    },
    {
        value: 'Березник',
    },
    {
        value: 'Вельск',
    },
    {
        value: 'Верхняя Тойма',
    },
    {
        value: 'Волошка',
    },
    {
        value: 'Вычегодский',
    },
    {
        value: 'Емца',
    },
    {
        value: 'Илеза',
    },
    {
        value: 'Ильинско-Подомское',
    },
    {
        value: 'Каргополь',
    },
    {
        value: 'Карпогоры',
    },
    {
        value: 'Кодино',
    },
    {
        value: 'Коноша',
    },
    {
        value: 'Коряжма',
    },
    {
        value: 'Котлас',
    },
    {
        value: 'Красноборск',
    },
    {
        value: 'Лешуконское',
    },
    {
        value: 'Мезень',
    },
    {
        value: 'Мирный',
    },
    {
        value: 'Нарьян-Мар',
    },
    {
        value: 'Новодвинск',
    },
    {
        value: 'Няндома',
    },
    {
        value: 'Онега',
    },
    {
        value: 'Пинега',
    },
    {
        value: 'Плесецк',
    },
    {
        value: 'Северодвинск',
    },
    {
        value: 'Сольвычегодск',
    },
    {
        value: 'Холмогоры',
    },
    {
        value: 'Шенкурск',
    },
    {
        value: 'Яренск',
    },
    {
        value: 'Астрахань',
    },
    {
        value: 'Ахтубинск',
    },
    {
        value: 'Верхний Баскунчак',
    },
    {
        value: 'Володарский',
    },
    {
        value: 'Енотаевка',
    },
    {
        value: 'Икряное',
    },
    {
        value: 'Камызяк',
    },
    {
        value: 'Капустин Яр',
    },
    {
        value: 'Красный Яр',
    },
    {
        value: 'Лиман',
    },
    {
        value: 'Началово',
    },
    {
        value: 'Харабали',
    },
    {
        value: 'Черный Яр',
    },
    {
        value: 'Аксаково',
    },
    {
        value: 'Амзя',
    },
    {
        value: 'Аскино',
    },
    {
        value: 'Баймак',
    },
    {
        value: 'Бакалы',
    },
    {
        value: 'Белебей',
    },
    {
        value: 'Белорецк',
    },
    {
        value: 'Бижбуляк',
    },
    {
        value: 'Бирск',
    },
    {
        value: 'Благовещенск',
    },
    {
        value: 'Большеустьикинское',
    },
    {
        value: 'Бураево',
    },
    {
        value: 'Верхнеяркеево',
    },
    {
        value: 'Верхние Киги',
    },
    {
        value: 'Верхние Татышлы',
    },
    {
        value: 'Верхний Авзян',
    },
    {
        value: 'Давлеканово',
    },
    {
        value: 'Дуван',
    },
    {
        value: 'Дюртюли',
    },
    {
        value: 'Ермекеево',
    },
    {
        value: 'Ермолаево',
    },
    {
        value: 'Зилаир',
    },
    {
        value: 'Зирган',
    },
    {
        value: 'Иглино',
    },
    {
        value: 'Инзер',
    },
    {
        value: 'Исянгулово',
    },
    {
        value: 'Ишимбай',
    },
    {
        value: 'Кананикольское',
    },
    {
        value: 'Кандры',
    },
    {
        value: 'Караидель',
    },
    {
        value: 'Караидельский',
    },
    {
        value: 'Киргиз-Мияки',
    },
    {
        value: 'Красноусольский',
    },
    {
        value: 'Кумертау',
    },
    {
        value: 'Кушнаренково',
    },
    {
        value: 'Малояз',
    },
    {
        value: 'Мелеуз',
    },
    {
        value: 'Месягутово',
    },
    {
        value: 'Мраково',
    },
    {
        value: 'Нефтекамск',
    },
    {
        value: 'Октябрьский',
    },
    {
        value: 'Раевский',
    },
    {
        value: 'Салават',
    },
    {
        value: 'Сибай',
    },
    {
        value: 'Старобалтачево',
    },
    {
        value: 'Старосубхангулово',
    },
    {
        value: 'Стерлибашево',
    },
    {
        value: 'Стерлитамак',
    },
    {
        value: 'Туймазы',
    },
    {
        value: 'Уфа',
    },
    {
        value: 'Учалы',
    },
    {
        value: 'Федоровка',
    },
    {
        value: 'Чекмагуш',
    },
    {
        value: 'Чишмы',
    },
    {
        value: 'Шаран',
    },
    {
        value: 'Янаул',
    },
    {
        value: 'Алексеевка',
    },
    {
        value: 'Белгород',
    },
    {
        value: 'Борисовка',
    },
    {
        value: 'Валуйки',
    },
    {
        value: 'Вейделевка',
    },
    {
        value: 'Волоконовка',
    },
    {
        value: 'Грайворон',
    },
    {
        value: 'Губкин',
    },
    {
        value: 'Ивня',
    },
    {
        value: 'Короча',
    },
    {
        value: 'Красногвардейское',
    },
    {
        value: 'Новый Оскол',
    },
    {
        value: 'Ракитное',
    },
    {
        value: 'Ровеньки',
    },
    {
        value: 'Старый Оскол',
    },
    {
        value: 'Строитель',
    },
    {
        value: 'Чернянка',
    },
    {
        value: 'Шебекино',
    },
    {
        value: 'Алтухово',
    },
    {
        value: 'Белая Березка',
    },
    {
        value: 'Белые Берега',
    },
    {
        value: 'Большое Полпино',
    },
    {
        value: 'Брянск',
    },
    {
        value: 'Бытошь',
    },
    {
        value: 'Выгоничи',
    },
    {
        value: 'Вышков',
    },
    {
        value: 'Гордеевка',
    },
    {
        value: 'Дубровка',
    },
    {
        value: 'Дятьково',
    },
    {
        value: 'Жирятино',
    },
    {
        value: 'Жуковка',
    },
    {
        value: 'Злынка',
    },
    {
        value: 'Ивот',
    },
    {
        value: 'Карачев',
    },
    {
        value: 'Клетня',
    },
    {
        value: 'Климово',
    },
    {
        value: 'Клинцы',
    },
    {
        value: 'Кокаревка',
    },
    {
        value: 'Комаричи',
    },
    {
        value: 'Красная Гора',
    },
    {
        value: 'Локоть',
    },
    {
        value: 'Мглин',
    },
    {
        value: 'Навля',
    },
    {
        value: 'Новозыбков',
    },
    {
        value: 'Погар',
    },
    {
        value: 'Почеп',
    },
    {
        value: 'Ржаница',
    },
    {
        value: 'Рогнедино',
    },
    {
        value: 'Севск',
    },
    {
        value: 'Стародуб',
    },
    {
        value: 'Суземка',
    },
    {
        value: 'Сураж',
    },
    {
        value: 'Трубчевск',
    },
    {
        value: 'Унеча',
    },
    {
        value: 'Бабушкин',
    },
    {
        value: 'Багдарин',
    },
    {
        value: 'Баргузин',
    },
    {
        value: 'Баянгол',
    },
    {
        value: 'Бичура',
    },
    {
        value: 'Выдрино',
    },
    {
        value: 'Гусиное Озеро',
    },
    {
        value: 'Гусиноозерск',
    },
    {
        value: 'Заиграево',
    },
    {
        value: 'Закаменск',
    },
    {
        value: 'Иволгинск',
    },
    {
        value: 'Илька',
    },
    {
        value: 'Кабанск',
    },
    {
        value: 'Каменск',
    },
    {
        value: 'Кижинга',
    },
    {
        value: 'Курумкан',
    },
    {
        value: 'Кырен',
    },
    {
        value: 'Кяхта',
    },
    {
        value: 'Монды',
    },
    {
        value: 'Мухоршибирь',
    },
    {
        value: 'Нижнеангарск',
    },
    {
        value: 'Орлик',
    },
    {
        value: 'Петропавловка',
    },
    {
        value: 'Романовка',
    },
    {
        value: 'Северобайкальск',
    },
    {
        value: 'Селенгинск',
    },
    {
        value: 'Сосново-Озерское',
    },
    {
        value: 'Таксимо',
    },
    {
        value: 'Турунтаево',
    },
    {
        value: 'Улан-Удэ',
    },
    {
        value: 'Хоринск',
    },
    {
        value: 'Александров',
    },
    {
        value: 'Андреево',
    },
    {
        value: 'Анопино',
    },
    {
        value: 'Бавлены',
    },
    {
        value: 'Балакирево',
    },
    {
        value: 'Боголюбово',
    },
    {
        value: 'Великодворский',
    },
    {
        value: 'Вербовский',
    },
    {
        value: 'Владимир',
    },
    {
        value: 'Вязники',
    },
    {
        value: 'Городищи',
    },
    {
        value: 'Гороховец',
    },
    {
        value: 'Гусевский',
    },
    {
        value: 'Гусь Хрустальный',
    },
    {
        value: 'Золотково',
    },
    {
        value: 'Иванищи',
    },
    {
        value: 'Камешково',
    },
    {
        value: 'Карабаново',
    },
    {
        value: 'Киржач',
    },
    {
        value: 'Ковров',
    },
    {
        value: 'Кольчугино',
    },
    {
        value: 'Красная Горбатка',
    },
    {
        value: 'Меленки',
    },
    {
        value: 'Муром',
    },
    {
        value: 'Петушки',
    },
    {
        value: 'Покров',
    },
    {
        value: 'Собинка',
    },
    {
        value: 'Судогда',
    },
    {
        value: 'Суздаль',
    },
    {
        value: 'Юрьев-Польский',
    },
    {
        value: 'Алексеевская',
    },
    {
        value: 'Алущевск',
    },
    {
        value: 'Быково',
    },
    {
        value: 'Волгоград',
    },
    {
        value: 'Волжский',
    },
    {
        value: 'Городище',
    },
    {
        value: 'Дубовка',
    },
    {
        value: 'Елань',
    },
    {
        value: 'Жирновск',
    },
    {
        value: 'Иловля',
    },
    {
        value: 'Калач-на-Дону',
    },
    {
        value: 'Камышин',
    },
    {
        value: 'Кириллов',
    },
    {
        value: 'Клетский',
    },
    {
        value: 'Котельниково',
    },
    {
        value: 'Котово',
    },
    {
        value: 'Кумылженская',
    },
    {
        value: 'Ленинск',
    },
    {
        value: 'Михайловка',
    },
    {
        value: 'Нехаевский',
    },
    {
        value: 'Николаевск',
    },
    {
        value: 'Новоаннинский',
    },
    {
        value: 'Новониколаевский',
    },
    {
        value: 'Ольховка',
    },
    {
        value: 'Палласовка',
    },
    {
        value: 'Рудня',
    },
    {
        value: 'Светлый Яр',
    },
    {
        value: 'Серафимович',
    },
    {
        value: 'Средняя Ахтуба',
    },
    {
        value: 'Сталинград',
    },
    {
        value: 'Старая Полтавка',
    },
    {
        value: 'Суровикино',
    },
    {
        value: 'Урюпинск',
    },
    {
        value: 'Фролово',
    },
    {
        value: 'Чернышковский',
    },
    {
        value: 'Бабаево',
    },
    {
        value: 'Белозерск',
    },
    {
        value: 'Великий Устюг',
    },
    {
        value: 'Верховажье',
    },
    {
        value: 'Вожега',
    },
    {
        value: 'Вологда',
    },
    {
        value: 'Вохтога',
    },
    {
        value: 'Вытегра',
    },
    {
        value: 'Грязовец',
    },
    {
        value: 'Кадников',
    },
    {
        value: 'Кадуй',
    },
    {
        value: 'Кичменгский Городок',
    },
    {
        value: 'Липин Бор',
    },
    {
        value: 'Никольск',
    },
    {
        value: 'Нюксеница',
    },
    {
        value: 'Сокол',
    },
    {
        value: 'Сямжа',
    },
    {
        value: 'Тарногский Городок',
    },
    {
        value: 'Тотьма',
    },
    {
        value: 'Устюжна',
    },
    {
        value: 'Харовск',
    },
    {
        value: 'Чагода',
    },
    {
        value: 'Череповец',
    },
    {
        value: 'Шексна',
    },
    {
        value: 'Шуйское',
    },
    {
        value: 'Анна',
    },
    {
        value: 'Бобров',
    },
    {
        value: 'Богучар',
    },
    {
        value: 'Борисоглебск',
    },
    {
        value: 'Бутурлиновка',
    },
    {
        value: 'Верхний Мамон',
    },
    {
        value: 'Верхняя Хава',
    },
    {
        value: 'Воробьевка',
    },
    {
        value: 'Воронеж',
    },
    {
        value: 'Грибановский',
    },
    {
        value: 'Давыдовка',
    },
    {
        value: 'Елань-Коленовский',
    },
    {
        value: 'Калач',
    },
    {
        value: 'Кантемировка',
    },
    {
        value: 'Лиски',
    },
    {
        value: 'Нижнедевицк',
    },
    {
        value: 'Новая Усмань',
    },
    {
        value: 'Новохоперск',
    },
    {
        value: 'Ольховатка',
    },
    {
        value: 'Острогожск',
    },
    {
        value: 'Павловск',
    },
    {
        value: 'Панино',
    },
    {
        value: 'Петропавловка',
    },
    {
        value: 'Поворино',
    },
    {
        value: 'Подгоренский',
    },
    {
        value: 'Рамонь',
    },
    {
        value: 'Репьевка',
    },
    {
        value: 'Россошь',
    },
    {
        value: 'Семилуки',
    },
    {
        value: 'Таловая',
    },
    {
        value: 'Терновка',
    },
    {
        value: 'Хохольский',
    },
    {
        value: 'Эртиль',
    },
    {
        value: 'нововоронеж',
    },
    {
        value: 'Агвали',
    },
    {
        value: 'Акуша',
    },
    {
        value: 'Ахты',
    },
    {
        value: 'Ачису',
    },
    {
        value: 'Бабаюрт',
    },
    {
        value: 'Бежта',
    },
    {
        value: 'Ботлих',
    },
    {
        value: 'Буйнакск',
    },
    {
        value: 'Вачи',
    },
    {
        value: 'Гергебиль',
    },
    {
        value: 'Гуниб',
    },
    {
        value: 'Дагестанские Огни',
    },
    {
        value: 'Дербент',
    },
    {
        value: 'Дылым',
    },
    {
        value: 'Ершовка',
    },
    {
        value: 'Избербаш',
    },
    {
        value: 'Карабудахкент',
    },
    {
        value: 'Карата',
    },
    {
        value: 'Каспийск',
    },
    {
        value: 'Касумкент',
    },
    {
        value: 'Кизилюрт',
    },
    {
        value: 'Кизляр',
    },
    {
        value: 'Кочубей',
    },
    {
        value: 'Кумух',
    },
    {
        value: 'Курах',
    },
    {
        value: 'Магарамкент',
    },
    {
        value: 'Маджалис',
    },
    {
        value: 'Махачкала',
    },
    {
        value: 'Мехельта',
    },
    {
        value: 'Новолакское',
    },
    {
        value: 'Рутул',
    },
    {
        value: 'Советское',
    },
    {
        value: 'Тарумовка',
    },
    {
        value: 'Терекли-Мектеб',
    },
    {
        value: 'Тлярата',
    },
    {
        value: 'Тпиг',
    },
    {
        value: 'Уркарах',
    },
    {
        value: 'Хасавюрт',
    },
    {
        value: 'Хив',
    },
    {
        value: 'Хунзах',
    },
    {
        value: 'Цуриб',
    },
    {
        value: 'Южно-Сухокумск',
    },
    {
        value: 'Биробиджан',
    },
    {
        value: 'Архиповка',
    },
    {
        value: 'Верхний Ландех',
    },
    {
        value: 'Вичуга',
    },
    {
        value: 'Гаврилов Посад',
    },
    {
        value: 'Долматовский',
    },
    {
        value: 'Дуляпино',
    },
    {
        value: 'Заволжск',
    },
    {
        value: 'Заречный',
    },
    {
        value: 'Иваново',
    },
    {
        value: 'Иваньковский',
    },
    {
        value: 'Ильинское-Хованское',
    },
    {
        value: 'Каминский',
    },
    {
        value: 'Кинешма',
    },
    {
        value: 'Комсомольск',
    },
    {
        value: 'Кохма',
    },
    {
        value: 'Лух',
    },
    {
        value: 'Палех',
    },
    {
        value: 'Пестяки',
    },
    {
        value: 'Приволжск',
    },
    {
        value: 'Пучеж',
    },
    {
        value: 'Родники',
    },
    {
        value: 'Савино',
    },
    {
        value: 'Сокольское',
    },
    {
        value: 'Тейково',
    },
    {
        value: 'Фурманов',
    },
    {
        value: 'Шуя',
    },
    {
        value: 'Южа',
    },
    {
        value: 'Юрьевец',
    },
    {
        value: 'Алексеевск',
    },
    {
        value: 'Алзамай',
    },
    {
        value: 'Алыгжер',
    },
    {
        value: 'Ангарск',
    },
    {
        value: 'Артемовский',
    },
    {
        value: 'Атагай',
    },
    {
        value: 'Байкал',
    },
    {
        value: 'Байкальск',
    },
    {
        value: 'Балаганск',
    },
    {
        value: 'Баяндай',
    },
    {
        value: 'Бирюсинск',
    },
    {
        value: 'Бодайбо',
    },
    {
        value: 'Большая Речка',
    },
    {
        value: 'Большой Луг',
    },
    {
        value: 'Бохан',
    },
    {
        value: 'Братск',
    },
    {
        value: 'Видим',
    },
    {
        value: 'Витимский',
    },
    {
        value: 'Вихоревка',
    },
    {
        value: 'Еланцы',
    },
    {
        value: 'Ербогачен',
    },
    {
        value: 'Железногорск-Илимский',
    },
    {
        value: 'Жигалово',
    },
    {
        value: 'Забитуй',
    },
    {
        value: 'Залари',
    },
    {
        value: 'Звездный',
    },
    {
        value: 'Зима',
    },
    {
        value: 'Иркутск',
    },
    {
        value: 'Казачинское',
    },
    {
        value: 'Качуг',
    },
    {
        value: 'Квиток',
    },
    {
        value: 'Киренск',
    },
    {
        value: 'Куйтун',
    },
    {
        value: 'Култук',
    },
    {
        value: 'Кутулик',
    },
    {
        value: 'Мама',
    },
    {
        value: 'Нижнеудинск',
    },
    {
        value: 'Оса',
    },
    {
        value: 'Саянск',
    },
    {
        value: 'Слюдянка',
    },
    {
        value: 'Тайшет',
    },
    {
        value: 'Тулун',
    },
    {
        value: 'Усолье-Сибирское',
    },
    {
        value: 'Усть-Илимск',
    },
    {
        value: 'Усть-Кут',
    },
    {
        value: 'Усть-Ордынский',
    },
    {
        value: 'Усть-Уда',
    },
    {
        value: 'Черемхово',
    },
    {
        value: 'Чунский',
    },
    {
        value: 'Шелехов',
    },
    {
        value: 'Баксан',
    },
    {
        value: 'Майский',
    },
    {
        value: 'Нальчик',
    },
    {
        value: 'Нарткала',
    },
    {
        value: 'Прохладный',
    },
    {
        value: 'Советское',
    },
    {
        value: 'Терек',
    },
    {
        value: 'Тырныауз',
    },
    {
        value: 'Чегем-Первый',
    },
    {
        value: 'Багратионовск',
    },
    {
        value: 'Балтийск',
    },
    {
        value: 'Гвардейск',
    },
    {
        value: 'Гурьевск',
    },
    {
        value: 'Гусев',
    },
    {
        value: 'Железнодорожный',
    },
    {
        value: 'Зеленоградск',
    },
    {
        value: 'Знаменск',
    },
    {
        value: 'Кёнигсберг',
    },
    {
        value: 'Калининград',
    },
    {
        value: 'Кенисберг',
    },
    {
        value: 'Краснознаменск',
    },
    {
        value: 'Мамоново',
    },
    {
        value: 'Неман',
    },
    {
        value: 'Нестеров',
    },
    {
        value: 'Озерск',
    },
    {
        value: 'Полесск',
    },
    {
        value: 'Правдинск',
    },
    {
        value: 'Светлогорск',
    },
    {
        value: 'Светлый',
    },
    {
        value: 'Славск',
    },
    {
        value: 'Советск',
    },
    {
        value: 'Черняховск',
    },
    {
        value: 'Аршань',
    },
    {
        value: 'Каспийский',
    },
    {
        value: 'Комсомольский',
    },
    {
        value: 'Малые Дербеты',
    },
    {
        value: 'Приютное',
    },
    {
        value: 'Советское',
    },
    {
        value: 'Троицкое',
    },
    {
        value: 'Утта',
    },
    {
        value: 'Цаган-Аман',
    },
    {
        value: 'Элиста',
    },
    {
        value: 'Юста',
    },
    {
        value: 'Яшалта',
    },
    {
        value: 'Яшкуль',
    },
    {
        value: 'Бабынино',
    },
    {
        value: 'Балабаново',
    },
    {
        value: 'Барятино',
    },
    {
        value: 'Белоусово',
    },
    {
        value: 'Бетлица',
    },
    {
        value: 'Боровск',
    },
    {
        value: 'Дугна',
    },
    {
        value: 'Дудоровский',
    },
    {
        value: 'Думиничи',
    },
    {
        value: 'Еленский',
    },
    {
        value: 'Жиздра',
    },
    {
        value: 'Износки',
    },
    {
        value: 'Калуга',
    },
    {
        value: 'Киров',
    },
    {
        value: 'Козельск',
    },
    {
        value: 'Кондрово',
    },
    {
        value: 'Людиново',
    },
    {
        value: 'Малоярославец',
    },
    {
        value: 'Медынь',
    },
    {
        value: 'Мещовск',
    },
    {
        value: 'Мосальск',
    },
    {
        value: 'Обнинск',
    },
    {
        value: 'Перемышль',
    },
    {
        value: 'Спас-Деменск',
    },
    {
        value: 'Сухиничи',
    },
    {
        value: 'Таруса',
    },
    {
        value: 'Ульяново',
    },
    {
        value: 'Ферзиково',
    },
    {
        value: 'Хвастовичи',
    },
    {
        value: 'Юхнов',
    },
    {
        value: 'Атласово',
    },
    {
        value: 'Аянка',
    },
    {
        value: 'Большерецк',
    },
    {
        value: 'Вилючинск',
    },
    {
        value: 'Елизово',
    },
    {
        value: 'Ильпырский',
    },
    {
        value: 'Каменское',
    },
    {
        value: 'Кировский',
    },
    {
        value: 'Ключи',
    },
    {
        value: 'Крапивная',
    },
    {
        value: 'Мильково',
    },
    {
        value: 'Никольское',
    },
    {
        value: 'Озерновский',
    },
    {
        value: 'Оссора',
    },
    {
        value: 'Палана',
    },
    {
        value: 'Парень',
    },
    {
        value: 'Пахачи',
    },
    {
        value: 'Петропавловск-Камчатский',
    },
    {
        value: 'Тигиль',
    },
    {
        value: 'Тиличики',
    },
    {
        value: 'Усть-Большерецк',
    },
    {
        value: 'Усть-Камчатск',
    },
    {
        value: 'Амбарный',
    },
    {
        value: 'Беломорск',
    },
    {
        value: 'Валаам',
    },
    {
        value: 'Вирандозеро',
    },
    {
        value: 'Гирвас',
    },
    {
        value: 'Деревянка',
    },
    {
        value: 'Идель',
    },
    {
        value: 'Ильинский',
    },
    {
        value: 'Импалахти',
    },
    {
        value: 'Калевала',
    },
    {
        value: 'Кемь',
    },
    {
        value: 'Кестеньга',
    },
    {
        value: 'Кондопога',
    },
    {
        value: 'Костомукша',
    },
    {
        value: 'Лахденпохья',
    },
    {
        value: 'Лоухи',
    },
    {
        value: 'Медвежьегорск',
    },
    {
        value: 'Муезерский',
    },
    {
        value: 'Олонец',
    },
    {
        value: 'Петрозаводск',
    },
    {
        value: 'Питкяранта',
    },
    {
        value: 'Повенец',
    },
    {
        value: 'Пряжа',
    },
    {
        value: 'Пудож',
    },
    {
        value: 'Сегежа',
    },
    {
        value: 'Сортавала',
    },
    {
        value: 'Софпорог',
    },
    {
        value: 'Суоярви',
    },
    {
        value: 'Анжеро-Судженск',
    },
    {
        value: 'Барзас',
    },
    {
        value: 'Белово',
    },
    {
        value: 'Белогорск',
    },
    {
        value: 'Березовский',
    },
    {
        value: 'Грамотеино',
    },
    {
        value: 'Гурьевск',
    },
    {
        value: 'Ижморский',
    },
    {
        value: 'Итатский',
    },
    {
        value: 'Калтан',
    },
    {
        value: 'Кедровка',
    },
    {
        value: 'Кемерово',
    },
    {
        value: 'Киселевск',
    },
    {
        value: 'Крапивинский',
    },
    {
        value: 'Ленинск-Кузнецкий',
    },
    {
        value: 'Мариинск',
    },
    {
        value: 'Междуреченск',
    },
    {
        value: 'Мыски',
    },
    {
        value: 'Новокузнецк',
    },
    {
        value: 'Осинники',
    },
    {
        value: 'Прокопьевск',
    },
    {
        value: 'Промышленная',
    },
    {
        value: 'Тайга',
    },
    {
        value: 'Таштагол',
    },
    {
        value: 'Тисуль',
    },
    {
        value: 'Топки',
    },
    {
        value: 'Тяжинский',
    },
    {
        value: 'Юрга',
    },
    {
        value: 'Яшкино',
    },
    {
        value: 'Яя',
    },
    {
        value: 'Арбаж',
    },
    {
        value: 'Аркуль',
    },
    {
        value: 'Белая Холуница',
    },
    {
        value: 'Богородское',
    },
    {
        value: 'Боровой',
    },
    {
        value: 'Верхошижемье',
    },
    {
        value: 'Вятские Поляны',
    },
    {
        value: 'Зуевка',
    },
    {
        value: 'Каринторф',
    },
    {
        value: 'Кикнур',
    },
    {
        value: 'Кильмезь',
    },
    {
        value: 'Киров',
    },
    {
        value: 'Кирово-Чепецк',
    },
    {
        value: 'Кирс',
    },
    {
        value: 'Кобра',
    },
    {
        value: 'Котельнич',
    },
    {
        value: 'Кумены',
    },
    {
        value: 'Ленинское',
    },
    {
        value: 'Луза',
    },
    {
        value: 'Малмыж',
    },
    {
        value: 'Мураши',
    },
    {
        value: 'Нагорск',
    },
    {
        value: 'Нема',
    },
    {
        value: 'Нововятск',
    },
    {
        value: 'Нолинск',
    },
    {
        value: 'Омутнинск',
    },
    {
        value: 'Опарино',
    },
    {
        value: 'Оричи',
    },
    {
        value: 'Пижанка',
    },
    {
        value: 'Подосиновец',
    },
    {
        value: 'Санчурск',
    },
    {
        value: 'Свеча',
    },
    {
        value: 'Слободской',
    },
    {
        value: 'Советск',
    },
    {
        value: 'Суна',
    },
    {
        value: 'Тужа',
    },
    {
        value: 'Уни',
    },
    {
        value: 'Уржум',
    },
    {
        value: 'Фаленки',
    },
    {
        value: 'Халтурин',
    },
    {
        value: 'Юрья',
    },
    {
        value: 'Яранск',
    },
    {
        value: 'Абезь',
    },
    {
        value: 'Айкино',
    },
    {
        value: 'Верхняя Инта',
    },
    {
        value: 'Визинга',
    },
    {
        value: 'Водный',
    },
    {
        value: 'Вожаель',
    },
    {
        value: 'Воркута',
    },
    {
        value: 'Вуктыл',
    },
    {
        value: 'Гешарт',
    },
    {
        value: 'Елецкий',
    },
    {
        value: 'Емва',
    },
    {
        value: 'Заполярный',
    },
    {
        value: 'Ижма',
    },
    {
        value: 'Инта',
    },
    {
        value: 'Ираель',
    },
    {
        value: 'Каджером',
    },
    {
        value: 'Кажым',
    },
    {
        value: 'Кожым',
    },
    {
        value: 'Койгородок',
    },
    {
        value: 'Корткерос',
    },
    {
        value: 'Кослан',
    },
    {
        value: 'Объячево',
    },
    {
        value: 'Печора',
    },
    {
        value: 'Сосногорск',
    },
    {
        value: 'Сыктывкар',
    },
    {
        value: 'Троицко-Печерск',
    },
    {
        value: 'Усинск',
    },
    {
        value: 'Усогорск',
    },
    {
        value: 'Усть-Кулом',
    },
    {
        value: 'Усть-Цильма',
    },
    {
        value: 'Ухта',
    },
    {
        value: 'Антропово',
    },
    {
        value: 'Боговарово',
    },
    {
        value: 'Буй',
    },
    {
        value: 'Волгореченск',
    },
    {
        value: 'Галич',
    },
    {
        value: 'Горчуха',
    },
    {
        value: 'Зебляки',
    },
    {
        value: 'Кадый',
    },
    {
        value: 'Кологрив',
    },
    {
        value: 'Кострома',
    },
    {
        value: 'Красное-на-Волге',
    },
    {
        value: 'Макарьев',
    },
    {
        value: 'Мантурово',
    },
    {
        value: 'Нерехта',
    },
    {
        value: 'Нея',
    },
    {
        value: 'Островское',
    },
    {
        value: 'Павино',
    },
    {
        value: 'Парфентьево',
    },
    {
        value: 'Поназырево',
    },
    {
        value: 'Солигалич',
    },
    {
        value: 'Судиславль',
    },
    {
        value: 'Сусанино',
    },
    {
        value: 'Чухлома',
    },
    {
        value: 'Шарья',
    },
    {
        value: 'Шемятино',
    },
    {
        value: 'Абинск',
    },
    {
        value: 'Абрау-Дюрсо',
    },
    {
        value: 'Анапа',
    },
    {
        value: 'Апшеронск',
    },
    {
        value: 'Армавир',
    },
    {
        value: 'Архипо-Осиповка',
    },
    {
        value: 'Афипский',
    },
    {
        value: 'Ахтырский',
    },
    {
        value: 'Ачуево',
    },
    {
        value: 'Белореченск',
    },
    {
        value: 'Верхнебаканский',
    },
    {
        value: 'Выселки',
    },
    {
        value: 'Геленджик',
    },
    {
        value: 'Гиагинская',
    },
    {
        value: 'Горячий Ключ',
    },
    {
        value: 'Джубга',
    },
    {
        value: 'Динская',
    },
    {
        value: 'Ейск',
    },
    {
        value: 'Ильский',
    },
    {
        value: 'Кабардинка',
    },
    {
        value: 'Калинино',
    },
    {
        value: 'Калининская',
    },
    {
        value: 'Каменномостский',
    },
    {
        value: 'Каневская',
    },
    {
        value: 'Кореновск',
    },
    {
        value: 'Красноармейская',
    },
    {
        value: 'Краснодар',
    },
    {
        value: 'Кропоткин',
    },
    {
        value: 'Крыловская',
    },
    {
        value: 'Крымск',
    },
    {
        value: 'Курганинск',
    },
    {
        value: 'Кущевская',
    },
    {
        value: 'Лабинск',
    },
    {
        value: 'Лениградская',
    },
    {
        value: 'Майкоп',
    },
    {
        value: 'Мостовской',
    },
    {
        value: 'Новороссийск',
    },
    {
        value: 'Отрадная',
    },
    {
        value: 'Павловская',
    },
    {
        value: 'Приморско-Ахтарск',
    },
    {
        value: 'Северская',
    },
    {
        value: 'Славянск-на-Кубани',
    },
    {
        value: 'Сочи',
    },
    {
        value: 'Староминская',
    },
    {
        value: 'Старощербиновская',
    },
    {
        value: 'Тбилисская',
    },
    {
        value: 'Темрюк',
    },
    {
        value: 'Тимашевск',
    },
    {
        value: 'Тихорецк',
    },
    {
        value: 'Туапсе',
    },
    {
        value: 'Тульский',
    },
    {
        value: 'Усть-Лабинск',
    },
    {
        value: 'Шовгеновский',
    },
    {
        value: ' Железногорск',
    },
    {
        value: 'Абаза',
    },
    {
        value: 'Абакан',
    },
    {
        value: 'Абан',
    },
    {
        value: 'Агинское',
    },
    {
        value: 'Артемовск',
    },
    {
        value: 'Аскиз',
    },
    {
        value: 'Ачинск',
    },
    {
        value: 'Байкит',
    },
    {
        value: 'Балахта',
    },
    {
        value: 'Балыкса',
    },
    {
        value: 'Белый Яр',
    },
    {
        value: 'Бельтырский',
    },
    {
        value: 'Бея',
    },
    {
        value: 'Бискамжа',
    },
    {
        value: 'Боготол',
    },
    {
        value: 'Боград',
    },
    {
        value: 'Богучаны',
    },
    {
        value: 'Большая Мурта',
    },
    {
        value: 'Большой Улуй',
    },
    {
        value: 'Бородино',
    },
    {
        value: 'Ванавара',
    },
    {
        value: 'Верхнеимбатск',
    },
    {
        value: 'Горячегорск',
    },
    {
        value: 'Дзержинское',
    },
    {
        value: 'Дивногорск',
    },
    {
        value: 'Диксон',
    },
    {
        value: 'Дудинка',
    },
    {
        value: 'Емельяново',
    },
    {
        value: 'Енисейск',
    },
    {
        value: 'Ермаковское',
    },
    {
        value: 'Заозерный',
    },
    {
        value: 'Зеленогорск',
    },
    {
        value: 'Игарка',
    },
    {
        value: 'Идринское',
    },
    {
        value: 'Иланский',
    },
    {
        value: 'Ирбейское',
    },
    {
        value: 'Казачинское',
    },
    {
        value: 'Канск',
    },
    {
        value: 'Каратузское',
    },
    {
        value: 'Караул',
    },
    {
        value: 'Кежма',
    },
    {
        value: 'Кодинск',
    },
    {
        value: 'Козулька',
    },
    {
        value: 'Копьево',
    },
    {
        value: 'Краснотуранск',
    },
    {
        value: 'Красноярск',
    },
    {
        value: 'Курагино',
    },
    {
        value: 'Лесосибирск',
    },
    {
        value: 'Минусинск',
    },
    {
        value: 'Мотыгино',
    },
    {
        value: 'Назарово',
    },
    {
        value: 'Нижний Ингаш',
    },
    {
        value: 'Новоселово',
    },
    {
        value: 'Норильск',
    },
    {
        value: 'Партизанское',
    },
    {
        value: 'Пировское',
    },
    {
        value: 'Саяногорск',
    },
    {
        value: 'Северо-Енисейский',
    },
    {
        value: 'Сосновоборск',
    },
    {
        value: 'Тасеево',
    },
    {
        value: 'Таштып',
    },
    {
        value: 'Тура',
    },
    {
        value: 'Туруханск',
    },
    {
        value: 'Тюхтет',
    },
    {
        value: 'Ужур',
    },
    {
        value: 'Усть-Авам',
    },
    {
        value: 'Уяр',
    },
    {
        value: 'Хатанга',
    },
    {
        value: 'Черемушки',
    },
    {
        value: 'Черногорск',
    },
    {
        value: 'Шалинское',
    },
    {
        value: 'Шарыпово',
    },
    {
        value: 'Шира',
    },
    {
        value: 'Шушенское',
    },
    {
        value: 'Варгаши',
    },
    {
        value: 'Глядянское',
    },
    {
        value: 'Далматово',
    },
    {
        value: 'Каргаполье',
    },
    {
        value: 'Катайск',
    },
    {
        value: 'Кетово',
    },
    {
        value: 'Курган',
    },
    {
        value: 'Куртамыш',
    },
    {
        value: 'Лебяжье',
    },
    {
        value: 'Макушино',
    },
    {
        value: 'Мишкино',
    },
    {
        value: 'Мокроусово',
    },
    {
        value: 'Петухово',
    },
    {
        value: 'Половинное',
    },
    {
        value: 'Сафакулево',
    },
    {
        value: 'Целинное',
    },
    {
        value: 'Шадринск',
    },
    {
        value: 'Шатрово',
    },
    {
        value: 'Шумиха',
    },
    {
        value: 'Щучье',
    },
    {
        value: 'Юргамыш',
    },
    {
        value: 'Альменево',
    },
    {
        value: 'Белая',
    },
    {
        value: 'Большое Солдатское',
    },
    {
        value: 'Глушково',
    },
    {
        value: 'Горшечное',
    },
    {
        value: 'Дмитриев-Льговский',
    },
    {
        value: 'Железногорск',
    },
    {
        value: 'Золотухино',
    },
    {
        value: 'Касторное',
    },
    {
        value: 'Конышевка',
    },
    {
        value: 'Коренево',
    },
    {
        value: 'Курск',
    },
    {
        value: 'Курчатов',
    },
    {
        value: 'Кшенский',
    },
    {
        value: 'Льгов',
    },
    {
        value: 'Мантурово',
    },
    {
        value: 'Медвенка',
    },
    {
        value: 'Обоянь',
    },
    {
        value: 'Поныри',
    },
    {
        value: 'Пристень',
    },
    {
        value: 'Прямицыно',
    },
    {
        value: 'Рыльск',
    },
    {
        value: 'Суджа',
    },
    {
        value: 'Тим',
    },
    {
        value: 'Фатеж',
    },
    {
        value: 'Хомутовка',
    },
    {
        value: 'Черемисиново',
    },
    {
        value: 'Щигры',
    },
    {
        value: 'Грязи',
    },
    {
        value: 'Данхов',
    },
    {
        value: 'Доброе',
    },
    {
        value: 'Долгоруково',
    },
    {
        value: 'Елец',
    },
    {
        value: 'Задонск',
    },
    {
        value: 'Измалково',
    },
    {
        value: 'Казинка',
    },
    {
        value: 'Лебедянь',
    },
    {
        value: 'Лев Толстой',
    },
    {
        value: 'Липецк',
    },
    {
        value: 'Тербуны',
    },
    {
        value: 'Усмань',
    },
    {
        value: 'Хлевное',
    },
    {
        value: 'Чаплыгин',
    },
    {
        value: 'Анадырь',
    },
    {
        value: 'Атка',
    },
    {
        value: 'Балыгычан',
    },
    {
        value: 'Беринговский',
    },
    {
        value: 'Билибино',
    },
    {
        value: 'Большевик',
    },
    {
        value: 'Ванкарем',
    },
    {
        value: 'Иульитин',
    },
    {
        value: 'Кадыкчан',
    },
    {
        value: 'Лаврентия',
    },
    {
        value: 'Магадан',
    },
    {
        value: 'Мыс Шмидта',
    },
    {
        value: 'Ола',
    },
    {
        value: 'Омонск',
    },
    {
        value: 'Омсукчан',
    },
    {
        value: 'Палатка',
    },
    {
        value: 'Певек',
    },
    {
        value: 'Провидения',
    },
    {
        value: 'Сеймчан',
    },
    {
        value: 'Синегорье',
    },
    {
        value: 'Сусуман',
    },
    {
        value: 'Усть-Белая',
    },
    {
        value: 'Усть-Омчуг',
    },
    {
        value: 'Эвенск',
    },
    {
        value: 'Эгвекинот',
    },
    {
        value: 'Ягодное',
    },
    {
        value: 'Волжск',
    },
    {
        value: 'Дубовский',
    },
    {
        value: 'Звенигово',
    },
    {
        value: 'Йошкар-Ола',
    },
    {
        value: 'Килемары',
    },
    {
        value: 'Козьмодемьянск',
    },
    {
        value: 'Куженер',
    },
    {
        value: 'Мари-Турек',
    },
    {
        value: 'Медведево',
    },
    {
        value: 'Морки',
    },
    {
        value: 'Новый Торьял',
    },
    {
        value: 'Оршанка',
    },
    {
        value: 'Параньга',
    },
    {
        value: 'Сернур',
    },
    {
        value: 'Советский',
    },
    {
        value: 'Юрино',
    },
    {
        value: 'Ардатов',
    },
    {
        value: 'Атюрьево',
    },
    {
        value: 'Атяшево',
    },
    {
        value: 'Большие Березники',
    },
    {
        value: 'Большое Игнатово',
    },
    {
        value: 'Выша',
    },
    {
        value: 'Ельники',
    },
    {
        value: 'Зубова Поляна',
    },
    {
        value: 'Инсар',
    },
    {
        value: 'Кадошкино',
    },
    {
        value: 'Кемля',
    },
    {
        value: 'Ковылкино',
    },
    {
        value: 'Комсомольский',
    },
    {
        value: 'Кочкурово',
    },
    {
        value: 'Краснослободск',
    },
    {
        value: 'Лямбирь',
    },
    {
        value: 'Ромоданово',
    },
    {
        value: 'Рузаевка',
    },
    {
        value: 'Саранск',
    },
    {
        value: 'Старое Шайгово',
    },
    {
        value: 'Темников',
    },
    {
        value: 'Теньгушево',
    },
    {
        value: 'Торбеево',
    },
    {
        value: 'Чамзинка',
    },
    {
        value: 'Апатиты',
    },
    {
        value: 'Африканда',
    },
    {
        value: 'Верхнетуломский',
    },
    {
        value: 'Заозерск',
    },
    {
        value: 'Заполярный',
    },
    {
        value: 'Зареченск',
    },
    {
        value: 'Зашеек',
    },
    {
        value: 'Зеленоборский',
    },
    {
        value: 'Кандалакша',
    },
    {
        value: 'Кильдинстрой',
    },
    {
        value: 'Кировск',
    },
    {
        value: 'Ковдор',
    },
    {
        value: 'Кола',
    },
    {
        value: 'Конда',
    },
    {
        value: 'Мончегорск',
    },
    {
        value: 'Мурманск',
    },
    {
        value: 'Мурмаши',
    },
    {
        value: 'Никель',
    },
    {
        value: 'Оленегорск',
    },
    {
        value: 'Полярные Зори',
    },
    {
        value: 'Полярный',
    },
    {
        value: 'Североморск',
    },
    {
        value: 'Снежногорск',
    },
    {
        value: 'Умба',
    },
    {
        value: 'Ардатов',
    },
    {
        value: 'Арзамас',
    },
    {
        value: 'Арья',
    },
    {
        value: 'Балахна',
    },
    {
        value: 'Богородск',
    },
    {
        value: 'Большереченск',
    },
    {
        value: 'Большое Болдино',
    },
    {
        value: 'Большое Козино',
    },
    {
        value: 'Большое Мурашкино',
    },
    {
        value: 'Большое Пикино',
    },
    {
        value: 'Бор',
    },
    {
        value: 'Бутурлино',
    },
    {
        value: 'Вад',
    },
    {
        value: 'Варнавино',
    },
    {
        value: 'Васильсурск',
    },
    {
        value: 'Вахтан',
    },
    {
        value: 'Вача',
    },
    {
        value: 'Велетьма',
    },
    {
        value: 'Ветлуга',
    },
    {
        value: 'Виля',
    },
    {
        value: 'Вознесенское',
    },
    {
        value: 'Володарск',
    },
    {
        value: 'Воротынец',
    },
    {
        value: 'Ворсма',
    },
    {
        value: 'Воскресенское',
    },
    {
        value: 'Выездное',
    },
    {
        value: 'Выкса',
    },
    {
        value: 'Гагино',
    },
    {
        value: 'Гидроторф',
    },
    {
        value: 'Горбатов',
    },
    {
        value: 'Горбатовка',
    },
    {
        value: 'Городец',
    },
    {
        value: 'Горький',
    },
    {
        value: 'Дальнее Константиново',
    },
    {
        value: 'Дзержинск',
    },
    {
        value: 'Дивеево',
    },
    {
        value: 'Досчатое',
    },
    {
        value: 'Заволжье',
    },
    {
        value: 'Катунки',
    },
    {
        value: 'Керженец',
    },
    {
        value: 'Княгинино',
    },
    {
        value: 'Ковернино',
    },
    {
        value: 'Красные Баки',
    },
    {
        value: 'Кстово',
    },
    {
        value: 'Кулебаки',
    },
    {
        value: 'Лукоянов',
    },
    {
        value: 'Лысково',
    },
    {
        value: 'Навашино',
    },
    {
        value: 'Нижний Новгород',
    },
    {
        value: 'Павлово',
    },
    {
        value: 'Первомайск',
    },
    {
        value: 'Перевоз',
    },
    {
        value: 'Пильна',
    },
    {
        value: 'Починки',
    },
    {
        value: 'Саров',
    },
    {
        value: 'Сергач',
    },
    {
        value: 'Сеченово',
    },
    {
        value: 'Сосновское',
    },
    {
        value: 'Спасское',
    },
    {
        value: 'Тонкино',
    },
    {
        value: 'Тоншаево',
    },
    {
        value: 'Уразовка',
    },
    {
        value: 'Урень',
    },
    {
        value: 'Чкаловск',
    },
    {
        value: 'Шаранга',
    },
    {
        value: 'Шатки',
    },
    {
        value: 'Шахунья',
    },
    {
        value: 'Анциферово',
    },
    {
        value: 'Батецкий',
    },
    {
        value: 'Большая Вишера',
    },
    {
        value: 'Боровичи',
    },
    {
        value: 'Валдай',
    },
    {
        value: 'Волот',
    },
    {
        value: 'Деманск',
    },
    {
        value: 'Зарубино',
    },
    {
        value: 'Кресцы',
    },
    {
        value: 'Любытино',
    },
    {
        value: 'Малая Вишера',
    },
    {
        value: 'Марево',
    },
    {
        value: 'Мошенское',
    },
    {
        value: 'Новгород',
    },
    {
        value: 'Окуловка',
    },
    {
        value: 'Парфино',
    },
    {
        value: 'Пестово',
    },
    {
        value: 'Поддорье',
    },
    {
        value: 'Сольцы',
    },
    {
        value: 'Старая Русса',
    },
    {
        value: 'Хвойное',
    },
    {
        value: 'Холм',
    },
    {
        value: 'Чудово',
    },
    {
        value: 'Шимск',
    },
    {
        value: 'Баган',
    },
    {
        value: 'Барабинск',
    },
    {
        value: 'Бердск',
    },
    {
        value: 'Биаза',
    },
    {
        value: 'Болотное',
    },
    {
        value: 'Венгерово',
    },
    {
        value: 'Довольное',
    },
    {
        value: 'Завьялово',
    },
    {
        value: 'Искитим',
    },
    {
        value: 'Карасук',
    },
    {
        value: 'Каргат',
    },
    {
        value: 'Колывань',
    },
    {
        value: 'Краснозерское',
    },
    {
        value: 'Крутиха',
    },
    {
        value: 'Куйбышев',
    },
    {
        value: 'Купино',
    },
    {
        value: 'Кыштовка',
    },
    {
        value: 'Маслянино',
    },
    {
        value: 'Михайловский',
    },
    {
        value: 'Мошково',
    },
    {
        value: 'Новосибирск',
    },
    {
        value: 'Ордынское',
    },
    {
        value: 'Северное',
    },
    {
        value: 'Сузун',
    },
    {
        value: 'Татарск',
    },
    {
        value: 'Тогучин',
    },
    {
        value: 'Убинское',
    },
    {
        value: 'Усть-Тарка',
    },
    {
        value: 'Чаны',
    },
    {
        value: 'Черепаново',
    },
    {
        value: 'Чистоозерное',
    },
    {
        value: 'Чулым',
    },
    {
        value: 'Береговой',
    },
    {
        value: 'Большеречье',
    },
    {
        value: 'Большие Уки',
    },
    {
        value: 'Горьковское',
    },
    {
        value: 'Знаменское',
    },
    {
        value: 'Исилькуль',
    },
    {
        value: 'Калачинск',
    },
    {
        value: 'Колосовка',
    },
    {
        value: 'Кормиловка',
    },
    {
        value: 'Крутинка',
    },
    {
        value: 'Любинский',
    },
    {
        value: 'Марьяновка',
    },
    {
        value: 'Муромцево',
    },
    {
        value: 'Называевск',
    },
    {
        value: 'Нижняя Омка',
    },
    {
        value: 'Нововаршавка',
    },
    {
        value: 'Одесское',
    },
    {
        value: 'Оконешниково',
    },
    {
        value: 'Омск',
    },
    {
        value: 'Павлоградка',
    },
    {
        value: 'Полтавка',
    },
    {
        value: 'Русская Поляна',
    },
    {
        value: 'Саргатское',
    },
    {
        value: 'Седельниково',
    },
    {
        value: 'Таврическое',
    },
    {
        value: 'Тара',
    },
    {
        value: 'Тевриз',
    },
    {
        value: 'Тюкалинск',
    },
    {
        value: 'Усть-Ишим',
    },
    {
        value: 'Черлак',
    },
    {
        value: 'Шербакуль',
    },
    {
        value: 'Абдулино',
    },
    {
        value: 'Адамовка',
    },
    {
        value: 'Айдырлинский',
    },
    {
        value: 'Акбулак',
    },
    {
        value: 'Аккермановка',
    },
    {
        value: 'Асекеево',
    },
    {
        value: 'Беляевка',
    },
    {
        value: 'Бугуруслан',
    },
    {
        value: 'Бузулук',
    },
    {
        value: 'Гай',
    },
    {
        value: 'Грачевка',
    },
    {
        value: 'Домбаровский',
    },
    {
        value: 'Дубенский',
    },
    {
        value: 'Илек',
    },
    {
        value: 'Ириклинский',
    },
    {
        value: 'Кувандык',
    },
    {
        value: 'Курманаевка',
    },
    {
        value: 'Матвеевка',
    },
    {
        value: 'Медногорск',
    },
    {
        value: 'Новоорск',
    },
    {
        value: 'Новосергиевка',
    },
    {
        value: 'Новотроицк',
    },
    {
        value: 'Октябрьское',
    },
    {
        value: 'Оренбург',
    },
    {
        value: 'Орск',
    },
    {
        value: 'Первомайский',
    },
    {
        value: 'Переволоцкий',
    },
    {
        value: 'Пономаревка',
    },
    {
        value: 'Саракташ',
    },
    {
        value: 'Светлый',
    },
    {
        value: 'Северное',
    },
    {
        value: 'Соль-Илецк',
    },
    {
        value: 'Сорочинск',
    },
    {
        value: 'Ташла',
    },
    {
        value: 'Тоцкое',
    },
    {
        value: 'Тюльган',
    },
    {
        value: 'Шарлык',
    },
    {
        value: 'Энергетик',
    },
    {
        value: 'Ясный',
    },
    {
        value: 'Болхов',
    },
    {
        value: 'Верховье',
    },
    {
        value: 'Глазуновка',
    },
    {
        value: 'Дмитровск-Орловский',
    },
    {
        value: 'Долгое',
    },
    {
        value: 'Залегощь',
    },
    {
        value: 'Змиевка',
    },
    {
        value: 'Знаменское',
    },
    {
        value: 'Колпны',
    },
    {
        value: 'Красная Заря',
    },
    {
        value: 'Кромы',
    },
    {
        value: 'Ливны',
    },
    {
        value: 'Малоархангельск',
    },
    {
        value: 'Мценск',
    },
    {
        value: 'Нарышкино',
    },
    {
        value: 'Новосиль',
    },
    {
        value: 'Орел',
    },
    {
        value: 'Покровское',
    },
    {
        value: 'Сосково',
    },
    {
        value: 'Тросна',
    },
    {
        value: 'Хомутово',
    },
    {
        value: 'Хотынец',
    },
    {
        value: 'Шаблыкино',
    },
    {
        value: 'Башмаково',
    },
    {
        value: 'Беднодемьяновск',
    },
    {
        value: 'Беково',
    },
    {
        value: 'Белинский',
    },
    {
        value: 'Бессоновка',
    },
    {
        value: 'Вадинск',
    },
    {
        value: 'Верхозим',
    },
    {
        value: 'Городище',
    },
    {
        value: 'Евлашево',
    },
    {
        value: 'Земетчино',
    },
    {
        value: 'Золотаревка',
    },
    {
        value: 'Исса',
    },
    {
        value: 'Каменка',
    },
    {
        value: 'Колышлей',
    },
    {
        value: 'Кондоль',
    },
    {
        value: 'Кузнецк',
    },
    {
        value: 'Лопатино',
    },
    {
        value: 'Малая Сердоба',
    },
    {
        value: 'Мокшан',
    },
    {
        value: 'Наровчат',
    },
    {
        value: 'Неверкино',
    },
    {
        value: 'Нижний Ломов',
    },
    {
        value: 'Никольск',
    },
    {
        value: 'Пачелма',
    },
    {
        value: 'Пенза',
    },
    {
        value: 'Русский Камешкир',
    },
    {
        value: 'Сердобск',
    },
    {
        value: 'Сосновоборск',
    },
    {
        value: 'Сура',
    },
    {
        value: 'Тамала',
    },
    {
        value: 'Шемышейка',
    },
    {
        value: 'Барда',
    },
    {
        value: 'Березники',
    },
    {
        value: 'Большая Соснова',
    },
    {
        value: 'Верещагино',
    },
    {
        value: 'Гайны',
    },
    {
        value: 'Горнозаводск',
    },
    {
        value: 'Гремячинск',
    },
    {
        value: 'Губаха',
    },
    {
        value: 'Добрянка',
    },
    {
        value: 'Елово',
    },
    {
        value: 'Зюкайка',
    },
    {
        value: 'Ильинский',
    },
    {
        value: 'Карагай',
    },
    {
        value: 'Керчевский',
    },
    {
        value: 'Кизел',
    },
    {
        value: 'Коса',
    },
    {
        value: 'Кочево',
    },
    {
        value: 'Красновишерск',
    },
    {
        value: 'Краснокамск',
    },
    {
        value: 'Кудымкар',
    },
    {
        value: 'Куеда',
    },
    {
        value: 'Кунгур',
    },
    {
        value: 'Лысьва',
    },
    {
        value: 'Ныроб',
    },
    {
        value: 'Нытва',
    },
    {
        value: 'Октябрьский',
    },
    {
        value: 'Орда',
    },
    {
        value: 'Оса',
    },
    {
        value: 'Оханск',
    },
    {
        value: 'Очер',
    },
    {
        value: 'Пермь',
    },
    {
        value: 'Соликамск',
    },
    {
        value: 'Суксун',
    },
    {
        value: 'Уинское',
    },
    {
        value: 'Усолье',
    },
    {
        value: 'Усть-Кишерть',
    },
    {
        value: 'Чайковский',
    },
    {
        value: 'Частые',
    },
    {
        value: 'Чердынь',
    },
    {
        value: 'Чернореченский',
    },
    {
        value: 'Чернушка',
    },
    {
        value: 'Чусовой',
    },
    {
        value: 'Юрла',
    },
    {
        value: 'Юсьва',
    },
    {
        value: 'Анучино',
    },
    {
        value: 'Арсеньев',
    },
    {
        value: 'Артем',
    },
    {
        value: 'Артемовский',
    },
    {
        value: 'Большой Камень',
    },
    {
        value: 'Валентин',
    },
    {
        value: 'Владивосток',
    },
    {
        value: 'Высокогорск',
    },
    {
        value: 'Горные Ключи',
    },
    {
        value: 'Горный',
    },
    {
        value: 'Дальнегорск',
    },
    {
        value: 'Дальнереченск',
    },
    {
        value: 'Зарубино',
    },
    {
        value: 'Кавалерово',
    },
    {
        value: 'Каменка',
    },
    {
        value: 'Камень-Рыболов',
    },
    {
        value: 'Кировский',
    },
    {
        value: 'Лазо',
    },
    {
        value: 'Лесозаводск',
    },
    {
        value: 'Лучегорск',
    },
    {
        value: 'Михайловка',
    },
    {
        value: 'Находка',
    },
    {
        value: 'Новопокровка',
    },
    {
        value: 'Ольга',
    },
    {
        value: 'Партизанск',
    },
    {
        value: 'Пограничный',
    },
    {
        value: 'Покровка',
    },
    {
        value: 'Русский',
    },
    {
        value: 'Самарга',
    },
    {
        value: 'Славянка',
    },
    {
        value: 'Спасск-Дальний',
    },
    {
        value: 'Терней',
    },
    {
        value: 'Уссурийск',
    },
    {
        value: 'Фокино',
    },
    {
        value: 'Хасан',
    },
    {
        value: 'Хороль',
    },
    {
        value: 'Черниговка',
    },
    {
        value: 'Чугуевка',
    },
    {
        value: 'Яковлевка',
    },
    {
        value: 'Бежаницы',
    },
    {
        value: 'Великие Луки',
    },
    {
        value: 'Гдов',
    },
    {
        value: 'Дедовичи',
    },
    {
        value: 'Дно',
    },
    {
        value: 'Заплюсье',
    },
    {
        value: 'Идрица',
    },
    {
        value: 'Красногородское',
    },
    {
        value: 'Кунья',
    },
    {
        value: 'Локня',
    },
    {
        value: 'Невель',
    },
    {
        value: 'Новоржев',
    },
    {
        value: 'Новосокольники',
    },
    {
        value: 'Опочка',
    },
    {
        value: 'Остров',
    },
    {
        value: 'Палкино',
    },
    {
        value: 'Печоры',
    },
    {
        value: 'Плюсса',
    },
    {
        value: 'Порхов',
    },
    {
        value: 'Псков',
    },
    {
        value: 'Пустошка',
    },
    {
        value: 'Пушкинские Горы',
    },
    {
        value: 'Пыталово',
    },
    {
        value: 'Себеж',
    },
    {
        value: 'Струги-Красные',
    },
    {
        value: 'Усвяты',
    },
    {
        value: 'Азов',
    },
    {
        value: 'Аксай',
    },
    {
        value: 'Алмазный',
    },
    {
        value: 'Аютинск',
    },
    {
        value: 'Багаевский',
    },
    {
        value: 'Батайск',
    },
    {
        value: 'Белая Калитва',
    },
    {
        value: 'Боковская',
    },
    {
        value: 'Большая Мартыновка',
    },
    {
        value: 'Вешенская',
    },
    {
        value: 'Волгодонск',
    },
    {
        value: 'Восход',
    },
    {
        value: 'Гигант',
    },
    {
        value: 'Горняцкий',
    },
    {
        value: 'Гуково',
    },
    {
        value: 'Донецк',
    },
    {
        value: 'Донской',
    },
    {
        value: 'Дубовское',
    },
    {
        value: 'Егорлыкская',
    },
    {
        value: 'Жирнов',
    },
    {
        value: 'Заветное',
    },
    {
        value: 'Заводской',
    },
    {
        value: 'Зверево',
    },
    {
        value: 'Зерноград',
    },
    {
        value: 'Зимовники',
    },
    {
        value: 'Кагальницкая',
    },
    {
        value: 'Казанская',
    },
    {
        value: 'Каменоломни',
    },
    {
        value: 'Каменск-Шахтинский',
    },
    {
        value: 'Кашары',
    },
    {
        value: 'Коксовый',
    },
    {
        value: 'Константиновск',
    },
    {
        value: 'Красный Сулин',
    },
    {
        value: 'Куйбышево',
    },
    {
        value: 'Матвеев Курган',
    },
    {
        value: 'Мигулинская',
    },
    {
        value: 'Миллерово',
    },
    {
        value: 'Милютинская',
    },
    {
        value: 'Морозовск',
    },
    {
        value: 'Новочеркасск',
    },
    {
        value: 'Новошахтинск',
    },
    {
        value: 'Обливская',
    },
    {
        value: 'Орловский',
    },
    {
        value: 'Песчанокопское',
    },
    {
        value: 'Покровское',
    },
    {
        value: 'Пролетарск',
    },
    {
        value: 'Ремонтное',
    },
    {
        value: 'Родионово-Несветайская',
    },
    {
        value: 'Ростов-на-Дону',
    },
    {
        value: 'Сальск',
    },
    {
        value: 'Семикаракорск',
    },
    {
        value: 'Таганрог',
    },
    {
        value: 'Тарасовский',
    },
    {
        value: 'Тацинский',
    },
    {
        value: 'Усть-Донецкий',
    },
    {
        value: 'Целина',
    },
    {
        value: 'Цимлянск',
    },
    {
        value: 'Чалтырь',
    },
    {
        value: 'Чертково',
    },
    {
        value: 'Шахты',
    },
    {
        value: 'Шолоховский',
    },
    {
        value: 'Александро-Невский',
    },
    {
        value: 'Горняк',
    },
    {
        value: 'Гусь Железный',
    },
    {
        value: 'Елатьма',
    },
    {
        value: 'Ермишь',
    },
    {
        value: 'Заречный',
    },
    {
        value: 'Захарово',
    },
    {
        value: 'Кадом',
    },
    {
        value: 'Касимов',
    },
    {
        value: 'Кораблино',
    },
    {
        value: 'Милославское',
    },
    {
        value: 'Михайлов',
    },
    {
        value: 'Пителино',
    },
    {
        value: 'Пронск',
    },
    {
        value: 'Путятино',
    },
    {
        value: 'Рыбное',
    },
    {
        value: 'Ряжск',
    },
    {
        value: 'Рязань',
    },
    {
        value: 'Сапожок',
    },
    {
        value: 'Сараи',
    },
    {
        value: 'Сасово',
    },
    {
        value: 'Скопин',
    },
    {
        value: 'Спас-Клепики',
    },
    {
        value: 'Спасск-Рязанский',
    },
    {
        value: 'Старожилово',
    },
    {
        value: 'Ухолово',
    },
    {
        value: 'Чучково',
    },
    {
        value: 'Шацк',
    },
    {
        value: 'Шилово',
    },
    {
        value: 'Алексеевка',
    },
    {
        value: 'Безенчук',
    },
    {
        value: 'Богатое',
    },
    {
        value: 'Богатырь',
    },
    {
        value: 'Большая Глущица',
    },
    {
        value: 'Большая Черниговка',
    },
    {
        value: 'Борское',
    },
    {
        value: 'Волжский',
    },
    {
        value: 'Жигулевск',
    },
    {
        value: 'Зольное',
    },
    {
        value: 'Исаклы',
    },
    {
        value: 'Камышла',
    },
    {
        value: 'Кинель',
    },
    {
        value: 'Кинель-Черкасы',
    },
    {
        value: 'Клявлино',
    },
    {
        value: 'Кошки',
    },
    {
        value: 'Красноармейское',
    },
    {
        value: 'Красный Яр',
    },
    {
        value: 'Куйбышев',
    },
    {
        value: 'Нефтегорск',
    },
    {
        value: 'Новокуйбышевск',
    },
    {
        value: 'Октябрьск',
    },
    {
        value: 'Отрадный',
    },
    {
        value: 'Пестравка',
    },
    {
        value: 'Похвистнево',
    },
    {
        value: 'Приволжье',
    },
    {
        value: 'Самара',
    },
    {
        value: 'Сургут (Самарская обл.)',
    },
    {
        value: 'Сызрань',
    },
    {
        value: 'Тольятти',
    },
    {
        value: 'Хворостянка',
    },
    {
        value: 'Чапаевск',
    },
    {
        value: 'Челно-Вершины',
    },
    {
        value: 'Шентала',
    },
    {
        value: 'Шигоны',
    },
    {
        value: 'Александров Гай',
    },
    {
        value: 'Аркадак',
    },
    {
        value: 'Аткарск',
    },
    {
        value: 'Базарный Карабулак',
    },
    {
        value: 'Балаково',
    },
    {
        value: 'Балашов',
    },
    {
        value: 'Балтай',
    },
    {
        value: 'Возрождение',
    },
    {
        value: 'Вольск',
    },
    {
        value: 'Воскресенское',
    },
    {
        value: 'Горный',
    },
    {
        value: 'Дергачи',
    },
    {
        value: 'Духовницкое',
    },
    {
        value: 'Екатериновка',
    },
    {
        value: 'Ершов',
    },
    {
        value: 'Заречный',
    },
    {
        value: 'Ивантеевка',
    },
    {
        value: 'Калининск',
    },
    {
        value: 'Каменский',
    },
    {
        value: 'Красноармейск',
    },
    {
        value: 'Красный Кут',
    },
    {
        value: 'Лысые Горы',
    },
    {
        value: 'Маркс',
    },
    {
        value: 'Мокроус',
    },
    {
        value: 'Новоузенск',
    },
    {
        value: 'Новые Бурасы',
    },
    {
        value: 'Озинки',
    },
    {
        value: 'Перелюб',
    },
    {
        value: 'Петровск',
    },
    {
        value: 'Питерка',
    },
    {
        value: 'Пугачев',
    },
    {
        value: 'Ровное',
    },
    {
        value: 'Романовка',
    },
    {
        value: 'Ртищево',
    },
    {
        value: 'Самойловка',
    },
    {
        value: 'Саратов',
    },
    {
        value: 'Степное',
    },
    {
        value: 'Татищево',
    },
    {
        value: 'Турки',
    },
    {
        value: 'Хвалынск',
    },
    {
        value: 'Энгельс',
    },
    {
        value: 'Абый',
    },
    {
        value: 'Алдан',
    },
    {
        value: 'Амга',
    },
    {
        value: 'Батагай',
    },
    {
        value: 'Бердигестях',
    },
    {
        value: 'Беркакит',
    },
    {
        value: 'Бестях',
    },
    {
        value: 'Борогонцы',
    },
    {
        value: 'Верхневилюйск',
    },
    {
        value: 'Верхнеколымск',
    },
    {
        value: 'Верхоянск',
    },
    {
        value: 'Вилюйск',
    },
    {
        value: 'Витим',
    },
    {
        value: 'Власово',
    },
    {
        value: 'Жиганск',
    },
    {
        value: 'Зырянка',
    },
    {
        value: 'Кангалассы',
    },
    {
        value: 'Канкунский',
    },
    {
        value: 'Ленск',
    },
    {
        value: 'Майя',
    },
    {
        value: 'Менкеря',
    },
    {
        value: 'Мирный',
    },
    {
        value: 'Нерюнгри',
    },
    {
        value: 'Нычалах',
    },
    {
        value: 'Нюрба',
    },
    {
        value: 'Олекминск',
    },
    {
        value: 'Покровск',
    },
    {
        value: 'Сангар',
    },
    {
        value: 'Саскылах',
    },
    {
        value: 'Среднеколымск',
    },
    {
        value: 'Сунтар',
    },
    {
        value: 'Тикси',
    },
    {
        value: 'Усть-Мая',
    },
    {
        value: 'Усть-Нера',
    },
    {
        value: 'Хандыга',
    },
    {
        value: 'Хонуу',
    },
    {
        value: 'Черский',
    },
    {
        value: 'Чокурдах',
    },
    {
        value: 'Чурапча',
    },
    {
        value: 'Якутск',
    },
    {
        value: 'Александровск-Сахалинский',
    },
    {
        value: 'Анбэцу',
    },
    {
        value: 'Анива',
    },
    {
        value: 'Бошняково',
    },
    {
        value: 'Быков',
    },
    {
        value: 'Вахрушев',
    },
    {
        value: 'Взморье',
    },
    {
        value: 'Гастелло',
    },
    {
        value: 'Горнозаводск',
    },
    {
        value: 'Долинск',
    },
    {
        value: 'Ильинский',
    },
    {
        value: 'Катангли',
    },
    {
        value: 'Корсаков',
    },
    {
        value: 'Курильск',
    },
    {
        value: 'Макаров',
    },
    {
        value: 'Невельск',
    },
    {
        value: 'Ноглики',
    },
    {
        value: 'Оха',
    },
    {
        value: 'Поронайск',
    },
    {
        value: 'Северо-Курильск',
    },
    {
        value: 'Смирных',
    },
    {
        value: 'Томари',
    },
    {
        value: 'Тымовское',
    },
    {
        value: 'Углегорск',
    },
    {
        value: 'Холмск',
    },
    {
        value: 'Шахтерск',
    },
    {
        value: 'Южно-Курильск',
    },
    {
        value: 'Южно-Сахалинск',
    },
    {
        value: 'Алапаевск',
    },
    {
        value: 'Алтынай',
    },
    {
        value: 'Арамиль',
    },
    {
        value: 'Артемовский',
    },
    {
        value: 'Арти',
    },
    {
        value: 'Асбест',
    },
    {
        value: 'Ачит',
    },
    {
        value: 'Байкалово',
    },
    {
        value: 'Басьяновский',
    },
    {
        value: 'Белоярский',
    },
    {
        value: 'Березовский',
    },
    {
        value: 'Богданович',
    },
    {
        value: 'Буланаш',
    },
    {
        value: 'Верхний Тагил',
    },
    {
        value: 'Верхняя Пышма',
    },
    {
        value: 'Верхняя Салда',
    },
    {
        value: 'Верхняя Синячиха',
    },
    {
        value: 'Верхняя Сысерть',
    },
    {
        value: 'Верхняя Тура',
    },
    {
        value: 'Верхотурье',
    },
    {
        value: 'Висим',
    },
    {
        value: 'Волчанск',
    },
    {
        value: 'Воронцовка',
    },
    {
        value: 'Гари',
    },
    {
        value: 'Дегтярск',
    },
    {
        value: 'Екатеринбург',
    },
    {
        value: 'Ертарский',
    },
    {
        value: 'Заводоуспенское',
    },
    {
        value: 'Зыряновский',
    },
    {
        value: 'Зюзельский',
    },
    {
        value: 'Ивдель',
    },
    {
        value: 'Изумруд',
    },
    {
        value: 'Ирбит',
    },
    {
        value: 'Ис',
    },
    {
        value: 'Каменск-Уральский',
    },
    {
        value: 'Камышлов',
    },
    {
        value: 'Карпинск',
    },
    {
        value: 'Карпунинский',
    },
    {
        value: 'Качканар',
    },
    {
        value: 'Кировград',
    },
    {
        value: 'Краснотурьинск',
    },
    {
        value: 'Красноуральск',
    },
    {
        value: 'Красноуфимск',
    },
    {
        value: 'Кушва',
    },
    {
        value: 'Лесной',
    },
    {
        value: 'Михайловск',
    },
    {
        value: 'Невьянск',
    },
    {
        value: 'Нижние Серги',
    },
    {
        value: 'Нижний Тагил',
    },
    {
        value: 'Нижняя Салда',
    },
    {
        value: 'Нижняя Тура',
    },
    {
        value: 'Новая Ляля',
    },
    {
        value: 'Новоуральск',
    },
    {
        value: 'Оус',
    },
    {
        value: 'Первоуральск',
    },
    {
        value: 'Полевской',
    },
    {
        value: 'Пышма',
    },
    {
        value: 'Ревда',
    },
    {
        value: 'Реж',
    },
    {
        value: 'Свердловск',
    },
    {
        value: 'Североуральск',
    },
    {
        value: 'Серов',
    },
    {
        value: 'Сосьва',
    },
    {
        value: 'Среднеуральск',
    },
    {
        value: 'Сухой Лог',
    },
    {
        value: 'Сысерть',
    },
    {
        value: 'Таборы',
    },
    {
        value: 'Тавда',
    },
    {
        value: 'Талица',
    },
    {
        value: 'Тугулым',
    },
    {
        value: 'Туринск',
    },
    {
        value: 'Туринская Слобода',
    },
    {
        value: 'Алагир',
    },
    {
        value: 'Ардон',
    },
    {
        value: 'Беслан',
    },
    {
        value: 'Бурон',
    },
    {
        value: 'Владикавказ',
    },
    {
        value: 'Дигора',
    },
    {
        value: 'Моздок',
    },
    {
        value: 'Орджоникидзе',
    },
    {
        value: 'Чикола',
    },
    {
        value: 'Велиж',
    },
    {
        value: 'Верхнеднепровский',
    },
    {
        value: 'Ворга',
    },
    {
        value: 'Вязьма',
    },
    {
        value: 'Гагарин',
    },
    {
        value: 'Глинка',
    },
    {
        value: 'Голынки',
    },
    {
        value: 'Демидов',
    },
    {
        value: 'Десногорск',
    },
    {
        value: 'Дорогобуж',
    },
    {
        value: 'Духовщина',
    },
    {
        value: 'Екимовичи',
    },
    {
        value: 'Ельня',
    },
    {
        value: 'Ершичи',
    },
    {
        value: 'Издешково',
    },
    {
        value: 'Кардымово',
    },
    {
        value: 'Красный',
    },
    {
        value: 'Монастырщина',
    },
    {
        value: 'Новодугино',
    },
    {
        value: 'Починок',
    },
    {
        value: 'Рославль',
    },
    {
        value: 'Рудня',
    },
    {
        value: 'Сафоново',
    },
    {
        value: 'Смоленск',
    },
    {
        value: 'Сычевка',
    },
    {
        value: 'Угра',
    },
    {
        value: 'Хиславичи',
    },
    {
        value: 'Холм-Жирковский',
    },
    {
        value: 'Шумячи',
    },
    {
        value: 'Ярцево',
    },
    {
        value: 'Александровское',
    },
    {
        value: 'Арзгир',
    },
    {
        value: 'Благодарный',
    },
    {
        value: 'Буденновск',
    },
    {
        value: 'Георгиевск',
    },
    {
        value: 'Дивное',
    },
    {
        value: 'Домбай',
    },
    {
        value: 'Донское',
    },
    {
        value: 'Ессентуки',
    },
    {
        value: 'Железноводск',
    },
    {
        value: 'Зеленокумск',
    },
    {
        value: 'Изобильный',
    },
    {
        value: 'Иноземцево',
    },
    {
        value: 'Ипатово',
    },
    {
        value: 'Карачаевск',
    },
    {
        value: 'Кисловодск',
    },
    {
        value: 'Кочубеевское',
    },
    {
        value: 'Красногвардейское',
    },
    {
        value: 'Курсавка',
    },
    {
        value: 'Левокумское',
    },
    {
        value: 'Минеральные Воды',
    },
    {
        value: 'Невинномысск',
    },
    {
        value: 'Нефтекумск',
    },
    {
        value: 'Новоалександровск',
    },
    {
        value: 'Новоалександровская',
    },
    {
        value: 'Новопавловск',
    },
    {
        value: 'Новоселицкое',
    },
    {
        value: 'Преградная',
    },
    {
        value: 'Пятигорск',
    },
    {
        value: 'Светлоград',
    },
    {
        value: 'Солнечнодольск',
    },
    {
        value: 'Ставрополь',
    },
    {
        value: 'Степное',
    },
    {
        value: 'Теберда',
    },
    {
        value: 'Усть-Джегута',
    },
    {
        value: 'Хабез',
    },
    {
        value: 'Черкесск',
    },
    {
        value: 'Бондари',
    },
    {
        value: 'Гавриловка Вторая',
    },
    {
        value: 'Жердевка',
    },
    {
        value: 'Знаменка',
    },
    {
        value: 'Инжавино',
    },
    {
        value: 'Кирсанов',
    },
    {
        value: 'Котовск',
    },
    {
        value: 'Мичуринск',
    },
    {
        value: 'Мордово',
    },
    {
        value: 'Моршанск',
    },
    {
        value: 'Мучкапский',
    },
    {
        value: 'Первомайский',
    },
    {
        value: 'Петровское',
    },
    {
        value: 'Пичаево',
    },
    {
        value: 'Рассказово',
    },
    {
        value: 'Ржакса',
    },
    {
        value: 'Староюрьево',
    },
    {
        value: 'Тамбов',
    },
    {
        value: 'Токаревка',
    },
    {
        value: 'Уварово',
    },
    {
        value: 'Умет',
    },
    {
        value: 'Агрыз',
    },
    {
        value: 'Азнакаево',
    },
    {
        value: 'Аксубаево',
    },
    {
        value: 'Актюбинский',
    },
    {
        value: 'Алексеевское',
    },
    {
        value: 'Альметьевск',
    },
    {
        value: 'Альметьевск',
    },
    {
        value: 'Апастово',
    },
    {
        value: 'Арск',
    },
    {
        value: 'Бавлы',
    },
    {
        value: 'Базарные Матаки',
    },
    {
        value: 'Балтаси',
    },
    {
        value: 'Богатые Сабы',
    },
    {
        value: 'Брежнев',
    },
    {
        value: 'Бугульма',
    },
    {
        value: 'Буинск',
    },
    {
        value: 'Васильево',
    },
    {
        value: 'Верхний Услон',
    },
    {
        value: 'Высокая Гора',
    },
    {
        value: 'Дербешкинский',
    },
    {
        value: 'Елабуга',
    },
    {
        value: 'Заинск',
    },
    {
        value: 'Зеленодольск',
    },
    {
        value: 'Казань',
    },
    {
        value: 'Камское Устье',
    },
    {
        value: 'Карабаш',
    },
    {
        value: 'Куйбышев',
    },
    {
        value: 'Кукмод',
    },
    {
        value: 'Кукмор',
    },
    {
        value: 'Лаишево',
    },
    {
        value: 'Лениногорск',
    },
    {
        value: 'Мамадыш',
    },
    {
        value: 'Менделеевск',
    },
    {
        value: 'Мензелинск',
    },
    {
        value: 'Муслюмово',
    },
    {
        value: 'Набережные Челны',
    },
    {
        value: 'Нижнекамск',
    },
    {
        value: 'Новошешминск',
    },
    {
        value: 'Нурлат',
    },
    {
        value: 'Пестрецы',
    },
    {
        value: 'Рыбная Слобода',
    },
    {
        value: 'Сарманово',
    },
    {
        value: 'Старое Дрожжаное',
    },
    {
        value: 'Тетюши',
    },
    {
        value: 'Чистополь',
    },
    {
        value: 'Андреаполь',
    },
    {
        value: 'Бежецк',
    },
    {
        value: 'Белый',
    },
    {
        value: 'Белый Городок',
    },
    {
        value: 'Березайка',
    },
    {
        value: 'Бологое',
    },
    {
        value: 'Васильевский Мох',
    },
    {
        value: 'Выползово',
    },
    {
        value: 'Вышний Волочек',
    },
    {
        value: 'Жарковский',
    },
    {
        value: 'Западная Двина',
    },
    {
        value: 'Заречье',
    },
    {
        value: 'Зубцов',
    },
    {
        value: 'Изоплит',
    },
    {
        value: 'Калашниково',
    },
    {
        value: 'Калинин',
    },
    {
        value: 'Калязин',
    },
    {
        value: 'Кашин',
    },
    {
        value: 'Кесова Гора',
    },
    {
        value: 'Кимры',
    },
    {
        value: 'Конаково',
    },
    {
        value: 'Красный Холм',
    },
    {
        value: 'Кувшиново',
    },
    {
        value: 'Лесное',
    },
    {
        value: 'Лихославль',
    },
    {
        value: 'Максатиха',
    },
    {
        value: 'Молоково',
    },
    {
        value: 'Нелидово',
    },
    {
        value: 'Оленино',
    },
    {
        value: 'Осташков',
    },
    {
        value: 'Пено',
    },
    {
        value: 'Рамешки',
    },
    {
        value: 'Ржев',
    },
    {
        value: 'Сандово',
    },
    {
        value: 'Селижарово',
    },
    {
        value: 'Сонково',
    },
    {
        value: 'Спирово',
    },
    {
        value: 'Старица',
    },
    {
        value: 'Тверь',
    },
    {
        value: 'Торжок',
    },
    {
        value: 'Торопец',
    },
    {
        value: 'Удомля',
    },
    {
        value: 'Фирово',
    },
    {
        value: 'Александровское',
    },
    {
        value: 'Асино',
    },
    {
        value: 'Бакчар',
    },
    {
        value: 'Батурино',
    },
    {
        value: 'Белый Яр',
    },
    {
        value: 'Зырянское',
    },
    {
        value: 'Итатка',
    },
    {
        value: 'Каргасок',
    },
    {
        value: 'Катайга',
    },
    {
        value: 'Кожевниково',
    },
    {
        value: 'Колпашево',
    },
    {
        value: 'Кривошеино',
    },
    {
        value: 'Мельниково',
    },
    {
        value: 'Молчаново',
    },
    {
        value: 'Парабель',
    },
    {
        value: 'Первомайское',
    },
    {
        value: 'Подгорное',
    },
    {
        value: 'Северск',
    },
    {
        value: 'Стрежевой',
    },
    {
        value: 'Томск',
    },
    {
        value: 'Тымск',
    },
    {
        value: 'Ак-Довурак',
    },
    {
        value: 'Бай Хаак',
    },
    {
        value: 'Кызыл',
    },
    {
        value: 'Самагалтай',
    },
    {
        value: 'Сарыг-Сеп',
    },
    {
        value: 'Суть-Холь',
    },
    {
        value: 'Тоора-Хем',
    },
    {
        value: 'Туран',
    },
    {
        value: 'Тээли',
    },
    {
        value: 'Хову-Аксы',
    },
    {
        value: 'Чадан',
    },
    {
        value: 'Шагонар',
    },
    {
        value: 'Эрзин',
    },
    {
        value: 'Агеево',
    },
    {
        value: 'Алексин',
    },
    {
        value: 'Арсеньево',
    },
    {
        value: 'Барсуки',
    },
    {
        value: 'Бегичевский',
    },
    {
        value: 'Белев',
    },
    {
        value: 'Богородицк',
    },
    {
        value: 'Болохово',
    },
    {
        value: 'Велегож',
    },
    {
        value: 'Венев',
    },
    {
        value: 'Волово',
    },
    {
        value: 'Горелки',
    },
    {
        value: 'Донской',
    },
    {
        value: 'Дубна',
    },
    {
        value: 'Епифань',
    },
    {
        value: 'Ефремов',
    },
    {
        value: 'Заокский',
    },
    {
        value: 'Казановка',
    },
    {
        value: 'Кимовск',
    },
    {
        value: 'Киреевск',
    },
    {
        value: 'Куркино',
    },
    {
        value: 'Ленинский',
    },
    {
        value: 'Новомосковск',
    },
    {
        value: 'Одоев',
    },
    {
        value: 'Плавск',
    },
    {
        value: 'Суворов',
    },
    {
        value: 'Тула',
    },
    {
        value: 'Узловая',
    },
    {
        value: 'Щекино',
    },
    {
        value: 'Ясногорск',
    },
    {
        value: 'Абатский',
    },
    {
        value: 'Аксарка',
    },
    {
        value: 'Армизонское',
    },
    {
        value: 'Аромашево',
    },
    {
        value: 'Белоярский',
    },
    {
        value: 'Бердюжье',
    },
    {
        value: 'Большое Сорокино',
    },
    {
        value: 'Вагай',
    },
    {
        value: 'Викулово',
    },
    {
        value: 'Винзили',
    },
    {
        value: 'Голышманово',
    },
    {
        value: 'Губкинский',
    },
    {
        value: 'Заводопетровский',
    },
    {
        value: 'Заводоуковск',
    },
    {
        value: 'Излучинск',
    },
    {
        value: 'Исетское',
    },
    {
        value: 'Ишим',
    },
    {
        value: 'Казанское',
    },
    {
        value: 'Казым-Мыс',
    },
    {
        value: 'Когалым',
    },
    {
        value: 'Кондинское',
    },
    {
        value: 'Красноселькуп',
    },
    {
        value: 'Лабытнанги',
    },
    {
        value: 'Ларьяк',
    },
    {
        value: 'Мегион',
    },
    {
        value: 'Мужи',
    },
    {
        value: 'Муравленко',
    },
    {
        value: 'Надым',
    },
    {
        value: 'Находка',
    },
    {
        value: 'Нефтеюганск',
    },
    {
        value: 'Нижневартовск',
    },
    {
        value: 'Нижняя Тавда',
    },
    {
        value: 'Новый Уренгой',
    },
    {
        value: 'Ноябрьск',
    },
    {
        value: 'Нягань',
    },
    {
        value: 'Октябрьское',
    },
    {
        value: 'Омутинский',
    },
    {
        value: 'Радужный',
    },
    {
        value: 'Салехард',
    },
    {
        value: 'Сладково',
    },
    {
        value: 'Советский',
    },
    {
        value: 'Сургут',
    },
    {
        value: 'Тазовский',
    },
    {
        value: 'Тарко-Сале',
    },
    {
        value: 'Тобольск',
    },
    {
        value: 'Тюмень',
    },
    {
        value: 'Уват',
    },
    {
        value: 'Унъюган',
    },
    {
        value: 'Упорово',
    },
    {
        value: 'Урай',
    },
    {
        value: 'Ханты-Мансийск',
    },
    {
        value: 'Юрибей',
    },
    {
        value: 'Ялуторовск',
    },
    {
        value: 'Яр-Сале',
    },
    {
        value: 'Ярково',
    },
    {
        value: 'Алнаши',
    },
    {
        value: 'Балезино',
    },
    {
        value: 'Вавож',
    },
    {
        value: 'Воткинск',
    },
    {
        value: 'Глазов',
    },
    {
        value: 'Грахово',
    },
    {
        value: 'Дебесы',
    },
    {
        value: 'Завьялово',
    },
    {
        value: 'Игра',
    },
    {
        value: 'Ижевск',
    },
    {
        value: 'Кама',
    },
    {
        value: 'Камбарка',
    },
    {
        value: 'Каракулино',
    },
    {
        value: 'Кез',
    },
    {
        value: 'Кизнер',
    },
    {
        value: 'Киясово',
    },
    {
        value: 'Красногорское',
    },
    {
        value: 'Можга',
    },
    {
        value: 'Сарапул',
    },
    {
        value: 'Селты',
    },
    {
        value: 'Сюмси',
    },
    {
        value: 'Ува',
    },
    {
        value: 'Устинов',
    },
    {
        value: 'Шаркан',
    },
    {
        value: 'Юкаменское',
    },
    {
        value: 'Якшур-Бодья',
    },
    {
        value: 'Яр',
    },
    {
        value: 'Базарный Сызган',
    },
    {
        value: 'Барыш',
    },
    {
        value: 'Большое Нагаткино',
    },
    {
        value: 'Вешкайма',
    },
    {
        value: 'Глотовка',
    },
    {
        value: 'Димитровград',
    },
    {
        value: 'Игнатовка',
    },
    {
        value: 'Измайлово',
    },
    {
        value: 'Инза',
    },
    {
        value: 'Ишеевка',
    },
    {
        value: 'Канадей',
    },
    {
        value: 'Карсун',
    },
    {
        value: 'Кузоватово',
    },
    {
        value: 'Майна',
    },
    {
        value: 'Новая Малыкла',
    },
    {
        value: 'Новоспасское',
    },
    {
        value: 'Павловка',
    },
    {
        value: 'Радищево',
    },
    {
        value: 'Сенгилей',
    },
    {
        value: 'Старая Кулатка',
    },
    {
        value: 'Старая Майна',
    },
    {
        value: 'Сурское',
    },
    {
        value: 'Тереньга',
    },
    {
        value: 'Ульяновск',
    },
    {
        value: 'Чердаклы',
    },
    {
        value: 'Аксай',
    },
    {
        value: 'Дарьинское',
    },
    {
        value: 'Деркул',
    },
    {
        value: 'Джамбейты',
    },
    {
        value: 'Джаныбек',
    },
    {
        value: 'Казталовка',
    },
    {
        value: 'Калмыково',
    },
    {
        value: 'Каратобе',
    },
    {
        value: 'Переметное',
    },
    {
        value: 'Сайхин',
    },
    {
        value: 'Уральск',
    },
    {
        value: 'Федоровка',
    },
    {
        value: 'Фурманово',
    },
    {
        value: 'Чапаев',
    },
    {
        value: 'Амурск',
    },
    {
        value: 'Аян',
    },
    {
        value: 'Березовый',
    },
    {
        value: 'Бикин',
    },
    {
        value: 'Бира',
    },
    {
        value: 'Биракан',
    },
    {
        value: 'Богородское',
    },
    {
        value: 'Болонь',
    },
    {
        value: 'Ванино',
    },
    {
        value: 'Волочаевка Вторая',
    },
    {
        value: 'Высокогорный',
    },
    {
        value: 'Вяземский',
    },
    {
        value: 'Горный',
    },
    {
        value: 'Гурское',
    },
    {
        value: 'Дормидонтовка',
    },
    {
        value: 'Заветы Ильича',
    },
    {
        value: 'Известковый',
    },
    {
        value: 'Иннокентьевка',
    },
    {
        value: 'Комсомольск-на-Амуре',
    },
    {
        value: 'Ленинское',
    },
    {
        value: 'Нелькан',
    },
    {
        value: 'Николаевск-на-Амуре',
    },
    {
        value: 'Облучье',
    },
    {
        value: 'Охотск',
    },
    {
        value: 'Переяславка',
    },
    {
        value: 'Смидович',
    },
    {
        value: 'Советская Гавань',
    },
    {
        value: 'Софийск',
    },
    {
        value: 'Троицкое',
    },
    {
        value: 'Тугур',
    },
    {
        value: 'Хабаровск',
    },
    {
        value: 'Чегдомын',
    },
    {
        value: 'Чумикан',
    },
    {
        value: 'Абакан',
    },
    {
        value: 'Саяногорск',
    },
    {
        value: 'Аган',
    },
    {
        value: 'Игрим',
    },
    {
        value: 'Излучинск',
    },
    {
        value: 'Лангепас',
    },
    {
        value: 'Лянтор',
    },
    {
        value: 'Мегион',
    },
    {
        value: 'Нефтеюганск',
    },
    {
        value: 'Нижневартовск',
    },
    {
        value: 'Нягань',
    },
    {
        value: 'Покачи',
    },
    {
        value: 'Приобье',
    },
    {
        value: 'Пыть-Ях',
    },
    {
        value: 'Радужный',
    },
    {
        value: 'Сургут',
    },
    {
        value: 'Урай',
    },
    {
        value: 'Ханты-Мансийск',
    },
    {
        value: 'Югорск',
    },
    {
        value: 'Агаповка',
    },
    {
        value: 'Аргаяш',
    },
    {
        value: 'Аша',
    },
    {
        value: 'Бакал',
    },
    {
        value: 'Бреды',
    },
    {
        value: 'Варна',
    },
    {
        value: 'Верхнеуральск',
    },
    {
        value: 'Верхний Уфалей',
    },
    {
        value: 'Еманжелинск',
    },
    {
        value: 'Златоуст',
    },
    {
        value: 'Карабаш',
    },
    {
        value: 'Карталы',
    },
    {
        value: 'Касли',
    },
    {
        value: 'Катав-Ивановск',
    },
    {
        value: 'Копейск',
    },
    {
        value: 'Коркино',
    },
    {
        value: 'Кунашак',
    },
    {
        value: 'Куса',
    },
    {
        value: 'Кыштым',
    },
    {
        value: 'Магнитогорск',
    },
    {
        value: 'Миасс',
    },
    {
        value: 'Озерск',
    },
    {
        value: 'Октябрьское',
    },
    {
        value: 'Пласт',
    },
    {
        value: 'Сатка',
    },
    {
        value: 'Сим',
    },
    {
        value: 'Снежинск',
    },
    {
        value: 'Трехгорный',
    },
    {
        value: 'Троицк',
    },
    {
        value: 'Увельский',
    },
    {
        value: 'Уйское',
    },
    {
        value: 'Усть-Катав',
    },
    {
        value: 'Фершампенуаз',
    },
    {
        value: 'Чебаркуль',
    },
    {
        value: 'Челябинск',
    },
    {
        value: 'Чесма',
    },
    {
        value: 'Южно-Уральск',
    },
    {
        value: 'Юрюзань',
    },
    {
        value: 'Аргун',
    },
    {
        value: 'Грозный',
    },
    {
        value: 'Гудермез',
    },
    {
        value: 'Малгобек',
    },
    {
        value: 'Назрань',
    },
    {
        value: 'Наурская',
    },
    {
        value: 'Ножай-Юрт',
    },
    {
        value: 'Орджоникидзевская',
    },
    {
        value: 'Советское',
    },
    {
        value: 'Урус-Мартан',
    },
    {
        value: 'Шали',
    },
    {
        value: 'Агинское',
    },
    {
        value: 'Аксеново-Зиловское',
    },
    {
        value: 'Акша',
    },
    {
        value: 'Александровский Завод',
    },
    {
        value: 'Амазар',
    },
    {
        value: 'Арбагар',
    },
    {
        value: 'Атамановка',
    },
    {
        value: 'Балей',
    },
    {
        value: 'Борзя',
    },
    {
        value: 'Букачача',
    },
    {
        value: 'Газимурский Завод',
    },
    {
        value: 'Давенда',
    },
    {
        value: 'Дарасун',
    },
    {
        value: 'Дровяная',
    },
    {
        value: 'Дульдурга',
    },
    {
        value: 'Жиндо',
    },
    {
        value: 'Забайкальск',
    },
    {
        value: 'Итака',
    },
    {
        value: 'Калга',
    },
    {
        value: 'Карымское',
    },
    {
        value: 'Кличка',
    },
    {
        value: 'Ключевский',
    },
    {
        value: 'Кокуй',
    },
    {
        value: 'Краснокаменск',
    },
    {
        value: 'Красный Чикой',
    },
    {
        value: 'Кыра',
    },
    {
        value: 'Моготуй',
    },
    {
        value: 'Могоча',
    },
    {
        value: 'Нерчинск',
    },
    {
        value: 'Нерчинский Завод',
    },
    {
        value: 'Нижний Часучей',
    },
    {
        value: 'Оловянная',
    },
    {
        value: 'Первомайский',
    },
    {
        value: 'Петровск-Забайкальский',
    },
    {
        value: 'Приаргунск',
    },
    {
        value: 'Сретенск',
    },
    {
        value: 'Тупик',
    },
    {
        value: 'Улеты',
    },
    {
        value: 'Хилок',
    },
    {
        value: 'Чара',
    },
    {
        value: 'Чернышевск',
    },
    {
        value: 'Чита',
    },
    {
        value: 'Шелопугино',
    },
    {
        value: 'Шилка',
    },
    {
        value: 'Алатырь',
    },
    {
        value: 'Аликово',
    },
    {
        value: 'Батырева',
    },
    {
        value: 'Буинск',
    },
    {
        value: 'Вурнары',
    },
    {
        value: 'Ибреси',
    },
    {
        value: 'Канаш',
    },
    {
        value: 'Киря',
    },
    {
        value: 'Комсомольское',
    },
    {
        value: 'Красноармейское',
    },
    {
        value: 'Красные Четаи',
    },
    {
        value: 'Кугеси',
    },
    {
        value: 'Мариинский Посад',
    },
    {
        value: 'Моргауши',
    },
    {
        value: 'Новочебоксарск',
    },
    {
        value: 'Порецкое',
    },
    {
        value: 'Урмары',
    },
    {
        value: 'Цивильск',
    },
    {
        value: 'Чебоксары',
    },
    {
        value: 'Шемурша',
    },
    {
        value: 'Шумерля',
    },
    {
        value: 'Ядрин',
    },
    {
        value: 'Яльчики',
    },
    {
        value: 'Янтиково',
    },
    {
        value: 'Анадырь',
    },
    {
        value: 'Билибино',
    },
    {
        value: 'Губкинский',
    },
    {
        value: 'Заполярный',
    },
    {
        value: 'Муравленко',
    },
    {
        value: 'Надым',
    },
    {
        value: 'Новый Уренгой',
    },
    {
        value: 'Ноябрьск',
    },
    {
        value: 'Пуровск',
    },
    {
        value: 'Салехард',
    },
    {
        value: 'Тарко',
    },
    {
        value: 'Андропов',
    },
    {
        value: 'Берендеево',
    },
    {
        value: 'Большое Село',
    },
    {
        value: 'Борисоглебский',
    },
    {
        value: 'Брейтово',
    },
    {
        value: 'Бурмакино',
    },
    {
        value: 'Варегово',
    },
    {
        value: 'Волга',
    },
    {
        value: 'Гаврилов Ям',
    },
    {
        value: 'Данилов',
    },
    {
        value: 'Любим',
    },
    {
        value: 'Мышкино',
    },
    {
        value: 'Некрасовское',
    },
    {
        value: 'Новый Некоуз',
    },
    {
        value: 'Переславль-Залесский',
    },
    {
        value: 'Пошехонье-Володарск',
    },
    {
        value: 'Ростов',
    },
    {
        value: 'Рыбинск',
    },
    {
        value: 'Тутаев',
    },
    {
        value: 'Углич',
    },
    {
        value: 'Ярославль',
    },
];
