import styles from './index.module.scss';

const FooterCopyright = () => {
    return (
        <div className={styles['footer__copyright']}>
            <p>«Интернет без угроз», НЦПТИ ФГАНУ НИИ «Спецвузавтоматика»</p>
            <p className={styles['footer__copyright-contact-info']}>
                Адрес редакции: 344010, Ростов-на-Дону, ул. Города Волос, д.6. Тел. редакции:
                <a href="tel:+78632012822">ИНН 6164205270 КПП 616501001 ОГРН 1026103272480</a>
            </p>
            <p className={styles['footer__copyright-rights']}>
                Все права на размещенную на платформе информацию принадлежат НЦПТИ. Материалы могут
                быть использованы только с обязательным размещением активной ссылки на
                первоисточник. Возрастное ограничение - 6+.
            </p>
        </div>
    );
};

export default FooterCopyright;
