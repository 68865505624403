import { cities } from './consts';
import { optionsList } from '../../ui/SearchBar';

interface ICitiesFetch {
    suggestions: optionsList;
}

export const getCities = async (query: string) => {
    try {
        const url = 'http://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address';
        const API__KEY = '08642f69fd0052af32746c86ecbb98dc9d4e28a5';

        const request = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: 'Token ' + API__KEY,
            },
            body: JSON.stringify({
                query: query,
                type: 'ADDRESS',
                hint: false,
                bounds: 'city-settlement',
                geoLocation: false,
                to_bound: { value: 'settlement' },
                from_bound: { value: 'city' },
                count: 10,
            }),
        });
        const { suggestions }: ICitiesFetch = await request.json();
        return suggestions;
    } catch (err) {
        return cities;
    }
};
