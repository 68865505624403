import { APP__ROUTS } from '../../shared/consts';
import { Suspense } from 'react';
import { lazyRetry } from '../../shared/utils/lazyRetry';

const UserPageLazy = lazyRetry(() => import('../../pages/UserPage/index'));


export const UserRoutes = [
    {
        path: APP__ROUTS.userLayout,
        element: (
            <Suspense>
                <UserPageLazy />
            </Suspense>
        ),
    },
]