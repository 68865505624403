import { useForm, SubmitHandler } from 'react-hook-form';
import { validateMail } from '../../../../shared/utils/validators';
import { PASSWORD__INPUT, MAIL__INPUT } from './consts';
import { validatePassword } from '../../../../shared/utils/validators';
import { IResetPassForm } from './types';
import { getResetPassDto } from './utils';
import { resetPassRequest } from './api';
import { useAppDispatch } from '../../../../shared/store';
import { createAlert, createAlertOptions } from '../../../../entities/alerts';

export function useResetPassFormState() {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm<IResetPassForm>({
        mode: 'onTouched',
        defaultValues: {
            mail: '',
            password: '',
        },
    });
    const watchMail = watch(MAIL__INPUT);
    const watchPassword = watch(PASSWORD__INPUT);
    const dispatch = useAppDispatch();
    const submit: SubmitHandler<IResetPassForm> = async (data) => {
        const dto = getResetPassDto(data);
        const response = await resetPassRequest(dto);
        if (response) {
            dispatch(
                createAlert(
                    createAlertOptions(
                        'Запрос был отправлен на указанную почту!',
                        'Пожалуйста, подтвердите Вашу почту для завершения регистрации'
                    )
                )
            );
        } else {
            dispatch(
                createAlert(
                    createAlertOptions(
                        'Что-то пошло не-так',
                        'Пожалуйста, попробуйте еще раз или обратитесь в службу поддержки Подвиг.РФ для получения помощи.',
                        'middle'
                    )
                )
            );
        }
    };

    const mailRegister = register(MAIL__INPUT, {
        required: 'Поле обязательно',
        validate: {
            validateMail: (v) => validateMail(v) || 'Введите email, указанный при регистрации.',
        },
        maxLength: {
            value: 100,
            message: 'превышено допустимое количество символов',
        },
    });

    const mailErrorText = errors?.mail && errors?.mail?.message;

    const passwordRegister = register(PASSWORD__INPUT, {
        required: 'Поле обязательно',
        validate: {
            validatePassword: (v) =>
                validatePassword(v) ||
                'Пароль может состоять только из букв латинского алфавита, цифр и специальных символов таких как @, $, !, %, *, ?, &',
        },
        maxLength: {
            value: 100,
            message: 'превышено допустимое количество символов',
        },
        minLength: {
            value: 8,
            message: 'Пароль должен состоять минимум из восьми символов',
        },
    });

    const passwordErrorText = errors?.password && errors?.password?.message;

    return {
        handleSubmit: handleSubmit(submit),
        watchMail,
        watchPassword,
        mailRegister,
        passwordRegister,
        mailErrorText,
        passwordErrorText,
    };
}
