import styels from './index.module.scss';
import { useEffect } from 'react';
import { getAlerts, deleteAlert } from '../../model/alerts';
import { useAppSelector } from '../../../../shared/store';
import { useAppDispatch } from '../../../../shared/store';
import { createPortal } from 'react-dom';
import clsx from 'clsx';
import { BottomAlert } from '../BottomAlert';
import { MiddleAlert } from '../MiddleAlert';

export const Alerts = () => {
    const alertContainer = document.getElementById('alerts')!;
    const alerts = useAppSelector(getAlerts);

    const dispatch = useAppDispatch();
    const closeAlert = (id: string) => {
        dispatch(deleteAlert(id));
    };
    const bottomAlerts = alerts.filter((alert) => alert.position === 'bottom');
    const middleAlerts = alerts.filter((alert) => alert.position === 'middle');

    useEffect(() => {
        const visibleTimer = setTimeout(() => {
            if (bottomAlerts.length) {
                dispatch(deleteAlert(bottomAlerts[0].id));
            }
        }, 5000);
        return () => {
            clearTimeout(visibleTimer);
        };
    }, [alerts]);
    return createPortal(
        <>
            {middleAlerts.map((alert) => (
                <MiddleAlert
                    key={alert.id}
                    title={alert.title}
                    text={alert.text}
                    onClick={() => {
                        closeAlert(alert.id);
                    }}
                />
            ))}
            <div className={styels['text-alert__container']}>
                {bottomAlerts.slice(0, 6).map((alert) => (
                    <BottomAlert
                        key={alert.id}
                        title={alert.title}
                        text={alert.text}
                        onClick={() => {
                            closeAlert(alert.id);
                        }}
                    />
                ))}
            </div>
        </>,
        alertContainer
    );
};
