import { ISignUpForm, ISignUpDto } from "./types";

export function getSignUpDto(signUpData: ISignUpForm): ISignUpDto {
    return {
        fio: signUpData.name,
        email: signUpData.mail,
        email_subscription: true,
        city: signUpData.location,
        date_of_birth: new Date(signUpData.birthday).toISOString(),
        password: signUpData.password,
    };
}
