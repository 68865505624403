import { ReactNode } from 'react';
import styles from './index.module.scss';
import clsx from 'clsx';

interface IPropsBtn {
    text: ReactNode;
    onClick?: (e: unknown) => void;
    variant:
        | 'primary'
        | 'orange'
        | 'light-orange'
        | 'admin'
        | 'gray'
        | 'white'
        | 'light-orange-gradient'
        | 'orange-gradient'
        | 'transparent';
    type: 'button' | 'submit' | 'reset' | undefined;
    className?: string;
    isLoading?: boolean;
    isDisabled?: boolean;
}

const Button: React.FC<IPropsBtn> = ({
    text = '',
    onClick,
    variant,
    type,
    className = '',
    isLoading = false,
    isDisabled = false,
}) => {
    return (
        <button
            onClick={onClick}
            type={type}
            disabled={isLoading}
            className={clsx(
                styles['button'],
                className,
                {
                    ['primary']: styles['button--primary'],
                    ['orange']: styles['button--orange'],
                    ['light-orange']: styles['button--light-orange'],
                    ['admin']: styles['button--admin'],
                    ['gray']: styles['button--gray'],
                    ['white']: styles['button--white'],
                    ['light-orange-gradient']: styles['button--light-orange-gradient'],
                    ['orange-gradient']: styles['button--orange-gradient'],
                    ['transparent']: styles['button--transparent'],
                }[variant],
                isLoading && styles['button--loading'],
                isDisabled && styles['button--disabled']
            )}
        >
            {text}
        </button>
    );
};

export default Button;
