import { CreatedAlert } from './alerts';

export function createAlertOptions(
    title: string,
    text: string,
    position?: 'middle' | 'bottom'
): CreatedAlert {
    return {
        title,
        text,
        position,
    };
}
