import { Header } from '../../../../widgets/Header';
import { Footer } from '../../../../widgets/Footer';
import { MobileNav } from '../../../../widgets/MobileNav';
import { Outlet } from 'react-router-dom';
import CloudsBackground from '../CloudsBackground';
import FeatAudioPlayer from '../../../../widgets/FeatAudioPlayer';
import { Alerts } from '../../../../entities/alerts';
import AuthWrapper from '../../../../widgets/AuthWrapper';
import AuthFormRouter from '../../../../widgets/AuthFormRouter';
import { ScrollOnTop } from '../../../../widgets/ScrollOnTop';
import ContestBanner from '../../../../widgets/ContestBanner';
import { GlobalModal } from '../GlobalModal';

export const MainLayout = () => {
    return (
        <AuthWrapper>
            <GlobalModal />
            <ContestBanner />
            <ScrollOnTop />
            <Alerts />
            <CloudsBackground />
            <MobileNav />
            <Header />
            <AuthFormRouter />
            <main>
                <Outlet />
            </main>
            <Footer />
            <FeatAudioPlayer />
        </AuthWrapper>
    );
};
