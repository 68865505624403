import PagePreloader from '../../shared/ui/PagePreloader';
import { APP__ROUTS } from '../../shared/consts';
import { Suspense } from 'react';
import { lazyRetry } from '../../shared/utils/lazyRetry';
import AdminLayout from '../../pages/AdminLayout';

const AdminGalleryLazy = lazyRetry(() => import('../../pages/AdminGallery/index'));
const AdminContestsLazy = lazyRetry(() => import('../../pages/AdminContests/index'));
const AdminFeatsLazy = lazyRetry(() => import('../../pages/AdminFeats/index'));
const AdminPuzzlesLazy = lazyRetry(() => import('../../pages/AdminPuzzles/index'));

export const AdminRoutes = [
    {
        path: APP__ROUTS.adminLayout,
        element: <AdminLayout />,
        children: [
            {
                index: true,
                path: APP__ROUTS.adminGallery,
                element: (
                    <Suspense fallback={<PagePreloader variant="admin" />}>
                        <AdminGalleryLazy />
                    </Suspense>
                ),
            },
            {
                path: APP__ROUTS.adminContests,
                element: (
                    <Suspense fallback={<PagePreloader variant="admin" />}>
                        <AdminContestsLazy />
                    </Suspense>
                ),
            },
            {
                path: APP__ROUTS.adminFeats,
                element: (
                    <Suspense fallback={<PagePreloader variant="admin" />}>
                        <AdminFeatsLazy />
                    </Suspense>
                ),
            },
            {
                path: APP__ROUTS.adminPuzzles,
                element: (
                    <Suspense fallback={<PagePreloader variant="admin" />}>
                        <AdminPuzzlesLazy />
                    </Suspense>
                ),
            },
        ],
    },
];
