import styels from './index.module.scss';
import { DOCUMENTS__LINKS } from './consts';

import { BASE__MEDIA__URL } from '../../../../shared/consts';

const FooterPolicy = () => {
    return (
        <div className={styels['footer__policy']}>
            <a
                className={styels['footer__policy-link']}
                href={`${BASE__MEDIA__URL}${DOCUMENTS__LINKS.privacyPolicy}`}
                target="_blank"
            >
                Политика конфиденциальности
            </a>
            <a
                className={styels['footer__policy-link']}
                href={`${BASE__MEDIA__URL}${DOCUMENTS__LINKS.processingPersonalData}`}
                target="_blank"
            >
                Соглашение на обработку персональных данных
            </a>
            <a
                className={styels['footer__policy-link']}
                href={`${BASE__MEDIA__URL}${DOCUMENTS__LINKS.distributionPersonalData}`}
                target="_blank"
            >
                Согласие на распространение персональных данных
            </a>
        </div>
    );
};

export default FooterPolicy;
