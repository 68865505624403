import MobileNavLayout from '../MobileNavLayout';
import MobileNavList from '../MobileNavList';
import { NAV_LINKS_DATA, PROFILE__LINK__DATA } from '../../consts';
import MobileNavLink from '../MobileNavLink';
import { getAuthStatus } from '../../../../entities/session';
import { useAppSelector, useAppDispatch } from '../../../../shared/store';
import { changeModalVisibleStatus } from '../../../../entities/authModal';

export const MobileNav = () => {
    const authStatus = useAppSelector(getAuthStatus);
    const dispatch = useAppDispatch();
    const changeAuthModalStatus = () => {
        dispatch(changeModalVisibleStatus());
    };
    return (
        <MobileNavLayout>
            <MobileNavList>
                {NAV_LINKS_DATA.map((navLink) => {
                    return (
                        <MobileNavLink
                            key={navLink.name}
                            path={navLink.path}
                            name={navLink.name}
                            iconVariant={navLink.iconVariant}
                        />
                    );
                })}
                {authStatus ? (
                    <MobileNavLink
                        path={PROFILE__LINK__DATA.path}
                        name={PROFILE__LINK__DATA.name}
                        iconVariant={PROFILE__LINK__DATA.iconVariant}
                    />
                ) : (
                    <MobileNavLink
                        path={window.location.href}
                        name={PROFILE__LINK__DATA.name}
                        iconVariant={PROFILE__LINK__DATA.iconVariant}
                        onClick={changeAuthModalStatus}
                    />
                )}
            </MobileNavList>
        </MobileNavLayout>
    );
};
