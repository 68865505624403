import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { audiopPlayerSlice } from '../../entities/featAudioPlayer/model';
import { featsSlice } from '../../entities/feat/model/feat';
import { sessionSlice } from '../../entities/session';
import { alertsSlice } from '../../entities/alerts';
import { authModalRoutingSlice } from '../../entities/authModal';

export const store = configureStore({
    reducer: {
        [featsSlice.name]: featsSlice.reducer,
        [audiopPlayerSlice.name]: audiopPlayerSlice.reducer,
        [sessionSlice.name]: sessionSlice.reducer,
        [alertsSlice.name]: alertsSlice.reducer,
        [authModalRoutingSlice.name]: authModalRoutingSlice.reducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
store.dispatch;
export const useAppDispatch = useDispatch<AppDispatch>;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
