import React, { ReactNode } from 'react';
import { useEffect } from 'react';
import { useAppDispatch } from '../../shared/store';
import { fetchUserData } from '../../entities/session';
import { getAuthStatus, getUserLoadingDataStatus } from '../../entities/session';
import { useAppSelector } from '../../shared/store';
import { Navigate } from 'react-router-dom';
import { APP__ROUTS } from '../../shared/consts';
import PreLoader from '../../shared/ui/PreLoader';
import styels from './index.module.scss';

interface IAuthGuard {
    children: ReactNode;
}

const AuthGuard: React.FC<IAuthGuard> = ({ children }) => {
    const dispatch = useAppDispatch();
    const isUserAuth = useAppSelector(getAuthStatus);
    const isUserDataLoading = useAppSelector(getUserLoadingDataStatus);

    useEffect(() => {
        dispatch(fetchUserData());
    }, []);
    if (!isUserDataLoading) {
        return <PreLoader className={styels['auth-guard__preloader']}/>;
    }

    if (!isUserAuth) {
        return <Navigate to={APP__ROUTS.main} replace />;
    }
    return <>{children}</>;
};

export default AuthGuard;
