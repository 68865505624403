import { ISignInDto } from './types';
import { BASE__API__URL } from '../../../../shared/consts';

export async function signInRequest(signInDto: ISignInDto) {
    try {
        const response = await fetch(`${BASE__API__URL}user/login`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(signInDto),
        });
        return response;
    } catch (err) {
        return undefined;
    }
}


