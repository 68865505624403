import { FeatDetail } from '../FeatDetail';
import Modal from '../../../../shared/ui/Modal';
import { useCreateModalState } from '../../../../shared/hooks/modal';
import FeatPreview from '../FeatPreview';
import { IFeat } from '../../model/types';
import styles from './index.module.scss';

interface IFeatCardProps {
    children?: React.ReactNode;
    playAudio: (event: React.MouseEvent, id: number) => void;
    stopAudio: (event: React.MouseEvent) => void;
    isPlaying: boolean;
    isChoosen: boolean;
    shareBtnOnClick: () => void;
    vkOnClick: () => void;
    feat: IFeat;
}

export const FeatCard: React.FC<IFeatCardProps> = ({
    playAudio,
    stopAudio,
    isPlaying,
    isChoosen,
    shareBtnOnClick,
    feat,
    vkOnClick,
}) => {
    const { isModalOpen, toggleModalStatus } = useCreateModalState();

    return (
        <>
            <FeatPreview
                shareIconOnClick={shareBtnOnClick}
                toggleModalStatus={toggleModalStatus}
                playAudio={playAudio}
                stopAudio={stopAudio}
                isPlaying={isPlaying}
                isChoosen={isChoosen}
                shareVkOnClick={vkOnClick}
                feat={feat}
            />

            {isModalOpen && (
                <Modal isOpen={isModalOpen} setStatus={toggleModalStatus} modalClassName={styles.modal}>
                    <FeatDetail
                        feat={feat}
                        isPlaying={isPlaying}
                        playAudio={playAudio}
                        stopAudio={stopAudio}
                        isChoosen={isChoosen}
                        shareIconOnClick={shareBtnOnClick}
                        shareVkOnClick={vkOnClick}
                    />
                </Modal>
            )}
        </>
    );
};
