import styels from './index.module.scss';
import { Link } from 'react-router-dom';
import LogoImg from '../../assets/logo.webp';
import clsx from 'clsx';

const Logo = ({ path = '', className = '' }) => {
    return (
        <div className={styels['logo-container']}>
            <Link to={path}>
                <img
                    className={clsx(styels['logo-img'], className)}
                    src={LogoImg}
                    alt="логотип проекта подвиг.рф"
                />
            </Link>
        </div>
    );
};

export default Logo;
