import styels from './index.module.scss';
import clsx from 'clsx';
import { optionsListType, option } from '../../utils/transformData';
import { useEffect } from 'react';

interface IOptionsList {
    options: optionsListType;
    changeValue: (id: number | undefined) => void;
    className?: string;
    choosenOption?: option;
    close: () => void;
    isVisible: boolean;
    btnClass: string;
}

export const OptionsList: React.FC<IOptionsList> = ({
    options,
    changeValue,
    className = '',
    choosenOption,
    isVisible,
    close,
    btnClass,
}) => {
    useEffect(() => {
        function handleClickOutside(e: any) {
            if (
                !e.target.classList.contains(styels['search__list']) &&
                !e.target.classList.contains(btnClass) &&
                !e.target.classList.contains(styels['search__list-item'])
            ) {
                close();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <ul className={clsx(isVisible ? styels.visible : '', className, styels['search__list'])}>
            {options.map((el, index: number) => {
                return (
                    <li
                        className={clsx(
                            styels['search__list-item'],
                            choosenOption?.value === el.value &&
                                styels['search__list-item--choosen']
                        )}
                        key={index}
                        onClick={() => changeValue(el.value)}
                    >
                        {el.option}
                    </li>
                );
            })}
        </ul>
    );
};
