import { useNavigate } from 'react-router-dom';
import { APP__ROUTS } from '../consts';

export function useAppNavigation() {
    const navigate = useNavigate();
    const redirectToContest = () => {
        navigate(APP__ROUTS.contests);
    };

    const redirectToFeatsPage = () => {
        navigate(APP__ROUTS.feats);
    };

    const redirectToMainPage = () => {
        navigate(APP__ROUTS.main);
    };
    const redirectToPuzzle = () => {
        navigate(APP__ROUTS.puzzle);
    };
    const redirectToRegistration = () => {
        navigate(APP__ROUTS.contests + '/' + APP__ROUTS.registration, {
            replace: true,
        });
    };
    const redirectToLogin = () => {
        navigate(APP__ROUTS.contests + '/' + APP__ROUTS.login, {
            replace: true,
        });
    };
    const redirectToVote = () => {
        navigate(APP__ROUTS.imageVoting, {
            replace: true,
        });
    };

    const redirectToGalleryImage = () => {
        navigate(APP__ROUTS.gallery + '/' + APP__ROUTS.galleryWorks + '/' + 'изображения');
    };

    return {
        redirectToContest,
        redirectToFeatsPage,
        redirectToMainPage,
        redirectToPuzzle,
        redirectToRegistration,
        redirectToVote,
        redirectToGalleryImage,
        redirectToLogin,
    };
}
