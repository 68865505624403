import { ISvgIcon } from '../types/icons';

export const PluseIcon: React.FC<ISvgIcon> = ({ className, onClick }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
            className={className}
            onClick={onClick}
        >
            <circle cx="26" cy="26" r="26" fill="currentColor" width="100%" height="100%"/>
            <g opacity="0.5">
                <path d="M26 16V37" stroke="black" strokeWidth="2" fill="currentColor" />
                <path d="M37 26L16 26" stroke="black" strokeWidth="2" fill="currentColor" />
            </g>
        </svg>
    );
};
