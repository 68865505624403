import { ISvgIcon } from '../types/icons';

const RightArrowIcon: React.FC<ISvgIcon> = ({ className = '', onClick }) => {
    return (
        <svg
            onClick={onClick}
            role="img"
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="forward-step"
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512"
        >
            <path
                fill="currentColor"
                d="M52.5 440.6c-9.5 7.9-22.8 9.7-34.1 4.4S0 428.4 0 416V96C0 83.6 7.2 72.3 18.4 67s24.5-3.6 34.1 4.4l192 160L256 241V96c0-17.7 14.3-32 32-32s32 14.3 32 32V416c0 17.7-14.3 32-32 32s-32-14.3-32-32V271l-11.5 9.6-192 160z"
            ></path>
        </svg>
    );
};

export default RightArrowIcon;
