import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../shared/store';

interface AuthModalRoutingState {
    currentRoute: 'signIn' | 'resetPassword' | 'signUp';
    isActive: boolean;
}

const initialState: AuthModalRoutingState = {
    currentRoute: 'signIn',
    isActive: false,
};

export const authModalRoutingSlice = createSlice({
    name: 'authModalRouting',
    initialState,
    reducers: {
        changeModalVisibleStatus: (state) => {
            state.isActive = !state.isActive;
        },
        redirectToSignIn: (state) => {
            state.currentRoute = 'signIn';
        },
        redirectToSignUp: (state) => {
            state.currentRoute = 'signUp';
        },
        redirectToResetPass: (state) => {
            state.currentRoute = 'resetPassword';
        },
    },
});

export const getCurrentRote = (state: RootState) => state['authModalRouting'].currentRoute;
export const getModalStatus = (state: RootState) => state['authModalRouting'].isActive;

export const { changeModalVisibleStatus, redirectToSignIn, redirectToSignUp, redirectToResetPass } =
    authModalRoutingSlice.actions;
