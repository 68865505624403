import PagePreloader from '../../shared/ui/PagePreloader';
import { APP__ROUTS } from '../../shared/consts';
import { Suspense } from 'react';
import { lazyRetry } from '../../shared/utils/lazyRetry';

const GalleryPageLazy = lazyRetry(() => import('../../pages/GalleryPage/index'));
const GalleryPopUpLazy = lazyRetry(
    () => import('../../pages/GalleryPage/ui/GalleryPreloaderModal')
);
const GalleryWorksTypes = lazyRetry(() => import('../../pages/GalleryWorkTypesList/index'));
const GalleryWorks = lazyRetry(() => import('../../pages/GalleryWorks/index'));

export const GalleryRoutes = [
    {
        path: APP__ROUTS.gallery,
        element: (
            <Suspense fallback={<PagePreloader />}>
                <GalleryPageLazy />
            </Suspense>
        ),
        children: [
            {
                path: APP__ROUTS.galleryMediaItemId,
                element: (
                    <Suspense>
                        <GalleryPopUpLazy />
                    </Suspense>
                ),
            },
            {
                index: true,
                element: (
                    <Suspense>
                        <GalleryWorksTypes />
                    </Suspense>
                ),
            },
            {
                path: APP__ROUTS.galleryWorks + '/:' + APP__ROUTS.galleryWorksType,
                element: (
                    <Suspense>
                        <GalleryWorks />
                    </Suspense>
                ),
            },
        ],
    },
];
