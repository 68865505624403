import { ReactNode } from 'react';
import styles from './index.module.scss';
import { createPortal } from 'react-dom';

interface IMobileNavLayoutProps {
    children: ReactNode;
}

const MobileNavLayout: React.FC<IMobileNavLayoutProps> = ({ children }) => {
    const bottomContainer = document.getElementById('bottom-container')!;

    return createPortal(
        <div className={styles['mobile-nav__container']}>
            <nav className={styles['mobile-nav']}>{children} </nav>
        </div>,
        bottomContainer
    );
};

export default MobileNavLayout;
