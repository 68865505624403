import { BASE__API__URL } from '../../../../shared/consts';
import { IGalleryItem } from '../../../../entities/gallery';

export async function createGalleryItem(formData: any): Promise<IGalleryItem | null> {
    try {
        const response = await fetch(`${BASE__API__URL}gallery`, {
            method: 'POST',
            body: formData,
            headers: {
                credentials: 'include',
            },
        });
        if (response.ok) {
            return await response.json();
        }

        return null;
    } catch (err) {
        throw new Error();
    }
}

export async function linkGalleryItemWithContest(
    contestId: number,
    galleryItemId: number
): Promise<boolean> {
    try {
        const response = await fetch(`${BASE__API__URL}gallery_contests`, {
            method: 'POST',
            body: JSON.stringify({
                contest_id: contestId,
                media_id: galleryItemId,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (response.ok) {
            return true;
        }

        return false;
    } catch (err) {
        throw new Error();
    }
}
