import styles from './index.module.scss';
import topCloud from './assets/topCloud.webp';
import bottomCloud from './assets/bottomCloud.webp';


const CloudsBackground = () => {
    return (
        <div className={styles['clouds-bg']}>
            <img className={styles['clouds-bg__top']} src={topCloud} alt="Облако дыма" />
            <img className={styles['clouds-bg__bottom']} src={bottomCloud} alt="Облако дыма" />
        </div>
    );
};

export default CloudsBackground;
