import React from 'react';
import { ISvgIcon } from '../../../shared/types/icons';

export const EarphoneIcon: React.FC<ISvgIcon & { bg?: boolean }> = ({
    className,
    onClick,
    bg = true,
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 40 40"
            fill="none"
            className={className}
            onClick={onClick}
        >
            {bg && (
                <>
                    <rect width="40" height="40" rx="20" fill="#F04D19" />
                    <rect width="40" height="40" rx="20" fill="url(#paint0_linear_3741_7062)" />
                </>
            )}
            <path
                d="M24.1673 20.834H27.5007V25.834C27.5007 26.2942 27.1276 26.6673 26.6673 26.6673H24.1673C23.7071 26.6673 23.334 26.2942 23.334 25.834V21.6673C23.334 21.2071 23.7071 20.834 24.1673 20.834Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M15.8333 20.834H12.5V25.834C12.5 26.2942 12.8731 26.6673 13.3333 26.6673H15.8333C16.2936 26.6673 16.6667 26.2942 16.6667 25.834V21.6673C16.6667 21.2071 16.2936 20.834 15.8333 20.834Z"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.5 20.834C12.5 16.6918 15.8579 13.334 20 13.334C24.1421 13.334 27.5 16.6918 27.5 20.834"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_3741_7062"
                    x1="2.76146"
                    y1="31.7343"
                    x2="35.1284"
                    y2="31.7343"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F04D19" />
                    <stop offset="1" stopColor="#F17F5B" />
                </linearGradient>
            </defs>
        </svg>
    );
};
