import React from 'react';
import { ISvgIcon } from '../../../shared/types/icons';

interface IViewIcon extends ISvgIcon {
    variant?: 0 | 1;
}

const ViewIcon: React.FC<IViewIcon> = ({ className, onClick, variant = 0 }) => {
    return (
        <>
            {variant === 0 ? (
                <svg
                    role="img"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="far"
                    data-icon="eye"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 576 512"
                    onClick={onClick}
                >
                    <path
                        fill="currentColor"
                        d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z"
                    ></path>
                </svg>
            ) : (
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 25 24"
                    fill="none"
                    onClick={onClick}
                    className={className}
                >
                    <path
                        d="M12.5 18C18.0228 18 22.5 12 22.5 12C22.5 12 18.0228 6 12.5 6C6.97715 6 2.5 12 2.5 12C2.5 12 6.97715 18 12.5 18Z"
                        stroke="#ADB3B9"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M12.5 14C13.6046 14 14.5 13.1046 14.5 12C14.5 10.8954 13.6046 10 12.5 10C11.3954 10 10.5 10.8954 10.5 12C10.5 13.1046 11.3954 14 12.5 14Z"
                        stroke="#ADB3B9"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            )}
        </>
    );
};

export default ViewIcon;
