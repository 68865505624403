export function getDayMothYearDateFormat(value: string) {
    return new Date(value).toLocaleDateString('ru-RU', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
    });
}

export function getYearMothDayFormat(value: Date | undefined) {
    if (value) {
        const result =
            value.getFullYear() +
            '-' +
            (value.getMonth() + 1).toString().padStart(2, '0') +
            '-' +
            value.getDate().toString().padStart(2, '0');
        return result;
    } else {
        return '';
    }
}

export function subtractYears(date: Date, years: number) {
    date.setFullYear(date.getFullYear() - years);
    return getYearMothDayFormat(date);
}
