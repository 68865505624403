import { useState } from 'react';

export function useFeatCardDetailAduioPlayer() {
    const [{ isPlaying, isActive }, setPlayerState] = useState({
        isPlaying: false,
        isActive: false,
    });

    const togglePlayPause = () => {
        setPlayerState((prevState) => {
            return {
                ...prevState,
                isPlaying: !prevState.isPlaying,
                isActive: true,
            };
        });
    };

    const stopAudio = () => {
        setPlayerState((prevState) => {
            return {
                ...prevState,
                isPlaying: false,
            };
        });
    };

    const closeAudioPlayer = () => {
        setPlayerState((prevState) => {
            return {
                ...prevState,
                isActive: false,
                isPlaying: false,
            };
        });
    };

    return {
        isPlaying,
        isActive,
        togglePlayPause,
        stopAudio,
        closeAudioPlayer,
    };
}
