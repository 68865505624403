import styels from './index.module.scss';
import PauseIcon from '../../../../shared/assets/PauseIcon';
import PlayIcon from '../../../../shared/assets/PlayIcon';
import CrossIcon from '../../../../shared/assets/CrossIcon';

interface Controls {
    isPlaying: boolean;
    togglePlayPause: () => void;
    closePlayer: () => void;
}

export const Controls: React.FC<Controls> = ({ isPlaying, togglePlayPause, closePlayer }) => {
    return (
        <div className={styels['audioplayer__controls-container']}>
            <div className={styels['audioplayer__controls']}>
                {isPlaying ? (
                    <PauseIcon className={styels['audioplayer__svg']} onClick={togglePlayPause} />
                ) : (
                    <PlayIcon className={styels['audioplayer__svg']} onClick={togglePlayPause} />
                )}
            </div>
            <div className={styels['audioplayer__playlist-container']}></div>
            <CrossIcon className={styels['audioplayer__svg']} onClick={closePlayer} />
        </div>
    );
};
