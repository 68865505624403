import { useForm, SubmitHandler } from 'react-hook-form';
import { validateMail } from '../../../../shared/utils/validators';
import { MAIL__INPUT, PASSWORD__INPUT } from './consts';
import { IResendAuthMesgForm } from './types';
import { resendAuthMesgRequest } from './api';
import { getSignInDto } from './utils';
import { changeAuthStatus, fetchUserData } from '../../../../entities/session';
import { useAppDispatch } from '../../../../shared/store';
import { createAlert, createAlertOptions } from '../../../../entities/alerts';
import { useState } from 'react';
import { useCreateModalState } from '../../../../shared/hooks/modal';

export function useResendMesgFormState() {
    const [passwordInputType, setType] = useState<'password' | 'text'>('password');
    const { toggleModalStatus, isModalOpen, closeModal } = useCreateModalState();
    const {
        register,
        handleSubmit,
        watch,
        reset,
        resetField,
        formState: { errors },
    } = useForm<IResendAuthMesgForm>({
        mode: 'onTouched',
        defaultValues: {
            mail: '',
            password: '',
        },
    });
    const watchMail = watch(MAIL__INPUT);
    const watchPassword = watch(PASSWORD__INPUT);
    const dispatch = useAppDispatch();
    const submit: SubmitHandler<IResendAuthMesgForm> = async (data) => {
        const response = await resendAuthMesgRequest(getSignInDto(data));
        if (response) {
            if (response.status === 200) {
                closeModal();
                dispatch(changeAuthStatus(true));
                dispatch(fetchUserData());
                dispatch(
                    createAlert(
                        createAlertOptions(
                            'письмо было отправлено на указанную почту!',
                            'Благодарим за участие в проекте Подвиг.РФ'
                        )
                    )
                );
            } else {
                resetField(PASSWORD__INPUT);
                dispatch(
                    createAlert(
                        createAlertOptions(
                            'Что-то пошло не-так',
                            'Пожалуйста, попробуйте еще раз или обратитесь в службу поддержки Подвиг.РФ для получения помощи.',
                            'middle'
                        )
                    )
                );
            }
        }
    };

    const mailRegister = register(MAIL__INPUT, {
        required: 'Поле обязательно',
        validate: {
            validateMail: (v) => validateMail(v) || 'Введите email, указанный при регистрации.',
        },
        maxLength: {
            value: 100,
            message: 'превышено допустимое количество символов',
        },
    });

    const mailErrorText = errors?.mail && errors?.mail?.message;

    const passwordRegister = register(PASSWORD__INPUT, {
        required: 'Поле обязательно',
        maxLength: {
            value: 100,
            message: 'превышено допустимое количество символов',
        },
    });

    const passwordErrorText = errors?.password && errors?.password?.message;

    return {
        handleSubmit: handleSubmit(submit),
        watchMail,
        watchPassword,
        mailRegister,
        passwordRegister,
        mailErrorText,
        passwordErrorText,
        passwordInputType,
        setType,
        toggleModalStatus,
        isModalOpen,
    };
}
