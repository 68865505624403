import React from 'react';
import CircledProfileIcon from '../../../../shared/assets/CircledProfileIcon';
import styles from './index.module.scss';
import { useAppSelector } from '../../../../shared/store';
import { getUserData } from '../../../../entities/session';

export const ProfileHeaderIcon = () => {
    const userData = useAppSelector(getUserData);
    return (
        <div className={styles['profile']}>
            <span className={styles['profile__name']}>
                {userData?.fio.split(' ').slice(0, 2).join(' ')}
            </span>
            <CircledProfileIcon className={styles['profile__icon']} />
        </div>
    );
};
