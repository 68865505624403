import styels from './index.module.scss';
import { BASE__MEDIA__URL } from '../../../../shared/consts';
import { IFeat } from '../../../feat';
import { createPortal } from 'react-dom';
import { forwardRef } from 'react';

interface IAudioPlayer {
    progressBar: any;
    controls: any;
    currentAudio: IFeat;
    onLoaded: any;
    onEnded: () => void;
    onTimeUpdate: (e: any) => void;
    onDurationChange: (e: any) => void;
    onCanPlay: () => void;
    isReady: boolean;
}

export const Display = forwardRef<HTMLAudioElement, IAudioPlayer>(function Display(
    {
        progressBar,
        controls,
        currentAudio,
        onLoaded,
        onEnded,
        onTimeUpdate,
        onDurationChange,
        onCanPlay,
        isReady,
    },
    ref
) {
    const bottomContainer = document.getElementById('bottom-container')!;

    return createPortal(
        <div className={styels['audioplayer']}>
            <div className={styels['audioplayer__header']}>
                <section className={styels['audioplayer__audio-info']}>
                    <h2 className={styels['audioplayer__audio-title']}>{currentAudio.name}</h2>
                    <span className={styels['audioplayer__audio-hero']}>{currentAudio.hero}</span>
                </section>
                {controls}
            </div>
            <audio
                onEnded={() => {
                    onEnded();
                }}
                src={BASE__MEDIA__URL + currentAudio.audio_url}
                ref={ref}
                preload="metadata"
                onLoadedData={onLoaded}
                onTimeUpdate={onTimeUpdate}
                onDurationChange={onDurationChange}
                onCanPlay={onCanPlay}
            ></audio>
            {progressBar}
            {!isReady && <div className={styels['audioplayer__preloader']}></div>}
        </div>,
        bottomContainer
    );
});
