export type option = { value: number; option: string };
export type optionsListType = option[];

export function createOption(option: string, value: number) {
    return {
        option,
        value,
    };
}

export function createFormData(data: Record<string, any>, formData: any) {
    
    for (const key in data) {
        if (data[key]) {
            if (data[key] instanceof FileList) {
                if (data[key].length) {
                    for (const file of data[key]) {
                        formData.append(key, file);
                    }
                }
            } else if (data[key] instanceof Array) {
                data[key].forEach((elem: any) => {
                    formData.append(key, elem);
                });
            } else {
                formData.append(key, data[key]);
            }
        }
    }
}
