export function validateMail(value: string) {
    const mailFormat = /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,10})+$/;
    if (value.match(mailFormat)) {
        return true;
    } else {
        return false;
    }
}

export function validateFullName(value: string) {
    return /^([а-яё]+(?:[ -][а-яё]+)*[ -]?|)$/i.test(value);
}

export function validatePassword(value: string) {
    return /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{8,}$/.test(value);
}
