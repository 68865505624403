import styels from './index.module.scss';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { APP__ROUTS } from '../../../../shared/consts';
import CircledProfileIcon from '../../../../shared/assets/CircledProfileIcon';
import AdminPanelIcon from '../../../../shared/assets/AdminPanelIcon';
import { useLocation } from 'react-router-dom';
import HomeIcon from '../../../MobileNav/assets/HomeIcon';
import { getUserData } from '../../../../entities/session';
import { useAppSelector } from '../../../../shared/store';

const ProfileHeaderNav = () => {
    const userData = useAppSelector(getUserData);

    return (
        <nav className={styels['profile-header__nav']}>
            <ul className={styels['profile-header__nav-list']}>
                <li className={styels['profile-header__nav-list-item']}>
                    <NavLink
                        className={({ isActive }) =>
                            clsx(isActive && styels['profile-header__nav-link--active'])
                        }
                        to={APP__ROUTS.profileLayout}
                        end
                    >
                        <span className={styels['profile-header__nav-text-link']}>Мой профиль</span>
                        <CircledProfileIcon className={styels['profile-header__nav-icon']} />
                    </NavLink>
                </li>
                {userData?.is_admin && (
                    <>
                        <li className={styels['profile-header__nav-list-item']}>
                            <NavLink
                                className={({ isActive }) =>
                                    clsx(
                                        isActive &&
                                            styels['profile-header__nav-link--active-admin-panel']
                                    )
                                }
                                to={APP__ROUTS.adminLayout}
                            >
                                <span className={styels['profile-header__nav-text-link']}>
                                    Админ панель
                                </span>
                                <AdminPanelIcon className={styels['profile-header__nav-icon']} />
                            </NavLink>
                        </li>
                    </>
                )}
                <li className={styels['profile-header__nav-list-item']}>
                    <NavLink
                        className={({ isActive }) =>
                            clsx(isActive && styels['profile-header__nav-link--active-admin-panel'])
                        }
                        to={APP__ROUTS.main}
                    >
                        <span className={styels['profile-header__nav-text-link']}>На главную</span>
                        <HomeIcon className={styels['profile-header__nav-icon']} />
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
};

export default ProfileHeaderNav;
