import React from 'react';
import styels from './index.module.scss';
import { BASE__MEDIA__URL } from '../../../../shared/consts';
import { getDayMothYearDateFormat } from '../../../../shared/utils/date';
import { useState } from 'react';
import { ReactNode } from 'react';
import clsx from 'clsx';
import ErrorImg from '../../../../shared/assets/ErrorImg';

interface IAdminFeatPreview {
    title: string;
    date: string;
    hero: string;
    img: string;
    toggleModalStatus: () => void;
    editAction: ReactNode;
    deleteAction: ReactNode;
    publishAction: ReactNode;
}

export const AdminFeatPreview: React.FC<IAdminFeatPreview> = ({
    title,
    hero,
    img,
    date,
    toggleModalStatus,
    editAction,
    deleteAction,
    publishAction,
}) => {
    const [isImageCorrect, setCorrectStatus] = useState(true);
    const formatDate = getDayMothYearDateFormat(date);

    const openFeatDetails = () => {
        toggleModalStatus();
    };

    return (
        <div className={styels['admin-feat-card__container']}>
            <article className={styels['admin-feat-card']}>
                <div
                    onClick={openFeatDetails}
                    className={clsx(
                        styels['admin-feat-card__img-container'],
                        styels['admin-feat-card__preview']
                    )}
                >
                    {isImageCorrect ? (
                        <img
                            src={`${BASE__MEDIA__URL}${img}`}
                            alt={title}
                            className={clsx(styels['admin-feat-card__img'])}
                            onError={() => setCorrectStatus(false)}
                        />
                    ) : (
                        <ErrorImg className={clsx(styels['admin-feat-card__img'])} />
                    )}
                </div>
                <h2 className={styels['admin-feat-card__title']} title={`${title}`}>
                    {title}
                </h2>

                <span className={styels['admin-feat-card__date']}>
                    Дата публикации: {formatDate}
                </span>
                <div className={styels['admin-feat-card__actions']}>
                    {editAction}
                    {deleteAction}
                    {publishAction}
                </div>
            </article>
        </div>
    );
};

export default AdminFeatPreview;
