import React from 'react';
import Modal from '../Modal';
import { useCreateModalState } from '../../hooks/modal';
import ViewIcon from '../../../entities/feat/assets/ViewIcon';
import styles from './index.module.scss';
import clsx from 'clsx';

interface ITextArea {
    className?: string;
    register: any;
    text: string;
    title?: string;
    titleClassName?: string;
    errorText?: string;
}

export const TextArea: React.FC<ITextArea> = ({
    className = '',
    register,
    text,
    title,
    titleClassName,
    errorText,
}) => {
    const { isModalOpen, toggleModalStatus } = useCreateModalState();
    return (
        <>
            <div className={clsx(styles['text-area__container'], className)}>
                <span className={clsx(styles['text-area__title'], titleClassName)}>{title}</span>
                <textarea {...register} className={styles['text-area']}></textarea>
                <div title="предпросмотр">
                    <ViewIcon
                        className={clsx(styles['text-area__icon'])}
                        onClick={toggleModalStatus}
                    />
                </div>
                <span className={styles['text-area__error-text']}>{errorText}</span>
            </div>
            {isModalOpen && (
                <Modal
                    isOpen={isModalOpen}
                    setStatus={toggleModalStatus}
                    className={styles['text-area__textarea-modal']}
                >
                    <textarea
                        id="text"
                        className={styles['text-area__textarea']}
                        {...register}
                    ></textarea>
                </Modal>
            )}
        </>
    );
};
