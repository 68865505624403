import Modal from '../../../../shared/ui/Modal';
import { ContestModal } from '../../../../widgets/ContestModal';
import { useSearchParams } from 'react-router-dom';

export const GlobalModal = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    return (
        <Modal
            isOpen={searchParams.get('showModal') === 'contest'}
            setStatus={() => {
                setSearchParams('');
            }}
        >
            <ContestModal />
        </Modal>
    );
};
