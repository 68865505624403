import PreLoader from '../PreLoader';
import styels from './index.module.scss';
import clsx from 'clsx';

interface IPagePreloader {
    variant?: 'primary' | 'admin';
}

const PagePreloader: React.FC<IPagePreloader> = ({ variant = 'primary' }) => {
    return (
        <PreLoader
            className={clsx(
                {
                    primary: styels['page-preloader--primary'],
                    admin: styels['page-preloader--admin'],
                }[variant]
            )}
        />
    );
};

export default PagePreloader;
