import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../shared/store';
import { PayloadAction } from '@reduxjs/toolkit';
import { createId } from '../../../shared/utils/uuid';

export interface IAlert {
    text: string;
    title: string;
    id: string;
    position: 'bottom' | 'middle' | undefined;
}
export type CreatedAlert = Omit<IAlert, 'id'>;

interface AlertsState {
    activeAlerts: IAlert[];
}

const initialState: AlertsState = {
    activeAlerts: [],
};

export const alertsSlice = createSlice({
    name: 'alerts',
    initialState,
    reducers: {
        createAlert: (state, action: PayloadAction<CreatedAlert>) => {
            state.activeAlerts.push({
                text: action.payload.text,
                title: action.payload.title,
                id: createId(),
                position: action.payload.position ? action.payload.position : 'bottom',
            });
        },
        deleteAlert: (state, action: PayloadAction<string>) => {
            state.activeAlerts = state.activeAlerts.filter((alert) => alert.id !== action.payload);
        },
    },
});

export const getAlerts = (state: RootState) => state['alerts'].activeAlerts;

export const { createAlert, deleteAlert } = alertsSlice.actions;
