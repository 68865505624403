import { ISignUpForm } from './types';

export const INITIAL__FROM__STATE: ISignUpForm = {
    name: '',
    mail: '',
    location: '',
    password: '',
    confirmPassword: '',
    birthday: '',
    privacyPolicy: false,
    personalData: false,
};

export const NAME__INPUT = 'name';

export const MAIL__INPUT = 'mail';

export const LOCATION__INPUT = 'location';

export const PASSWORD__INPUT = 'password';

export const CONFIRM__PASSWORD__INPUT = 'confirmPassword';

export const BIRTHDAY__INPUT = 'birthday';

export const PRIVACY__POLICY__INPUT = 'privacyPolicy';

export const PERSONAL__DATA__INPUT = 'personalData';
