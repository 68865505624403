import styels from './index.module.scss';
import clsx from 'clsx';
import React, { useRef, useEffect } from 'react';

interface IAudioProgressBar {
    handleProgressChange: any;
    className?: string;
    value?: number;
    max: number;
}

export const AudioProgressBar: React.FC<IAudioProgressBar> = ({
    handleProgressChange,
    className = '',
    value,
    max,
}) => {
    const progressBarRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        try {
            const interval = setInterval(() => {
                if (progressBarRef.current) {
                    const durationPercent = Math.ceil((Number(value) / max) * 100);

                    progressBarRef.current.style.setProperty(
                        '--range-progress',
                        `${durationPercent}%`
                    );
                }
            }, 300);
            return () => {
                clearInterval(interval);
            };
        } catch (err) {
            console.log(err);
        }
    }, [value, max]);
    return (
        <div className={styels['audioplayer__progress-container']}>
            <input
                className={clsx(styels['audioplayer__progress'], className)}
                type="range"
                ref={progressBarRef}
                onChange={handleProgressChange}
                value={value}
                max={max}
            />
        </div>
    );
};
