import Button from '../Button';
import styels from './index.module.scss';
import clsx from 'clsx';
import ShareIcon from '../../assets/ShareIcon';
import VkLogo from '../../assets/VkLogo';
import { ArrowIcon } from './assets/ArrowIcon';

interface IShareButton {
    shareListClasses?: string;
    shareIconOnClick?: () => void;
    shareVkOnClick?: () => void;
    className?: string;
    type?: 'orange-gradient' | 'primary' | 'transparent' | 'arrow-btn';
    hreafArrt?: Record<string, string | boolean>;
}

const ShareButton: React.FC<IShareButton> = ({
    shareListClasses = '',
    shareIconOnClick,
    shareVkOnClick,
    className = '',
    type = 'primary',
    hreafArrt = {},
}) => {
    return (
        <div className={styels['share-btn__container']} onClick={(e) => e.stopPropagation()}>
            {type === 'arrow-btn' ? (
                <div className={styels['share-btn__arrow-btn']}>
                    <ArrowIcon /> <div>Поделиться</div>
                </div>
            ) : (
                <Button
                    text="Поделиться"
                    type="button"
                    variant={type}
                    className={clsx(styels['share-btn'], className)}
                />
            )}
            <ul className={clsx(shareListClasses, styels['share-btn__icons-list'])}>
                <li>
                    <a href="#" {...hreafArrt}>
                        <ShareIcon
                            className={styels['share-btn__icon']}
                            onClick={shareIconOnClick}
                        />
                    </a>
                </li>
                <li>
                    <VkLogo className={styels['share-btn__icon']} onClick={shareVkOnClick} />
                </li>
            </ul>
        </div>
    );
};

export default ShareButton;
