import styles from './index.module.scss';
import React, { useState } from 'react';
import { IInputProps } from '../Input';
import clsx from 'clsx';

export type optionsList = { value: string | number; option: string }[];

interface ISearchbarProps extends IInputProps {
    options: optionsList;
    changeValue: (value: string | number) => void;
}

const Searchbar: React.FC<ISearchbarProps> = ({
    options,
    title,
    errorText,
    isEmpty,
    type,
    register,
    className = '',
    changeValue,
    titleClassName,
}) => {
    const [isFocus, setFocus] = useState(false);
    const [filterValue, setFilterValue] = useState<optionsList>([]);
    const handleSearch = (event: any) => {
        const searchValue = event.target.value;
        const filterArray = options.filter((e) => {
            return e.option.toLowerCase().includes(searchValue.toLowerCase());
        });
        if (searchValue === '') {
            setFilterValue([]);
        } else {
            setFilterValue(filterArray);
        }
        changeValue(searchValue);
    };

    const handlChooseValue = (e: any, value: any) => {
        const currentFocusEl = !document.activeElement;
        const searchInput = !document.querySelector(`.${styles['searchbar__input']}`);
        if (currentFocusEl === searchInput) {
            e.preventDefault();
            changeValue(value);
        }
    };

    return (
        <div
            className={clsx(className, styles['searchbar'])}
            onFocus={() => {
                setFocus(true);
            }}
            onBlur={() => setFocus(false)}
        >
            <label htmlFor="searchbar__input">
                <span className={clsx(styles['searchbar__input__title'], titleClassName)}>
                    {title}
                </span>
                <input
                    className={clsx(
                        styles['searchbar__input'],
                        errorText && styles['searchbar__input--invalid'],
                        !errorText && !isEmpty && styles['searchbar__input--valid']
                    )}
                    type={type}
                    id="input"
                    {...register}
                    onInput={handleSearch}
                />
                <span className={styles['searchbar__input__error-text']}>{errorText}</span>
            </label>

            <ul
                className={clsx(
                    styles['searchbar__list'],
                    !isFocus && styles['searchbar__list--closed']
                )}
            >
                {filterValue.length !== 0 &&
                    filterValue.map((el: any, index: number) => {
                        return (
                            <li
                                className={styles['searchbar__list-item']}
                                key={index}
                                onMouseDown={(e) => {
                                    handlChooseValue(e, el.value);
                                }}
                            >
                                {el.value}
                            </li>
                        );
                    })}
            </ul>
        </div>
    );
};
export default Searchbar;
