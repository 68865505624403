import styels from './index.module.scss';
import { ADMIN__NAVLINKS__DATA } from './consts';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

const AdminNavPanel = () => {
    return (
        <div className={styels['admin-page__nav-container']}>
            <nav className={styels['admin-page__nav']}>
                <ul className={styels['admin-page__nav-list']}>
                    {ADMIN__NAVLINKS__DATA.map((linkData) => {
                        return (
                            <li key={linkData.name} className={styels['admin-page__nav-list-item']}>
                                <NavLink
                                    end
                                    className={({ isActive }) =>
                                        clsx(isActive && styels['admin-page__nav-link--active'])
                                    }
                                    to={linkData.path}
                                >
                                    {linkData.name}
                                </NavLink>
                            </li>
                        );
                    })}
                </ul>
            </nav>
        </div>
    );
};

export default AdminNavPanel;
