import { APP__ROUTS } from '../../shared/consts';
import { Suspense } from 'react';
import { lazyRetry } from '../../shared/utils/lazyRetry';
import PagePreloader from '../../shared/ui/PagePreloader';
import ContestBanner from '../../widgets/ContestBanner';

const ContestPageLazy = lazyRetry(() => import('../../pages/ContestsPage/index'));
const ContestSubmitForm = lazyRetry(() => import('../../pages/ContestSubmitForm/index'));

export const ContestRoutes = [
    {
        path: APP__ROUTS.contests,
        element: (
            <Suspense fallback={<PagePreloader />}>
                <ContestPageLazy />
            </Suspense>
        ),
        children: [
            {
                path: APP__ROUTS.registration,
                element: <></>,
            },
            {
                path: APP__ROUTS.login,
                element: <></>,
                
            },
            {
                path: APP__ROUTS.banner,
                element: <ContestBanner isAlwaysActive={true} />,
            },
            {
                path: APP__ROUTS.contetSubmitForm,
                element: <ContestSubmitForm />,
            },
        ],
    },
];
