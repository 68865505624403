import React from 'react';
import { IFeat } from '../../model/types';
import styles from './index.module.scss';
import { BASE__MEDIA__URL } from '../../../../shared/consts';
import ProfileIcon from '../../../../shared/assets/ProfileIcon';
import { getDayMothYearDateFormat } from '../../../../shared/utils/date';
import PlayIcon from '../../../../shared/assets/PlayIcon';

interface IFeatMediaExample {
    feat: IFeat;
}

export const FeatMediaExample: React.FC<IFeatMediaExample> = ({ feat }) => {
    return (
        <div className={styles['feat-example__container']}>
            <PlayIcon className={styles['feat-example__play-icon']} />
            <div className={styles['feat-example__img-container']}>
                <img
                    src={BASE__MEDIA__URL + feat.image_url}
                    className={styles['feat-example__img']}
                    alt={feat.name}
                ></img>
            </div>
            <h2 className={styles['feat-example__title']}>{feat.name}</h2>
            <div className={styles['feat-example__detail-container']}>
                {/* <ProfileIcon className={styles['feat-example__icon']} /> */}
                <div className={styles['feat-example__detail']}>
                    <span className={styles['feat-example__hero']}>{feat.hero}</span>
                    <time className={styles['feat-example__date']}>
                        {getDayMothYearDateFormat((feat.publish_date))}
                    </time>
                </div>
            </div>
            <span className={styles['feat-example__description']}>{feat.description}</span>
        </div>
    );
};
