import styles from './index.module.scss';
import { createPortal } from 'react-dom';
import { useEffect } from 'react';
import clsx from 'clsx';

export interface IModalProps {
    isOpen: boolean;
    setStatus?: () => void;
    children: React.ReactNode;
    className?: string;
    modalClassName?: string;
    additionalElements?: React.ReactNode;
}

const Modal: React.FC<IModalProps> = ({
    isOpen,
    setStatus,
    children,
    className,
    modalClassName,
    additionalElements,
}) => {
    const modalContainer = document.getElementById('modal')!;
    const changeStatus = (e: React.MouseEvent<HTMLElement>) => {
        const target = e.target as Element;
        if (target.classList.contains(styles.modal)) {
            setStatus && setStatus();
        }
    };

    useEffect(() => {
        isOpen
            ? document.body.classList.add(styles['overflow-hidden'])
            : document.body.classList.remove(styles['overflow-hidden']);
        return () => {
            document.body.classList.remove(styles['overflow-hidden']);
        };
    }, [isOpen]);

    return createPortal(
        isOpen?<dialog
            onClick={changeStatus}
            className={clsx(
                isOpen ? `${styles.modal} ${styles.active}` : `${styles.modal}`,
                className
            )}
        >
            <div className={styles['modal__content-container']}>
                <div
                    className={clsx(`${styles.modal__content}`, modalClassName)}
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                >
                    {isOpen && children}
                </div>
                {additionalElements}
            </div>
        </dialog>: <></>,
        modalContainer
    );
};

export default Modal;
