import React from 'react';
import Modal from '../../../../shared/ui/Modal';
import { useCreateModalState } from '../../../../shared/hooks/modal';
import CrossIcon from '../../../../shared/assets/CrossIcon';
import styles from './index.module.scss';
import clsx from 'clsx';

interface IMiddleAlert {
    title: string;
    text: string;
    onClick: () => void;
}

export const MiddleAlert: React.FC<IMiddleAlert> = ({ title, text, onClick }) => {
    const { isModalOpen, toggleModalStatus } = useCreateModalState(true);

    return (
        <Modal
            isOpen={isModalOpen}
            setStatus={() => {
                toggleModalStatus();
                onClick();
            }}
            className={styles['alert__modal']}
        >
            <div className={clsx(styles['alert'], styles['active'])}>
                <CrossIcon className={styles['alert__icon']} onClick={onClick} />
                <h2 className={styles['alert__title']}>{title}</h2>
                <span className={styles['alert__text']}>{text}</span>
            </div>
        </Modal>
    );
};
