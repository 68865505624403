import React, { useState } from 'react';
import styles from './AuthorInfo.module.scss';
import clsx from 'clsx';
import { BASE__MEDIA__URL } from '../../consts';

interface IAuthorInfo {
    author: string | null;
    publishDateDMY: string;
    className?: string;
    src: string | null;
}

export const AuthorInfo: React.FC<IAuthorInfo> = ({
    author,
    publishDateDMY,
    className = '',
    src,
}) => {
    const [isImgErr, setImgErrStatus] = useState(false);

    return (
        <div className={clsx(styles['avatar_detail'], className)}>
            {!isImgErr ? (
                <img
                    className={styles['avatar_detail_avatar']}
                    src={BASE__MEDIA__URL + src}
                    alt="аватар"
                    onError={() => {
                        setImgErrStatus(true);
                    }}
                />
            ) : (
                <div className={styles['avatar_detail_avatar_not_found']}></div>
            )}

            <div className={styles['avatar_detail_date']}>
                {!!author && (
                    <span
                        className={styles['avatar_detail_author']}
                        title={`${author}`}
                    >{`${author}`}</span>
                )}
                <span className={styles['avatar_detail_publish_date']} title={publishDateDMY}>
                    {publishDateDMY}
                </span>
            </div>
        </div>
    );
};
