import styels from './index.module.scss';
import Logo from '../../../../shared/ui/Logo';
import ProfileHeaderNav from '../ProfileHeaderNav';
import { useLocation } from 'react-router-dom';
import { APP__ROUTS } from '../../../../shared/consts';
import { ProfileHeaderIcon } from '../ProfileHeaderIcon';

export const ProfileHeader = () => {
    const location = useLocation();
    const isAdminLocation = location.pathname.includes(APP__ROUTS.adminLayout);

    return (
        <header className={styels['profile-header']}>
            <div className={styels['profile-header__container']}>
                <Logo
                    path={APP__ROUTS.main}
                    className={isAdminLocation ? styels['profile-header__logo'] : ''}
                />
                <ProfileHeaderNav />
                <ProfileHeaderIcon />
            </div>
        </header>
    );
};
