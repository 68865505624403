import React from 'react';

export const WhiteVkLogo = ({ className = '' }) => {
    return (
        <svg
            className={className}
            width="18"
            height="19"
            viewBox="0 0 18 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.3636 0.5H1.63636C0.732273 0.5 0 1.23227 0 2.13636V16.8636C0 17.7677 0.732273 18.5 1.63636 18.5H16.3636C17.2677 18.5 18 17.7677 18 16.8636V2.13636C18 1.23227 17.2677 0.5 16.3636 0.5ZM14.2045 12.7457L12.6761 12.767C12.6761 12.767 12.3464 12.8316 11.9152 12.5338C11.3433 12.1411 10.8041 11.1192 10.3835 11.2517C9.95727 11.3875 9.97036 12.3072 9.97036 12.3072C9.97036 12.3072 9.97364 12.4692 9.87627 12.5747C9.77073 12.6885 9.56373 12.677 9.56373 12.677H8.87891C8.87891 12.677 7.36855 12.8022 6.03736 11.4145C4.58755 9.90173 3.30627 6.91618 3.30627 6.91618C3.30627 6.91618 3.231 6.728 3.31118 6.63146C3.402 6.52509 3.64664 6.52182 3.64664 6.52182L5.283 6.51364C5.283 6.51364 5.43682 6.54064 5.54809 6.62245C5.63891 6.68955 5.69045 6.81555 5.69045 6.81555C5.69045 6.81555 5.95473 7.48564 6.30573 8.09273C6.98973 9.27582 7.30718 9.53518 7.53955 9.40836C7.87827 9.22345 7.77682 7.736 7.77682 7.736C7.77682 7.736 7.78336 7.196 7.60664 6.95546C7.46918 6.76809 7.21064 6.71245 7.09773 6.69855C7.00527 6.68627 7.15582 6.47191 7.35218 6.37536C7.64673 6.23136 8.16709 6.22318 8.78155 6.22891C9.26018 6.23382 9.39845 6.26327 9.585 6.30909C10.1495 6.44573 9.95809 6.97264 9.95809 8.23673C9.95809 8.64173 9.88527 9.21118 10.1765 9.39855C10.3025 9.47955 10.6094 9.41082 11.3744 8.10664C11.7385 7.48809 12.0109 6.76155 12.0109 6.76155C12.0109 6.76155 12.0706 6.63227 12.1631 6.57664C12.258 6.51936 12.3856 6.53736 12.3856 6.53736L14.1079 6.52673C14.1079 6.52673 14.625 6.46455 14.7093 6.69936C14.7976 6.94482 14.5162 7.51836 13.8125 8.45682C12.6573 9.99827 12.5288 9.85509 13.4885 10.7469C14.4049 11.5986 14.5939 12.0126 14.6258 12.065C15.0046 12.6958 14.2045 12.7457 14.2045 12.7457Z"
                fill="white"
            />
        </svg>
    );
};
