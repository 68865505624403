import { IFeat } from './types';
import { Params, ParamParseKey, ActionFunctionArgs } from 'react-router-dom';
import { APP__ROUTS } from '../../../shared/consts';
import { getFeatById } from './api';

const Paths = {
    featsDetail: APP__ROUTS.feats,
} as const;

interface FeatLoaderArgs extends ActionFunctionArgs {
    params: Params<ParamParseKey<typeof Paths.featsDetail>>;
}

export async function loadFeatById({ params }: FeatLoaderArgs): Promise<IFeat | null> {
    const { id } = params;
    const response = await getFeatById(Number(id));
    return response;
}
