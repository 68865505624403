import styles from './index.module.scss';
import { NavLink } from 'react-router-dom';
import { APP__ROUTS } from '../../../../shared/consts';

const FooterNav = () => {
    return (
        <nav>
            <ul className={styles['footer__nav-list']}>
                <li>
                    <NavLink to={APP__ROUTS.main} className={styles['footer__nav-list-link']}>
                        Главная
                    </NavLink>
                </li>
                <li>
                    <NavLink to={APP__ROUTS.feats} className={styles['footer__nav-list-link']}>
                        Подвиги
                    </NavLink>
                </li>
                <li>
                    <NavLink to={APP__ROUTS.gallery} className={styles['footer__nav-list-link']}>
                        Галерея
                    </NavLink>
                </li>
                <li>
                    <NavLink to={APP__ROUTS.contests} className={styles['footer__nav-list-link']}>
                        Конкурсы
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
};

export default FooterNav;
