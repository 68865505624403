import React from 'react';

export const PuzzleIncon = ({ className = '' }) => {
    return (
        <svg
            width="30"
            height="30"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="className"
        >
            <path
                d="M15 4C15 4.35064 14.9398 4.68722 14.8293 5L18 5C18.5523 5 19 5.44772 19 6V9.17071C18.6872 9.06015 18.3506 9 18 9C16.3431 9 15 10.3431 15 12C15 13.6569 16.3431 15 18 15C18.3506 15 18.6872 14.9398 19 14.8293V18C19 18.5523 18.5523 19 18 19H6C5.44772 19 5 18.5523 5 18V14.8293C4.68722 14.9398 4.35064 15 4 15C2.34315 15 1 13.6569 1 12C1 10.3431 2.34315 9 4 9C4.35064 9 4.68722 9.06015 5 9.17071V6C5 5.44772 5.44772 5 6 5L9.17071 5C9.06015 4.68722 9 4.35064 9 4C9 2.34315 10.3431 1 12 1C13.6569 1 15 2.34315 15 4Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
