import styles from './index.module.scss';
import Input from '../../../../../shared/ui/Input';
import Button from '../../../../../shared/ui/Button';
import clsx from 'clsx';
import { useSignUpFormState } from '../../model/signUp';
import Searchbar from '../../../../../shared/ui/SearchBar';
import ViewIcon from '../../../../../entities/feat/assets/ViewIcon';
import { useState } from 'react';
import { subtractYears, getDayMothYearDateFormat } from '../../../../../shared/utils/date';
import { CheckBox } from '../../../../../shared/ui/CheckBox';
import { BASE__MEDIA__URL } from '../../../../../shared/consts';
import { DOCUMENTS__LINKS } from '../../../../../widgets/Footer/ui/FooterPolicy/consts';

interface ISignUpForm {
    navMenu?: JSX.Element;
    setModalStatus: () => void;
}

export const SignUpForm: React.FC<ISignUpForm> = ({ navMenu, setModalStatus }) => {
    const {
        handleSubmit,

        watchName,
        nameRegister,
        nameErrorText,

        watchMail,
        mailRegister,
        mailErrorText,

        watchLocation,
        locationRegister,
        locationErrorText,
        changeLocationValue,

        watchPassword,
        passwordRegister,
        passwordErrorText,

        watchConfirmPassword,
        confirmPasswordRegister,
        confirmPasswordErrorText,

        watchBirthday,
        birthdayRegister,
        birthdayErrorText,

        confirmRegister,

        isValid,

        personalDataRegister,

        cities,
    } = useSignUpFormState(setModalStatus);
    const [passwordInputType, setType] = useState<'password' | 'text'>('password');

    const getLink = (content: string, link: string) => {
        return (
            <a href={`${BASE__MEDIA__URL}${link}`} target="_blank" className={styles.link}>
                {content}
            </a>
        );
    };

    return (
        <div className={styles['signup']}>
            <form className={styles['signup__form']} onSubmit={handleSubmit}>
                <h2 className={styles['signup__form-title']}>Регистрация</h2>
                <Input
                    isEmpty={!watchName}
                    type={'text'}
                    title="Фамилия, имя и отчество"
                    register={nameRegister}
                    errorText={nameErrorText}
                    titleClassName={styles['signup__form-input-title']}
                />
                <Input
                    className={styles['signup__form-input']}
                    isEmpty={!watchMail}
                    type={'text'}
                    title="Почта"
                    register={mailRegister}
                    errorText={mailErrorText}
                    titleClassName={styles['signup__form-input-title']}
                />
                <Searchbar
                    className={styles['signup__form-input']}
                    options={cities}
                    isEmpty={!watchLocation}
                    type={'text'}
                    title="Откуда вы?"
                    register={locationRegister}
                    errorText={locationErrorText}
                    changeValue={changeLocationValue}
                    titleClassName={styles['signup__form-input-title']}
                />
                <div className={styles['signup__form-input-container']}>
                    <Input
                        className={styles['signup__form-input']}
                        isEmpty={!watchPassword}
                        type={passwordInputType}
                        title="Придумайте пароль"
                        register={passwordRegister}
                        errorText={passwordErrorText}
                        titleClassName={styles['signup__form-input-title']}
                    />
                    {watchPassword && (
                        <ViewIcon
                            className={styles['signup__icon']}
                            onClick={() => {
                                setType((prevState) => {
                                    return prevState === 'password' ? 'text' : 'password';
                                });
                            }}
                        />
                    )}
                </div>
                <Input
                    className={styles['signup__form-input']}
                    isEmpty={!watchConfirmPassword}
                    type={'password'}
                    title="Подтвердите пароль"
                    register={confirmPasswordRegister}
                    errorText={confirmPasswordErrorText}
                    titleClassName={styles['signup__form-input-title']}
                />
                <Input
                    className={styles['signup__form-input']}
                    isEmpty={!watchBirthday}
                    type={'date'}
                    title="Дата рождения"
                    register={birthdayRegister}
                    errorText={birthdayErrorText}
                    titleClassName={styles['signup__form-input-title']}
                    atr={{ max: subtractYears(new Date(), 6) }}
                />
                <CheckBox
                    text={
                        <span>
                            Соглашаюсь на
                            {getLink(' обработку', DOCUMENTS__LINKS.processingPersonalData)} и
                            {getLink(
                                ' распространение персональных данных',
                                DOCUMENTS__LINKS.distributionPersonalData
                            )}
                        </span>
                    }
                    register={personalDataRegister}
                    className={styles.checkbox}
                />
                <CheckBox
                    text={
                        <span>
                            Соглашаюсь с{' '}
                            {getLink(
                                'политикой конфиденциальности',
                                DOCUMENTS__LINKS.privacyPolicy
                            )}
                        </span>
                    }
                    register={confirmRegister}
                    className={styles.checkbox}
                />
                <Button
                    text="Регистрация"
                    variant="orange-gradient"
                    type="submit"
                    className={clsx(styles['signup__form-btn'], styles['signup__form-btn'])}
                    isDisabled={!isValid}
                />
                {navMenu}
            </form>
        </div>
    );
};
