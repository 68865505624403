import { createBrowserRouter } from 'react-router-dom';
import { APP__ROUTS } from '../../shared/consts';
import { MainLayout } from '../../pages/MainLayout';
import { Navigate } from 'react-router-dom';
import { Suspense } from 'react';
import PagePreloader from '../../shared/ui/PagePreloader';
import ProfileLayout from '../../pages/ProfileLayout/index';
import { lazyRetry } from '../../shared/utils/lazyRetry';
import { PuzzleRoutes } from './puzzle';
import { GalleryRoutes } from './gallery';
import { AdminRoutes } from './admin';
import { UserRoutes } from './user';
import { ContestRoutes } from './contest';
import { FeatsRoutes } from './feats';

const MainPageLazy = lazyRetry(() => import('../../pages/MainPage/index'));
const ImageVotingPage = lazyRetry(() => import('../../pages/ImageVotingPage/index'));

const router = createBrowserRouter([
    {
        path: APP__ROUTS.main,
        element: <MainLayout />,
        children: [
            {
                path: APP__ROUTS.notExistRouteRedirect,
                element: <Navigate to={APP__ROUTS.main} replace />,
            },
            {
                index: true,
                path: APP__ROUTS.main,
                element: <Suspense fallback={<PagePreloader />}>{<MainPageLazy />}</Suspense>,
            },
            // {
            //     path: APP__ROUTS.imageVoting,
            //     element: <Suspense fallback={<PagePreloader />}>{<ImageVotingPage />}</Suspense>,
            // },

            ...GalleryRoutes,
            ...FeatsRoutes,
            ...ContestRoutes,
            ...PuzzleRoutes,
        ],
    },
    {
        path: APP__ROUTS.profileLayout,
        element: <ProfileLayout />,
        children: [...UserRoutes, ...AdminRoutes],
    },
]);
export default router;
