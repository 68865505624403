import styles from './index.module.scss';
import { useAppNavigation } from '../../../../shared/hooks/navigation';
const GameBanner = () => {
    const { redirectToPuzzle } = useAppNavigation();

    return (
        <div className={styles['header__advertisement']} id="header__feat__advertisement">
            <div className={styles['header__advertisement-info']}>
                <div className={styles['header__advertisement-info-container']}>
                    <ul className={styles['header__advertisement-title-list']}>
                        <li className={styles['header__advertisement-title']}>
                            Игра-пазл «Памятник»
                        </li>
                        <li className={styles['header__advertisement-subtitle']}>
                            Играй и получи шанс выиграть приз!
                        </li>
                    </ul>
                    <button className={styles.btn} onClick={redirectToPuzzle}>
                        Играть
                    </button>
                </div>
            </div>
        </div>
    );
};

export default GameBanner;
