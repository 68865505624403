import styles from './index.module.scss';
import clsx from 'clsx';
import { WhiteVkLogo } from '../../../../../shared/assets/WhiteVkLogo';
import { BASE__APP__URL } from '../../../../../shared/consts';
import { RESPONSES__TYPE } from '../../consts';
import { VK__CLIENT__ID } from '../../../../../shared/consts';
import * as VKID from '@vkid/sdk';
import { useEffect, useRef } from 'react';

const handleRedirect = () => {
    window.location.href = `https://oauth.vk.com/authorize?client_id=${VK__CLIENT__ID}&display=page&response_type=${RESPONSES__TYPE}&redirect_uri=${BASE__APP__URL}`;
};

const VkAuthLink = ({ className = '' }) => {
    const container = useRef(null);
    // useEffect(() => {
    //     if (container.current) {
    //         VKID.Config.init({
    //             app: 51857462, // Идентификатор приложения.
    //             redirectUrl: BASE__APP__URL, // Адрес для перехода после авторизации.
    //             state: 'quer', // Произвольная строка состояния приложения.
    //         });
    //         const oneTap = new VKID.OneTap();
    //         oneTap
    //             .render({
    //                 container: container.current,
    //                 scheme: VKID.Scheme.LIGHT,
    //                 lang: VKID.Languages.RUS,
    //             })
    //             .on(VKID.WidgetEvents.ERROR, (e: any) => {
    //                 console.log(e);
    //             }); // handleError — какой-либо обработчик ошибки.
    //     }
    // }, []);
    return (
        <>
            <a className={clsx(className, styles['vk-auth-link'])} onClick={handleRedirect}>
                Войти
                <WhiteVkLogo className={styles['vk-auth-link__logo']} />
            </a>
            {/* <div id="VkIdSdkOneTap" ref={container}></div> */}
        </>
    );
};

export default VkAuthLink;
