import styles from './index.module.scss';
import Input from '../../../../../shared/ui/Input';
import Button from '../../../../../shared/ui/Button';
import clsx from 'clsx';
import { useResendMesgFormState } from '../../model/useResendMesgFormState';
import ViewIcon from '../../../../../entities/feat/assets/ViewIcon';
import Modal from '../../../../../shared/ui/Modal';

export const ResendAuthMesgForm = () => {
    const {
        handleSubmit,
        watchMail,
        watchPassword,
        mailRegister,
        passwordRegister,
        mailErrorText,
        passwordErrorText,
        passwordInputType,
        setType,
        toggleModalStatus,
        isModalOpen,
    } = useResendMesgFormState();

    return (
        <>
            <button
                type="button"
                onClick={toggleModalStatus}
                className={styles['resend-auth-mesg__open-modal-btn']}
            >
                Отправить письмо для верификации
            </button>
            <Modal
                setStatus={toggleModalStatus}
                isOpen={isModalOpen}
                className={styles['resend-auth-mesg__modal']}
            >
                <form className={styles['resend-auth-mesg__form']} onSubmit={handleSubmit}>
                    <h2 className={styles['resend-auth-mesg__form-title']}>Верификация почты</h2>
                    <Input
                        isEmpty={!watchMail}
                        type={'text'}
                        title="Почта"
                        register={mailRegister}
                        errorText={mailErrorText}
                        titleClassName={styles['resend-auth-mesg__form-input-title']}
                    />
                    <div className={styles['resend-auth-mesg__form-input-container']}>
                        <Input
                            className={styles['resend-auth-mesg__form-input-pass']}
                            isEmpty={!watchPassword}
                            type={passwordInputType}
                            title="Пароль"
                            register={passwordRegister}
                            errorText={passwordErrorText}
                            titleClassName={styles['resend-auth-mesg__form-input-title']}
                            atr={{ autoComplete: 'on' }}
                        />
                        {watchPassword && (
                            <ViewIcon
                                className={styles['resend-auth-mesg__icon']}
                                onClick={() => {
                                    setType((prevState) => {
                                        return prevState === 'password' ? 'text' : 'password';
                                    });
                                }}
                            />
                        )}
                    </div>
                    <Button
                        text="Отправить письмо"
                        variant="primary"
                        type="submit"
                        className={clsx(
                            styles['resend-auth-mesg__form-btn'],
                            styles['resend-auth-mesg__form-btn']
                        )}
                    />
                </form>
            </Modal>
        </>
    );
};
