import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../../shared/store';
import { PayloadAction } from '@reduxjs/toolkit';
import { fetchUserData, fetchSignOut, fetchUppdateUser, fetchSignInvK } from './api';
import { IUserData } from './types';

interface SessionState {
    isAuth: boolean;
    userData: IUserData | null;
    isLoading: boolean | null;
}

const initialState: SessionState = {
    isAuth: false,
    userData: null,
    isLoading: null,
};

export const sessionSlice = createSlice({
    name: 'session',
    initialState,
    reducers: {
        changeAuthStatus: (state, action: PayloadAction<boolean>) => {
            state.isAuth = action.payload;
        },
        changeUserData: (state, action: PayloadAction<null | IUserData>) => {
            state.userData = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserData.pending, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(fetchUserData.fulfilled, (state, action) => {
            state.userData = action.payload;
            state.isAuth = action.payload ? true : false;
            state.isLoading = true;
        });
        builder.addCase(fetchUserData.rejected, (state) => {
            state.userData = null;
            state.isAuth = false;
            state.isLoading = true;
        });

        builder.addCase(fetchSignInvK.pending, (state, action) => {
            state.isLoading = false;
        });
        builder.addCase(fetchSignInvK.fulfilled, (state, action) => {
            // state.isLoading = true;
        });
        builder.addCase(fetchSignInvK.rejected, (state) => {
            state.userData = null;
            state.isAuth = false;
            state.isLoading = true;
        });

        builder.addCase(fetchSignOut.fulfilled, (state, action) => {
            state.userData = null;
            state.isAuth = false;
        });
        builder.addCase(fetchSignOut.rejected, (state) => {
            state.userData = null;
            state.isAuth = false;
        });
        builder.addCase(fetchUppdateUser.fulfilled, (state, action) => {
            state.userData = {
                fio: action.payload.fio,
                email: action.payload.email,
                email_subscription: !!state.userData?.email_subscription,
                is_admin: !!state.userData?.is_admin,
                city: action.payload.city,
                date_of_birth: action.payload.date_of_birth,
                avatar_url: action.payload.avatar_url,
            };
        });
        builder.addCase(fetchUppdateUser.rejected, (state) => {
            console.log('err');
        });
    },
});

export const getAuthStatus = (state: RootState) => state['session'].isAuth;
export const getUserData = (state: RootState) => state['session'].userData;
export const getUserLoadingDataStatus = (state: RootState) => state['session'].isLoading;

export const { changeAuthStatus, changeUserData } = sessionSlice.actions;
