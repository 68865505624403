import HeaderNavPanel from '../HeaderNavPanel';
import HedaerLayout from '../HedaerLayout';
import GameBanner from '../GameBanner';

export const Header = () => {
    return (
        <HedaerLayout>
            <HeaderNavPanel />
            <GameBanner />
        </HedaerLayout>
    );
};
