import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import styles from './index.module.scss';
import HomeIcon from '../../assets/HomeIcon';
import GalleryIcon from '../../assets/GalleryIcon';
import DeedsIcon from '../../assets/DeedsIcon';
import ContestIcon from '../../assets/ContestIcon';
import ProfileIcon from '../../../../shared/assets/ProfileIcon';
import { PuzzleIncon } from '../../assets/PuzzleIncon';

interface IMobileNavLinkProps {
    path: string;
    name: string;
    iconVariant: 'home' | 'gallery' | 'deeds' | 'contest' | 'profile' | 'puzzle';
    onClick?: () => void;
}

const MobileNavLink: React.FC<IMobileNavLinkProps> = ({ path, name, iconVariant, onClick }) => {
    const icon = {
        home: <HomeIcon />,
        gallery: <GalleryIcon />,
        deeds: <DeedsIcon />,
        contest: <ContestIcon />,
        profile: <ProfileIcon />,
        puzzle: <PuzzleIncon />,
    }[iconVariant];
    return (
        <li>
            <NavLink
                onClick={onClick}
                to={path}
                className={({ isActive }) =>
                    clsx(styles['mobile-nav__link'], isActive && styles['mobile-nav__link--active'])
                }
            >
                {icon}
                <span className={styles['mobile-nav__link-text']}>{name}</span>
            </NavLink>
        </li>
    );
};

export default MobileNavLink;
