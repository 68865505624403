import Input from '../../../../../shared/ui/Input';
import Button from '../../../../../shared/ui/Button';
import styels from './index.module.scss';
import { useResetPassFormState } from '../../model/resetPass';
import clsx from 'clsx';

interface IResetPasswordForm {
    navMenu?: JSX.Element;
}

export const ResetPassForm: React.FC<IResetPasswordForm> = ({ navMenu }) => {
    const {
        handleSubmit,
        watchMail,
        watchPassword,
        mailRegister,
        passwordRegister,
        mailErrorText,
        passwordErrorText,
    } = useResetPassFormState();
    return (
        <div className={styels['reset-pass']}>
            <form className={styels['reset-pass__form']} onSubmit={handleSubmit}>
                <h2 className={styels['reset-pass__form-title']}>Сброс пароля</h2>
                <Input
                    isEmpty={!watchMail}
                    type={'text'}
                    title="Почта"
                    register={mailRegister}
                    errorText={mailErrorText}
                    titleClassName={styels['reset-pass__form-input-title']}
                />
                <Input
                    className={styels['reset-pass__form-input-pass']}
                    isEmpty={!watchPassword}
                    type={'password'}
                    title="Новый пароль"
                    register={passwordRegister}
                    errorText={passwordErrorText}
                    titleClassName={styels['reset-pass__form-input-title']}
                />
                <Button
                    text="Сбросить пароль"
                    variant="primary"
                    type="submit"
                    className={clsx(styels['reset-pass__form-btn'], styels['reset-pass__form-btn'])}
                />
                {navMenu}
            </form>
        </div>
    );
};
