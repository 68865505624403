import React from 'react';
import { ISvgIcon } from '../types/icons';

const ErrorImg: React.FC<ISvgIcon> = ({ className = '' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.0"
            viewBox="0 0 1024.000000 1024.000000"
            preserveAspectRatio="xMidYMid meet"
            className={className}
        >
            <g
                transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)"
                fill="currentColor"
                stroke="none"
            >
                <path d="M3180 7608 c-26 -14 -53 -37 -65 -58 -20 -35 -20 -48 -20 -1405 0 -1352 0 -1370 20 -1404 27 -46 64 -71 113 -78 50 -7 48 -8 329 128 121 59 236 112 255 119 32 12 43 7 298 -115 157 -75 282 -129 306 -132 38 -5 69 8 316 126 151 72 279 131 286 131 6 0 129 -57 274 -126 153 -74 280 -128 304 -131 37 -5 70 8 316 126 151 72 280 131 286 131 6 0 129 -57 274 -126 156 -75 280 -128 305 -131 54 -7 113 25 142 77 21 38 21 40 21 939 0 764 -2 906 -14 929 -23 42 -987 1000 -1019 1012 -19 7 -456 10 -1356 10 l-1327 0 -44 -22z m2522 -712 l3 -425 35 -36 c19 -19 50 -40 70 -45 20 -6 201 -10 428 -10 l392 0 0 -660 0 -659 -112 53 c-62 30 -149 71 -193 92 -128 62 -131 61 -443 -89 -144 -70 -268 -127 -275 -127 -6 0 -130 57 -274 126 -144 69 -280 128 -302 131 -36 5 -68 -8 -316 -126 -152 -72 -281 -131 -288 -131 -6 0 -130 57 -275 126 -168 81 -278 128 -305 131 -37 4 -65 -7 -241 -91 -109 -53 -201 -96 -202 -96 -2 0 -4 509 -4 1130 l0 1130 1150 0 1149 0 3 -424z m516 -204 c-106 -1 -198 0 -205 2 -10 4 -13 53 -13 208 l0 203 205 -205 205 -205 -192 -3z" />
                <path d="M3485 4380 c-170 -81 -324 -161 -341 -177 -19 -16 -37 -46 -43 -70 -8 -30 -11 -241 -9 -725 3 -665 4 -684 23 -718 12 -21 39 -44 65 -58 l45 -22 1807 2 1807 3 28 21 c15 11 38 36 51 54 l22 33 0 704 c0 757 2 730 -52 780 -40 38 -627 315 -678 320 -42 5 -67 -5 -323 -127 l-277 -133 -87 41 c-49 22 -173 82 -278 131 -135 65 -200 91 -227 91 -28 0 -110 -34 -313 -131 l-275 -132 -87 41 c-49 22 -173 82 -278 131 -145 70 -199 91 -230 90 -30 0 -114 -35 -350 -149z m626 -304 c146 -69 281 -129 301 -132 39 -7 80 10 429 179 l177 85 273 -131 c229 -111 281 -132 319 -132 37 1 95 25 315 131 l270 131 50 -22 c28 -13 125 -59 218 -104 l167 -80 0 -541 0 -540 -1615 0 -1615 0 0 538 0 539 218 105 c119 58 219 104 222 103 3 -1 124 -59 271 -129z" />
            </g>
        </svg>
    );
};

export default ErrorImg;
