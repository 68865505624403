import styels from './index.module.scss';
import PlayIcon from '../../../../shared/assets/PlayIcon';
import PauseIcon from '../../../../shared/assets/PauseIcon';
import { BASE__MEDIA__URL } from '../../../../shared/consts';
import ShareButton from '../../../../shared/ui/ShareButton';
import { useState } from 'react';
import ErrorImg from '../../../../shared/assets/ErrorImg';
import clsx from 'clsx';
import { IFeat } from '../../model/types';
import { AuthorInfo } from '../../../../shared/ui/AuthorInfo/AuthorInfo';
import { getDayMothYearDateFormat } from '../../../../shared/utils/date';
import { EarphoneIcon } from '../../assets/earphone';
import Button from '../../../../shared/ui/Button';
import ViewIcon from '../../assets/ViewIcon';

interface IFeatDetail {
    isPlaying: boolean;
    playAudio: (event: React.MouseEvent, id: number) => void;
    stopAudio: (event: React.MouseEvent) => void;
    isChoosen: boolean;
    shareIconOnClick?: () => void;
    shareVkOnClick?: () => void;
    feat: IFeat;
}

export const FeatDetail: React.FC<IFeatDetail> = ({
    isPlaying,
    stopAudio,
    playAudio,
    isChoosen,
    shareIconOnClick,
    shareVkOnClick,
    feat,
}) => {
    const [isImageCorrect, setCorrectStatus] = useState(true);
    const formatDate = getDayMothYearDateFormat(feat.publish_date);

    const audioIcon =
        isChoosen && isPlaying ? (
            <PauseIcon className={styels['feat-story__play-icon']} />
        ) : (
            <PlayIcon className={styels['feat-story__play-icon']} />
        );
    return (
        <article className={styels['feat-story']}>
            <div className={styels['feat-story__rubrics']}>
                {feat.rubrics.map((el) => (
                    <div key={el} className={styels['feat-story__rubrics-item']}>
                        <div title={el} className={styels['feat-story__rubrics-item-value']}>
                            {el}
                        </div>
                    </div>
                ))}
            </div>
            <h1 className={styels['feat-story__title']}>{feat.name}</h1>
            <AuthorInfo
                author={feat.hero}
                publishDateDMY={`${feat.hero_city ? feat.hero_city + ', ' : ''}${formatDate}`}
                src={feat.image_url}
            />
            <Button
                onClick={(event: any) => {
                    feat.audio_url && isChoosen && isPlaying
                        ? stopAudio(event)
                        : playAudio(event, feat.id);
                }}
                type="button"
                variant="orange-gradient"
                className={styels['feat-story__audio-btn']}
                text={
                    <div className={styels['feat-story__audio-container']}>
                        <EarphoneIcon
                            bg={false}
                            className={styels['feat-story__audio-container_icon']}
                        />
                        <div>Слушать</div>
                    </div>
                }
            />
            <div className={styels['feat-story__content-container']}>
                <div className={styels['feat-story__img-container']}>
                    {isImageCorrect ? (
                        <img
                            src={BASE__MEDIA__URL + feat.image_url}
                            alt={feat.name}
                            className={styels['feat-story__img']}
                            onError={() => setCorrectStatus(false)}
                        ></img>
                    ) : (
                        <ErrorImg className={clsx(styels['feat-story__img'])} />
                    )}
                </div>
                <div className={styels['feat-story__content']}>
                    <p className={styels['feat-story__text']}>{feat.description}</p>
                    <div className={styels['feat-story__footer']}>
                        <div className={styels['feat-story__view-stat']}>
                            <ViewIcon className={styels['feat-story__view-icon']} variant={1} />
                            <span>{feat.views}</span>
                        </div>
                        <ShareButton
                            shareIconOnClick={shareIconOnClick}
                            shareVkOnClick={shareVkOnClick}
                            type="arrow-btn"
                        />
                    </div>
                </div>
            </div>
        </article>
    );
};
